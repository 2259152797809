import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface adminActiveState {
    adminPageOpen: boolean,
    editUserPageOpen: boolean,
    editProjectPageOpen: boolean,
    addProjectPageOpen: boolean,
    viewUsers: boolean,
    viewProjects: boolean
}

const initialState: adminActiveState = {
    adminPageOpen: false,
    editUserPageOpen: false,
    editProjectPageOpen: false,
    addProjectPageOpen: false,
    viewUsers: false,
    viewProjects: false
}

export const adminPageSlice = createSlice({
    name: "adminPage",
    initialState,
    reducers: {
        closeAdminPage(state) {
            return {
                ...state,
                adminPageOpen: false
            }
        },
        openAdminPage(state) {
            return {
                ...state,
                adminPageOpen: true
            }
        },
        openEditUserPage(state) {
            return {
                ...state,
                adminPageOpen: true,
                editUserPageOpen: true,
            }
        },
        closeEditUserPage(state) {
            return {
                ...state,
                adminPageOpen: true,
                editUserPageOpen: false,
            }
        },
        openEditProjectPage(state) {
            return {
                ...state,
                adminPageOpen: true,
                editProjectPageOpen: true,
            }
        },
        closeEditProjectPage(state) {
            return {
                ...state,
                adminPageOpen: true,
                editProjectPageOpen: false,
            }
        },
        openAddProjectPage(state) {
            return {
                ...state,
                adminPageOpen: true,
                addProjectPageOpen: true
            }
        },
        closeAddProjectPage(state) {
            return {
                ...state,
                adminPageOpen: true,
                addProjectPageOpen: false
            }
        },
        openViewUsersPage(state) {
            return {
                ...state,
                adminPageOpen: true,
                viewUsers: true
            }
        },
        closeViewUsersPage(state) {
            return {
                ...state,
                adminPageOpen: true,
                viewUsers: false
            }
        },
        openViewProjectsPage(state) {
            return {
                ...state,
                adminPageOpen: true,
                viewProjects: true
            }
        },
        closeViewProjectsPage(state) {
            return {
                ...state,
                adminPageOpen: true,
                viewProjects: false
            }
        }}
    }    
);

export const { 
    closeAdminPage, 
    openAdminPage, 
    openEditUserPage, 
    closeEditUserPage, 
    openEditProjectPage, 
    closeEditProjectPage,
    openAddProjectPage, 
    closeAddProjectPage,
    openViewUsersPage,
    closeViewUsersPage,
    openViewProjectsPage,
    closeViewProjectsPage
} = adminPageSlice.actions

export default adminPageSlice.reducer