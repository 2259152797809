import React, { useState } from 'react';
import { useDeleteProductionTrackingMutation, useEditProductionTrackingMutation, useGetProductionTrackingQuery } from '../../../../redux/injectionEndpoints/costTracking/productionTrackingEndpoint';
import { AddProductionTracking } from './add items/AddProductionTracking';
import TrackingGraph from './graphs/TrackingGraph';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { dollarConverter } from '../../../../functions/dollarConverter';
import { Trash } from 'react-bootstrap-icons';
import { useGetCostCodesQuery } from '../../../../redux/injectionEndpoints/costTracking/costCodeEndpoint';
import { useNavigate } from 'react-router-dom';
import { editTrackingDates } from '../../../../functions/costTracking/productionTracking/editTrackingDates';
import { useGetCostsQuery } from '../../../../redux/injectionEndpoints/costTracking/costsEndpoint';
import { productionTracking } from '../../../../types/productionTracking';

export const ProductionTracking = () => {

    const navigate = useNavigate();

    const project = useSelector((state: RootState) => state.tabs.projectTab)

    const { productionTracking } = useGetProductionTrackingQuery(undefined, {
        selectFromResult: ({ data }) => ({
            productionTracking: data?.filter((production) => production.project === project)
        })
    });

    const { costs } = useGetCostsQuery(undefined, {
        selectFromResult: ({ data }) => ({
            costs: data?.filter(cost => cost.project === project)
        })
    })

    const [editProductionTracking] = useEditProductionTrackingMutation();
    const [deleteProductionTracking] = useDeleteProductionTrackingMutation();

    const { data: costCodes } = useGetCostCodesQuery();

    const [addProductionTracker, setaddProductionTracker] = useState<boolean>(false);

    const refetchDates = () => {//to update production dates for each production tracking item
        productionTracking?.forEach(productionTracked => {
            editProductionTracking({
                id: productionTracked._id!,
                field: "productionTracked",
                value: editTrackingDates(costs, productionTracked)
            })
        })
    };

    //for delete any productiontracking
    const deleteProductionTrackingFunc = (productionTracked: productionTracking) => {
        deleteProductionTracking(productionTracked._id!)
    };

    const closeAddProductionTracking = () => {
        setaddProductionTracker(false)
    }

    return (
        <div>
            <div className="modal-subTabs">
                {!addProductionTracker &&
                    <span className="modal-subTabs-item" onClick={() => setaddProductionTracker(true)}><button className='universal-save-button'>Add Production Tracking</button></span>
                }
                <span className="modal-subTabs-item" onClick={() => refetchDates()}><button className='universal-search-button'>Refetch Dates</button></span>
                <span className="modal-subTabs-item" onClick={() => navigate('/editProductionTracking')} ><button className='universal-delete-button'>Edit Data</button></span>
            </div>
            <div className="costCodes-modal-pageSplit-column">
                {addProductionTracker && <AddProductionTracking closeAddProductionTracking={closeAddProductionTracking} addProductionTracking={addProductionTracker} />}
            </div>
            {productionTracking && productionTracking.map((productionTracked, index) => {
                return <div className='productionTracking-parent'>
                    <div className='productionTracking-child productionTracking-child-1'>
                        <h6>{productionTracked.name}</h6>
                        <div className='productionTracking-child-icons'>
                            <Trash onClick={() => deleteProductionTrackingFunc(productionTracked)} />
                        </div>
                    </div>
                    <div className='productionTracking-child productionTracking-child-2'>
                        <TrackingGraph project={project} dollarTrackingCode={productionTracked.dollarTrackingCode} budgetUnitRate={productionTracked.budgetUnitRate} budgetDailyRate={productionTracked.budgetDailyRate} productionTracked={[...productionTracked.productionTracked]} />
                    </div>
                    <div className='productionTracking-child productionTracking-child-3'>
                        <h6>Budget Unit Rate: </h6>
                        <p>{dollarConverter(productionTracked.budgetUnitRate, "$", "")}</p>
                        <h6>Budget Daily Rate: </h6>
                        <p>{dollarConverter(productionTracked.budgetDailyRate, "$", "")}</p>
                        <h6>Cost Code Being Tracked: </h6>
                        <p>{costCodes ? productionTracked.dollarTrackingCode.concat(" - ", costCodes.find(code => code.code.concat(".", code.subCode) === productionTracked.dollarTrackingCode)!.subCodeName) : productionTracked.dollarTrackingCode}</p>
                        <h6>Unit Being Tracked: </h6>
                        <p>{productionTracked.unitBeingTracked}</p>
                    </div>
                </div>
            })}
        </div>
    )
}
