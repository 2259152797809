import { editObject } from "../../../types/edit";
import { API } from "../../APIs/moduleAPI";
import { ongoingCost } from "../../../types/ongoingCosts";

export const extendedOngoingCostsAPI = API
    .enhanceEndpoints({ addTagTypes: ['OngoingCost' as const] })
    .injectEndpoints({
        endpoints: builder => ({
            getOngoingCosts: builder.query<ongoingCost[], void>({ //retrieve all Costs from db
                query: () => {
                    return {
                        url: 'costTracking/getAllOngoingCosts',
                        method: 'GET'
                    }
                },
                providesTags: ["OngoingCost"]
            }),
            addOngoingCost: builder.mutation<ongoingCost, ongoingCost>({
                query(ongoingCost) {
                    return {
                        url: 'costTracking/createNewOngoingCost',
                        method: "POST",
                        body: ongoingCost
                    }
                },
                invalidatesTags: ["OngoingCost"]
            }),
            deleteOngoingCost: builder.mutation<ongoingCost, string>({
                query(id) {
                    return {
                        url: `costTracking/deleteOngoingCost/${id}`,
                        method: 'DELETE'
                    }
                },
                invalidatesTags: ["OngoingCost"]
            }),
            editOngoingCost: builder.mutation<ongoingCost, editObject>({
                query(editObject) {
                    return {
                        url: `costTracking/editOngoingCost/${editObject.id}`,
                        method: "POST",
                        body: editObject
                    }
                },
                invalidatesTags: ["OngoingCost"]
            })
        }),
        overrideExisting: false
    })

export const {
    useAddOngoingCostMutation,
    useDeleteOngoingCostMutation,
    useEditOngoingCostMutation,
    useGetOngoingCostsQuery
} = extendedOngoingCostsAPI