import React, { useEffect, useState } from 'react';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Modal } from 'react-bootstrap';
import { useAuthenticateUserMutation } from '../../redux/injectionEndpoints/auth/authEndpoints';
import { loginSuccess } from '../../redux/reducers/authReducer';
import { useDispatch } from 'react-redux';
import { requestUserAccess } from '../../redux/reducers/authReducer';

export const SignIn = () => {
    const [signIn, setsignIn] = useState<boolean>(false);
    const [requestAccess, setrequestAccess] = useState<boolean>(false)

    const [email, setemail] = useState<string>("");
    const [password, setpassword] = useState<string>("");

    const [login, result] = useAuthenticateUserMutation();

    const dispatch = useDispatch();

    const attemptLogin = () => {
        login({
            email,
            password
        }).unwrap().then(result => {
            dispatch(loginSuccess(result))
        })
    }

    return (
        <div id="logOut-heading-dropdown">
            <Dropdown >
                <Dropdown.Toggle id="logOut-heading-dropdown-button">
                    Sign In
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-style-1">
                    <Dropdown.Item onClick={() => setsignIn(true)}>Existing User</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={() => dispatch(requestUserAccess())} >New User</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            {signIn &&
                <Modal show={true} >
                    <Modal.Header closeButton onHide={() => setsignIn(false)}>
                        <Modal.Title >
                            User Sign In
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Email</p>
                        <input onChange={(e:React.ChangeEvent<HTMLInputElement>) => setemail(e.currentTarget.value)} ></input>
                        <p>Password</p>
                        <input onChange={(e:React.ChangeEvent<HTMLInputElement>) => setpassword(e.currentTarget.value)}></input>
                        <br></br>
                        <br></br>
                        <button onClick={() => attemptLogin()} >Sign In</button>
                    </Modal.Body>
                </Modal>
            }
        </div>
    )
}
