import { cost } from "../../../types/cost"
import { production, productionTracking } from "../../../types/productionTracking"

export const editTrackingDates = (costs: cost[] | undefined, productionTracked: productionTracking) => {
    if (!costs || !productionTracked.dollarTrackingCode) return []
    //costs have not been filtered by dollarTrackingCode at this point
    const filteredCosts = costs.filter(cost => cost.costCode === productionTracked.dollarTrackingCode);
    const dollarTrackedArrayDateFiltered = filteredCosts.sort((a, b) => { //sort from smallest (earliest) to largest (latest)
        if (Date.parse(a.date) > Date.parse(b.date)) {
            return 1
        }
        if (Date.parse(a.date) < Date.parse(b.date)) {
            return -1
        }
        else return 0
    })
    
    const earliestdollarTrackedDate = dollarTrackedArrayDateFiltered[0].date; //earliest date
    const latestdollarTrackedDate = dollarTrackedArrayDateFiltered[dollarTrackedArrayDateFiltered.length - 1].date; //latest date

    //calculate Dates array. this array signifies all dates we need entries for in our data as we have incurred costs on these dates.
    const numberOfDays = (Math.abs(Date.parse(latestdollarTrackedDate) - Date.parse(earliestdollarTrackedDate)) / 1000 / 60 / 60 / 24) + 1 //find the number of days to track consecutively.

    const firstDate = new Date(Date.parse(earliestdollarTrackedDate) - 1000 * 60 * 60 * 24);
    //need to ensure all months and days in the strings are double digits
    const firstDateMonthString = firstDate.getMonth() + 1 < 10 ? "0".concat((firstDate.getMonth() + 1).toString()) : (firstDate.getMonth() + 1).toString()
    const firstDateDayString = firstDate.getDate() < 10 ? "0".concat(firstDate.getDate().toString()) : firstDate.getDate().toString()
    //concat them all together
    const firstDateString = firstDate.getFullYear().toString().concat("-", firstDateMonthString, "-", firstDateDayString)

    let dates:string[] = [firstDateString]

    for (var i = 0; i < numberOfDays + 1; i++) { //create the dates array by consecutively adding a day to the earliest date and pushing it to the dates array until we reach the total number of days
        var currentDate = new Date(dates[i])
        var nextDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000) //current date + 24 hours
        const nextDateMonthString = nextDate.getMonth() + 1 < 10 ? "0".concat((nextDate.getMonth() + 1).toString()) : (nextDate.getMonth() + 1).toString() //ensure 2 digits
        const nextDateDayString = nextDate.getDate() < 10 ? "0".concat((nextDate.getDate()).toString()) : (nextDate.getDate()).toString() //ensure 2 digits
        dates.push(nextDate.getFullYear().toString().concat("-", nextDateMonthString, "-", nextDateDayString)) //concatenate
    }

    //function to check if quantities exist in current production tracking for each date
    const checkQuantities = (date:string) => {
        let quantity: number = 0

        productionTracked.productionTracked.forEach(prod => {
            if (prod.date === date) { //if the date already exists in the initial document, use the quantity already in the document
                quantity = prod.quantity
            }
        })
        return quantity
    }

    //now we compare the dates array with what already exists on the productionTracked data so we don't lose what has already been stored
    let returnObject: production[] = Array(dates.length).fill(null).map((_, index) => {return {date: dates[index], quantity: checkQuantities(dates[index])}})

    return returnObject
}