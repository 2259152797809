import React, { useState } from 'react'
import { Modal, Table } from 'react-bootstrap';
import { Binoculars, Trash } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { useGetIndexesQuery, useGetIndexQuery } from '../../../../../redux/injectionEndpoints/costTracking/indexEndpoint';
import { useAddTemplateMutation } from '../../../../../redux/injectionEndpoints/costTracking/templatesEndpoint';
import { RootState } from '../../../../../redux/store';
import { index } from '../../../../../types';

const topTableHeadings = [
    "Name",
    "Recurring?",
    "Occurance",
    "Start Date",
    "End Date",
    "Costs"
]

const costHeadings = [
    "Index",
    "Cost Code",
    "Resource Code",
    "Company",
    "Description",
    "Rates",
    ""
];

const occuranceOptions = [
    "Nil",
    "Daily",
    "Weekly",
    "Fortnightly",
    "Monthly"
];

type props = {
    closeAddTemplate: () => void,
    addTemplate: boolean
}

export const AddTemplate = (props: props) => {
    const { data: indexesData, isSuccess: indexesSuccess } = useGetIndexesQuery(); //for retrieving all indexes
    const [addTemplate] = useAddTemplateMutation();

    const [newCostIndex, setnewCostIndex] = useState<string>("0")
    const { data: indexData, isSuccess: indexSuccess } = useGetIndexQuery(newCostIndex, { refetchOnMountOrArgChange: true }); //for retrieving an index

    const selectedProject = useSelector((state: RootState) => state.tabs.projectTab)

    const [name, setname] = useState<string>("")
    const [recurring, setrecurring] = useState<boolean>(false)
    const [frequency, setfrequency] = useState<string>("")
    const [occuranceStart, setoccuranceStart] = useState<string>("")
    const [occuranceEnd, setoccuranceEnd] = useState<string>("");
    const [indexes, setindexes] = useState<index[]>([])

    const setIfRecurring = (e: React.ChangeEvent<HTMLSelectElement>) => {
        switch (e.currentTarget.value) {
            case "1":
                setrecurring(true)
                return
            case "0":
                setrecurring(false)
                return
        }
    }

    const saveIndex = (e: React.MouseEvent<HTMLButtonElement>) => { //save new rate from add rate option
        e.preventDefault();

        if (newCostIndex === "0") {
            return
        }
        else {
            setindexes([...indexes, { id: newCostIndex, project: indexData?.project!, company: indexData?.company!, description: indexData?.description!, subCostCode: indexData?.subCostCode, resourceCode: indexData?.resourceCode!, rates: indexData?.rates! }])
        }
    }

    const deleteIndex = (e: React.MouseEvent<SVGElement>) => { //delete rate from the add rate option
        e.preventDefault();
        const newIndexes = indexes!.filter(index => index.id != e.currentTarget.id)
        setindexes(newIndexes)
    };

    const createTemplate = (e: React.MouseEvent<HTMLButtonElement>) => {

        if (recurring) { //if our template has recurring input, these are to be created with the new template
            addTemplate({
                project: selectedProject,
                name,
                recurring,
                occuranceStart,
                occuranceEnd,
                frequency,
                costs: indexes
            });
        }
        else {
            addTemplate({ //indicate no recurring fields through "N/A"
                project: selectedProject,
                name,
                recurring,
                occuranceStart: "N/A",
                occuranceEnd: "N/A",
                frequency: "N/A",
                costs: indexes
            })
        }
    }

    return (
        <Modal show={props.addTemplate} dialogClassName='modal-80w'>
            <Modal.Header closeButton onHide={() => props.closeAddTemplate()}>
                <Modal.Title>Add Template</Modal.Title>
            </Modal.Header>
            <Modal.Body>

                <Table>
                    <thead>
                        <tr>
                            {topTableHeadings.map(heading => {
                                return <th>{heading}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><input onChange={(e: React.ChangeEvent<HTMLInputElement>) => setname(e.currentTarget.value)}></input></td>
                            <td>
                                <select onChange={setIfRecurring}>
                                    <option value={0}>No</option>
                                    <option value={1}>Yes</option>
                                </select>
                            </td>
                            <td>
                                {recurring ?
                                    <select onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setfrequency(e.currentTarget.value)}>
                                        {occuranceOptions.map(occurance => {
                                            return <option key={occurance} value={occurance}>{occurance}</option>
                                        })}
                                    </select> :
                                    "N/A"
                                }
                            </td>
                            <td> {recurring ?
                                <input type="date" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setoccuranceStart(e.currentTarget.value)}></input> :
                                "N/A"
                            }
                            </td>
                            <td> {recurring ?
                                <input type="date" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setoccuranceEnd(e.currentTarget.value)}></input> :
                                "N/A"
                            }
                            </td>
                            <th>
                                <Table>
                                    <thead>
                                        <tr>
                                            {costHeadings.map(heading => {
                                                return <th>{heading}</th>
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <select onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setnewCostIndex(e.currentTarget.value)}>
                                                    <option value={"0"}>0</option>
                                                    {indexesData && indexesData.map(index => {
                                                        if (index.project == selectedProject) {
                                                            return <option key={index.id}>{index.id!}</option>
                                                        }
                                                    })}
                                                </select>
                                            </td>
                                            <td>{indexData && indexData.subCostCode}</td>
                                            <td>{indexData && indexData.resourceCode}</td>
                                            <td>{indexData && indexData.company}</td>
                                            <td>{indexData && indexData.description}</td>
                                            <td>{indexData && <Binoculars />}</td>
                                            <td><button className='universal-small-save-button' onClick={saveIndex}>Save to Template</button></td>
                                        </tr>
                                        {indexes.map((indexMap, index) => {
                                            return <tr>
                                                <td>{indexMap.id}</td>
                                                <td>{indexMap.subCostCode}</td>
                                                <td>{indexMap.resourceCode}</td>
                                                <td>{indexMap.company}</td>
                                                <td>{indexMap.description}</td>
                                                <td><Binoculars /></td>
                                                <td><Trash id={indexMap.id?.toString()} onClick={deleteIndex} /></td>
                                            </tr>
                                        })}
                                    </tbody>
                                </Table>
                            </th>
                        </tr>
                        <tr>
                            <td colSpan={topTableHeadings.length}>
                                <button className='universal-save-button' onClick={createTemplate}>Save Template</button>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <button className='universal-close-button' onClick={() => props.closeAddTemplate()}>Close</button>
            </Modal.Footer>
        </Modal>

    )
}
