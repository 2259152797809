import React from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export const RequestAccessConfirmed = () => {
    const navigate = useNavigate();

    React.useEffect(() => {
        window.setTimeout(() => navigate('/login'), 5000)
    }, [])

    return (
        <Modal show={true} centered size='sm'>
            <Modal.Header  >
                <Modal.Title >
                    Access Has Been Requested
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>You will receive notification once your request has been processed</p>
            </Modal.Body>
        </Modal>
    )
}
