import React from 'react'
import { useAuthenticateUserMutation } from '../../redux/injectionEndpoints/auth/authEndpoints';
import { userLoading } from '../../redux/reducers/authReducer';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { useNavigate } from 'react-router-dom';
import { useCheckAuthenticationQuery } from '../../redux/injectionEndpoints/auth/authEndpoints';
import { DummyPage } from '../body/DummyPage'
import { ForgotPassword } from './ForgotPassword';

export const PleaseSignIn = () => {
    const navigate = useNavigate();

    const authorisedUser = useSelector((state: RootState) => state.user);
    const { data: checkIfAuthenticated } = useCheckAuthenticationQuery();

    const [email, setemail] = React.useState<string>("");
    const [password, setpassword] = React.useState<string>("");

    const [login] = useAuthenticateUserMutation();
    const registerSuccessLoginMessage = useSelector((state: RootState) => state.user.requestAccessMessage)

    const [forgotPassword, setforgotPassword] = React.useState<boolean>(false)

    const dispatch = useDispatch();

    const attemptLogin = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        dispatch(userLoading())
        //use route through RTK Query
        login({
            email,
            password
        })
            .unwrap()
            .then(result => {
                // dispatch(loginSuccess(result))
                // if (result.user?.projects) dispatch(changeProject(result.user?.projects[0]!))
            })
            .catch(err => {
                console.log(err)
                document.getElementById("login-error-message")!.innerHTML = err.data.message
                window.setTimeout(() => document.getElementById("login-error-message")!.innerHTML = "", 5000)
            })
    };

    const closeForgotPassword = () => {
        setforgotPassword(false)
    }

    React.useEffect(() => {
        if (authorisedUser.isAuthenticated && !authorisedUser.requestAccess) {//for checking if a user is logged in
            navigate('/Costs')
        }
    }, [authorisedUser, checkIfAuthenticated])

    return (
        <>
            {authorisedUser.isLoading ? <DummyPage /> :
                <form className="please-sign-in-container">
                    <h3>User Sign In</h3>
                    <p>Email</p>
                    <input onChange={(e: React.ChangeEvent<HTMLInputElement>) => setemail(e.currentTarget.value)} ></input>
                    <p>Password</p>
                    <input type="password" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setpassword(e.currentTarget.value)}></input>
                    <br></br>
                    <div className="please-sign-in-container-buttons">
                        <button onClick={(e: React.MouseEvent<HTMLButtonElement>) => attemptLogin(e)} >Sign In</button>
                        <button onClick={() => navigate('/requestaccess')}>Request Access</button>
                    </div>
                    <div className="forgot-password-container">
                        <p>OR</p>
                        <button onClick={() => navigate('/forgot-password') }>Forgot Password?</button>
                    </div>
                    <p>{registerSuccessLoginMessage}</p>
                    <p style={{ color: "red", margin: "0 auto", textAlign: "center" }} id="login-error-message"></p>
                </form>
            }
        </>
    )
}
