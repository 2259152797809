import React, { useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import { useAddUserMutation } from '../../redux/injectionEndpoints/users/userEndpoints';
import { useGetProjectsQuery } from '../../redux/injectionEndpoints/costTracking/projectsEndpoint';
import { PlusCircle, Trash } from 'react-bootstrap-icons';

const tableHeadings = [
    "First Name",
    "Last Name",
    "Company",
    "Position",
    "Email",
    "Phone",
    "User Privilege",
    "Projects",
    "Selected Projects"
];

type props = {
    closeAddUser: () => void,
    addUser: boolean
}

export const AddUser = (props: props) => {
    const [addUser] = useAddUserMutation();

    const { data: companyProjects } = useGetProjectsQuery();

    const [firstName, setfirstName] = useState<string>("")
    const [lastName, setlastName] = useState<string>("")
    const [company, setcompany] = useState<string>("")
    const [position, setposition] = useState<string>("")
    const [email, setemail] = useState<string>("")
    const [phone, setphone] = useState<string>("")
    const [userPrivilege, setuserPrivilege] = useState<"God" | "Engineer" | "Admin" | "">("")
    const [projects, setprojects] = useState<string[]>([]);

    const [selectedProject, setselectedProject] = React.useState<string>("")

    const saveNewUser = () => {
        addUser({
            firstName,
            lastName,
            company,
            position,
            email,
            phone,
            userPrivilege,
            projects,
            activated: false
        });
    }

    return (
        <Modal show={props.addUser} dialogClassName="modal-70w" >
            <Modal.Header closeButton onHide={() => props.closeAddUser()}>
                <Modal.Title>Add User</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table striped bordered>
                    <thead>
                        <tr>
                            {tableHeadings.map(heading => {
                                return <th>{heading}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><input className='add-user-input' onChange={(e: React.ChangeEvent<HTMLInputElement>) => setfirstName(e.currentTarget.value)}></input></td>
                            <td><input className='add-user-input' onChange={(e: React.ChangeEvent<HTMLInputElement>) => setlastName(e.currentTarget.value)}></input></td>
                            <td><input className='add-user-input' onChange={(e: React.ChangeEvent<HTMLInputElement>) => setcompany(e.currentTarget.value)}></input></td>
                            <td><input className='add-user-input' onChange={(e: React.ChangeEvent<HTMLInputElement>) => setposition(e.currentTarget.value)}></input></td>
                            <td><input className='add-user-input' onChange={(e: React.ChangeEvent<HTMLInputElement>) => setemail(e.currentTarget.value)}></input></td>
                            <td><input className='add-user-input' onChange={(e: React.ChangeEvent<HTMLInputElement>) => setphone(e.currentTarget.value)}></input></td>
                            <td>
                                <select className='add-user-input' onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                    switch (e.target.value) {
                                        case "God": setuserPrivilege("God"); break
                                        case "Engineer": setuserPrivilege("Engineer"); break
                                        case "Admin": setuserPrivilege("Admin"); break
                                    }
                                }}>
                                    <option value="God">God</option>
                                    <option value="Admin">Admin</option>
                                    <option value="Engineer">Engineer</option>
                                </select>
                            </td>
                            <td className='add-user-input-multicell'>
                                <select className='add-user-input' onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setselectedProject(e.target.value)}>
                                    <option value="0">SELECT</option>
                                    {Array.isArray(companyProjects) && companyProjects.length > 0 &&
                                        companyProjects.map(proj => {
                                            return <option value={proj._id!}>{proj.projectCode.concat(" - ", proj.projectName)}</option>
                                        })
                                    }
                                </select>
                                <PlusCircle onClick={() => setprojects([...projects, selectedProject])} />
                            </td>
                            <td>
                                {Array.isArray(projects) && projects.length > 0 &&
                                    projects.map(proj => {
                                        return <div className='add-user-selected-projects'>
                                            <p>{companyProjects?.find(project => project._id! === proj)?.projectCode.concat(" - ", companyProjects?.find(project => project._id! === proj)!.projectName)}</p>
                                            <Trash onClick={() => setprojects(oldProjects => oldProjects.filter(projectoRemove => projectoRemove !== proj ))} />
                                        </div>
                                    })
                                }
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <button onClick={() => saveNewUser()}>Save New User</button>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={() => props.closeAddUser()}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}
