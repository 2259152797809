import { resourceCode } from "../../../types/resourceCode";
import { editObject } from "../../../types/edit";
import { API } from "../../APIs/moduleAPI";

export const extendedResourceCodesAPI = API
    .enhanceEndpoints({addTagTypes: ['ResourceCode' as const]})
    .injectEndpoints({
        endpoints: builder => ({
            getResourceCodes: builder.query<resourceCode[], void>({
                query: () => {
                    return {
                        url: "costTracking/getAllResourceCodes",
                        method: "GET"
                    }
                },
                providesTags: ["ResourceCode"]
            }),
            getResourceCode: builder.query<resourceCode[], string>({
                query(id) {
                    return {
                        url: `costTracking/getResourceCode/${id}`,
                        method: 'GET'
                    }
                },
                providesTags: ["ResourceCode"]
            }),
            addResourceCode: builder.mutation<resourceCode, resourceCode>({
                query(resourceCode) {
                    return {
                        url: 'costTracking/createResourceCode',
                        method: 'POST',
                        body: resourceCode
                    }
                },
                invalidatesTags: ["ResourceCode"]
            }),
            deleteResourceCode: builder.mutation<resourceCode, string>({
                query(id) {
                    return {
                        url: `costTracking/deleteResourceCode/${id}`,
                        method: "DELETE",
                    }
                },
                invalidatesTags: ["ResourceCode"]
            }),
            editResourceCode: builder.mutation<resourceCode, editObject>({
                query(editObject){
                    return {
                        url: `costTracking/editResourceCode/${editObject.id}`,
                        method: "POST",
                        body: editObject
                    }
                },
                invalidatesTags: ["ResourceCode"]
            })
        })
    })

export const {
    useGetResourceCodesQuery,
    useGetResourceCodeQuery,
    useAddResourceCodeMutation,
    useDeleteResourceCodeMutation,
    useEditResourceCodeMutation
} = extendedResourceCodesAPI