import React, { FC, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { closePropertyEditor } from '../../../../../redux/reducers/editPropertyReducer';
import { editProperty, editPropertyProps } from '../../../../../types/editPropertyProps';
import { useEditCompanyMutation } from '../../../../../redux/injectionEndpoints/costTracking/companiesEndpoint';
import { useEditResourceCodeMutation, useGetResourceCodesQuery } from '../../../../../redux/injectionEndpoints/costTracking/resourceCodeEndpoint';
import { useEditCostCodeMutation } from '../../../../../redux/injectionEndpoints/costTracking/costCodeEndpoint';
import { useEditIndexMutation, useGetIndexesQuery, useGetIndexQuery } from '../../../../../redux/injectionEndpoints/costTracking/indexEndpoint';
import { editObject } from '../../../../../types/edit';
import { costCode, resourceCode } from '../../../../../types/costCode';
import { index } from '../../../../../types';
import { useGetCostCodesQuery } from '../../../../../redux/injectionEndpoints/costTracking/costCodeEndpoint';
import { useEditCostMutation, useGetCostQuery } from '../../../../../redux/injectionEndpoints/costTracking/costsEndpoint';
import { cost } from '../../../../../types/cost';

export const EditProperty: FC<editProperty> = (props) => { //props is the actual data that will be edited. propertyType is the type of property to be edited (eg Cost Code or Resource Code etc)

    const [valueBeingUpdated, setvalueBeingUpdated] = useState<string | number>("");
    const [indexBeingUpdated, setindexBeingUpdated] = useState<string>("");

    const { data: costCodesData, refetch: costCodesRefetch } = useGetCostCodesQuery();
    const { data: resourceCodesData } = useGetResourceCodesQuery();
    const { data: indexesData } = useGetIndexesQuery();
    const { data: indexData } = useGetIndexQuery(indexBeingUpdated.toString());

    const [editCompanyProperty] = useEditCompanyMutation();
    const [editResourceCodeProperty] = useEditResourceCodeMutation();
    const [editCostCodeProperty] = useEditCostCodeMutation();
    const [editIndexProperty] = useEditIndexMutation();
    const [editCostProperty] = useEditCostMutation();

    const [editObject, seteditObject] = useState<editObject>({ //this will be sent to backend for editing
        id: props.data.id!,
        field: props.updateField,
        value: ""
    })

    const dispatch = useDispatch();

    const saveAndClose = () => {

        switch (props.propertyType) {
            case "Company": editCompanyProperty({ ...editObject, value: valueBeingUpdated })
                return
            case "Resource Code": editResourceCodeProperty({ ...editObject, value: valueBeingUpdated })
                return
            case "Cost Code": {
                if (props.arrayIndex! >= 0) { //code for when we edit a resource code within a cost code
                    var costCode = { ...props.data } as costCode

                    const updatedResourceCodes = costCode.resourceCodes.map((resourceCode, index) => (index === props.arrayIndex ? { ...resourceCode, [props.updateSubField!]: valueBeingUpdated } : resourceCode)) //we are returning a new array of resource codes for which the particular field of the resource code needing updating is addressed. 

                    const typescriptUpdatedResourceCodes = [...updatedResourceCodes].map(resourceCode => ({
                        resource: resourceCode.resource,
                        rate: Number(resourceCode.rate), //need this as our code is saving the above as all string
                        quantity: Number(resourceCode.quantity), //need this as our code is saving the above as all string
                        unit: resourceCode.unit,
                        total: Number(resourceCode.rate) * Number(resourceCode.quantity) //need this as our code is saving the above as all string
                    })
                    )

                    editCostCodeProperty({ ...editObject, value: typescriptUpdatedResourceCodes })
                    if (props.updateSubField === "rate" || "quantity") { //if our rates or quantities change, we need to also update our final budget
                        var newBudget = 0
                        typescriptUpdatedResourceCodes.forEach(resourceCode => {
                            newBudget += resourceCode.total
                        })
                        editCostCodeProperty({ ...editObject, field: "budget", value: newBudget })
                    }
                    costCodesRefetch();
                }

                else editCostCodeProperty({ ...editObject, value: valueBeingUpdated }) //if not editing resource code, edit cost code property as per usual
                return
            }
            case "Cost": {
                if (props.arrayIndex! >= 0) { //update if the index arrays are being updated
                    var cost = props.data as cost;
                    var rateQuantities = (props.data as cost).rates.map(rate => (rate.numberOf))

                    const updatedRates = cost.rates.map((rate, index) => (index === props.arrayIndex ? { ...rate, [props.updateSubField!]: valueBeingUpdated } : rate)) // return a new rates array with the updated rate (quantity for costs) included

                    console.log(updatedRates)

                    const typescriptUpdatedRates = [...updatedRates].map(rate => ({ 
                        description: rate.description,
                        rate: Number(rate.rate),
                        unit: rate.unit,
                        numberOf: Number(rate.numberOf),
                        valueOf: Number(rate.numberOf) * Number(rate.rate)
                    }))
                    editCostProperty({ ...editObject, value: typescriptUpdatedRates })

                    //Update Total Cost when rates and quantities change
                    editCostProperty({ ...editObject, field: "totalCost", value: typescriptUpdatedRates.map(rate => (rate)).reduce((prev, curr) => prev + curr.numberOf * curr.rate, 0) })
                }
                else if (props.updateField === "index") {
                    console.log(indexBeingUpdated)
                    editCostProperty({ ...editObject, value: indexBeingUpdated })
                    editCostProperty({ id: props.data.id!, field: "company", value: indexData!.company })
                    editCostProperty({ id: props.data.id!, field: "description", value: indexData!.description })
                    editCostProperty({ id: props.data.id!, field: "costCode", value: indexData!.subCostCode! })
                    editCostProperty({ id: props.data.id!, field: "resourceCode", value: indexData!.resourceCode })
                    editCostProperty({ id: props.data.id!, field: "totalCost", value: 0 })
                    editCostProperty({
                        id: props.data.id!, field: "rates", value: indexData!.rates.map((rate) => ({ //set new rates for the new cost based on UI inputs and indexData
                            description: rate.rateDescription!,
                            rate: rate.rate!,
                            unit: rate.unit!,
                            numberOf: 0,
                            valueOf: 0
                        }))
                    })
                }
                //If not updating a rate inside the cost, a simple (field,value) pair update
                else {
                    editCostProperty({ ...editObject, value: valueBeingUpdated }) //if not editing resource code, edit cost code property as per usual
                }

                return
            }
            case "Index":
                if (props.arrayIndex! >= 0) {
                    var index = { ...props.data } as index

                    var updatedIndexRates = index.rates.map((indexRate, index) => (index === props.arrayIndex ? { ...indexRate, [props.updateSubField!]: valueBeingUpdated } : indexRate)) //we are returning a new array of index rates for which the particular field of the resource code needing updating is addressed.

                    const typescriptUpdatedIndexRates = updatedIndexRates.map(indexRate => ({
                        id: indexRate.id,
                        rate: Number(indexRate.rate),
                        unit: indexRate.unit,
                        rateDescription: indexRate.rateDescription
                    }))
                    editIndexProperty({ ...editObject, value: typescriptUpdatedIndexRates })
                }
                else editIndexProperty({ ...editObject, value: valueBeingUpdated })
                return
        }
    }

    const propertyToUpdateRender = () => { //to determine what type of input needs to be rendered when editing the cost.
        switch (props.updateField) {
            case "index":
                return <select onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setindexBeingUpdated(e.currentTarget.value)}>
                    <option>Select</option>
                    {indexesData?.map(index => {
                        return <option value={index.id!}>{index.id}</option>
                    })}
                </select>
            case "date":
                return <input type="date" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setvalueBeingUpdated(e.currentTarget.value)}></input>
            case "costCode":
                return <select onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setvalueBeingUpdated(e.currentTarget.value)}>
                    {costCodesData?.map(costCode => {
                        return <option value={costCode.code.concat(".", costCode.subCode)}>{costCode.code.concat(".", costCode.subCode)}</option>
                    })}
                </select>
            case "resourceCode":
                return <select onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setvalueBeingUpdated(e.currentTarget.value)}>
                    {resourceCodesData?.map(resourceCode => {
                        return <option value={resourceCode.code}>{resourceCode.code}</option>
                    })}
                </select>
                case "status":
                    return <select onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setvalueBeingUpdated(e.currentTarget.value)}>
                        <option>Select</option>
                        <option value="Open">Open</option>
                        <option value="Approved">Approved</option>
                        <option value="Pending">Pending</option>
                        <option value="Rejected">Rejected</option>
                    </select>
            case "purchaseOrder":
            case "docketNumber":
            case "invoiceNumber":
            case "company":
            case "description":
                return <input onChange={(e: React.ChangeEvent<HTMLInputElement>) => setvalueBeingUpdated(e.currentTarget.value)}></input>

            default: return <input onChange={(e: React.ChangeEvent<HTMLInputElement>) => setvalueBeingUpdated(e.currentTarget.value)}></input>

        }
    }

    return (
        <div>
            <Modal show={true}>
                <Modal.Header closeButton onHide={() => dispatch(closePropertyEditor())}>
                    <Modal.Title>
                        {"Edit " + props.propertyType}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><b>Updating Property: {props.updateField}</b></p>
                    <p>Current Value: {props.updateValue}</p>
                    <p>Updated Value: {propertyToUpdateRender()}</p>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => dispatch(closePropertyEditor())}>Close</button>
                    <button onClick={() => { saveAndClose(); dispatch(closePropertyEditor()) }}>Save Changes</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
