import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { Binoculars } from 'react-bootstrap-icons';
import { useGetUsersQuery, useDeleteUserMutation } from '../../redux/injectionEndpoints/users/userEndpoints';
import { useSelector, useDispatch } from 'react-redux';
import { openEditUserPage } from '../../redux/reducers/adminReducer';
import { AddUser } from './AddUser';
import { EditUser } from './EditUser';
import { RootState } from '../../redux/store';
import { useGetProjectsQuery } from '../../redux/injectionEndpoints/costTracking/projectsEndpoint';

const tableHeadings = [
    "Select",
    "First Name",
    "Last Name",
    "Company",
    "Position",
    "Email",
    "Phone",
    "User Privilege",
    "Projects",
    "Activated"
];

export const ViewUsers = () => {
    const dispatch = useDispatch();

    //endpoints for users
    const { data: usersData } = useGetUsersQuery();
    const { data: projects } = useGetProjectsQuery();
    const [deleteSelectedUser] = useDeleteUserMutation();

    //conditional rendering
    const [addUser, setaddUser] = useState<boolean>(false)
    const [checkedUser, setcheckedUser] = useState<string>("")
    const editUser = useSelector((state: RootState) => state.admin.editUserPageOpen)

    const manageChecks = (e: React.ChangeEvent<HTMLInputElement>) => {
        (document.getElementsByName(`${checkedUser}`) as NodeListOf<HTMLInputElement>).forEach(element => element.checked = false)
        if (e.currentTarget.checked) { //if box is checked
            setcheckedUser(e.currentTarget.name); //add new user to checked user
        }
        else setcheckedUser("")
    };

    const deleteUser = () => {
        deleteSelectedUser(checkedUser);
        const userElements = (document.getElementsByClassName("tableCheckbox") as HTMLCollectionOf<HTMLInputElement>) //get all checkbox elements
        for (var i = 0; i < userElements.length; i++) {
            userElements[i].checked = false //set all checkbox elements to false
        }
        setcheckedUser("");
    };

    const closeAddUser = () => {
        setaddUser(false)
    }

    return (
        <div>
            <div className="modal-subTabs">
                {!addUser &&
                    <span onClick={() => { setaddUser(true) }} className="modal-subTabs-item">
                        <p>Add User</p>
                    </span>
                }
                {addUser &&
                    <span onClick={() => setaddUser(false)} className="modal-subTabs-item">
                        <p>Hide</p>
                    </span>
                }
                {(checkedUser === "" || usersData?.find(user => user._id === checkedUser)?.userPrivilege == "God") ? null :
                    <span className="modal-subTabs-item" onClick={() => deleteUser()}>
                        <p>Delete</p>
                    </span>
                }
                {checkedUser === "" ? null :
                    <span className="modal-subTabs-item" onClick={() => dispatch(openEditUserPage())}>
                        <p>Edit</p>
                    </span>
                }
            </div>
            {editUser && <EditUser {...usersData?.find(user => user._id === checkedUser)!} />}
            <div className="costCodes-modal-pageSplit-column">
                {addUser && <AddUser closeAddUser={closeAddUser} addUser={addUser} />}
                <Table >
                    <thead>
                        <tr>
                            {tableHeadings.map(heading => {
                                return <th>{heading}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {usersData &&
                            usersData.map(user => {
                                return <>
                                    <tr>
                                        <td><input name={user._id} type="checkbox" className='tableCheckbox' onChange={(e: React.ChangeEvent<HTMLInputElement>) => manageChecks(e)}></input></td>
                                        <td>{user.firstName}</td>
                                        <td>{user.lastName}</td>
                                        <td>{user.company}</td>
                                        <td>{user.position}</td>
                                        <td>{user.email}</td>
                                        <td>{user.phone}</td>
                                        <td>{user.userPrivilege}</td>
                                        <td>{ Array.isArray(projects) && projects.length > 0 &&
                                            projects.filter(project => user.projects.includes(project._id!))
                                                .map(proj => {
                                                    return <p style={{margin: "0.1em"}}>{proj.projectCode.concat(" - ", proj.projectName)}</p>
                                                })
                                        }
                                        </td>
                                        <td>{user.activated ? "Yes" : "No"}</td>
                                    </tr>
                                </>
                            })
                        }
                    </tbody>
                </Table>
            </div>
        </div>
    )
}
