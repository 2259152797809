import React from 'react'

type props = {
    onSearch: (startDate: string, endDate: string, singleDateSearch: boolean, showBlanks: boolean) => void
}

export const DateRange = (props: props) => {
    const [startDate, setStartDate] = React.useState<string>('');
    const [endDate, setEndDate] = React.useState<string>('');
    const [singleDateSearch, setSingleDateSearch] = React.useState<boolean>(false);
    const [showBlanks, setshowBlanks] = React.useState<boolean>(false)

    const handleStartDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setStartDate(e.target.value);
    };

    const handleEndDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEndDate(e.target.value);
    };

    const handleToggle = () => {
        setSingleDateSearch(!singleDateSearch);
    };

    const handleBlanks = () => {
        setshowBlanks(!showBlanks)
    }

    React.useEffect(() => {
        props.onSearch(startDate, endDate, singleDateSearch, showBlanks);
    }, [startDate, endDate, singleDateSearch, showBlanks])

    return (
        <div className="date-range-search">
            <div className="single-date-toggle">
                <label className='date-range-label'>
                    <input
                        type="checkbox"
                        checked={singleDateSearch}
                        onChange={handleToggle}
                    />
                    Single Date Search
                </label>
                <label className='date-range-label'>
                    <input
                        type="checkbox"
                        checked={showBlanks}
                        onChange={handleBlanks}
                    />
                    Show Blanks
                </label>
            </div>
            <div className="date-input">
                <label htmlFor="searchCosts-start-date-input" className='date-range-label'>{!singleDateSearch ? "Start Date: " : "Date: "}</label>
                <input
                    type="date"
                    id="searchCosts-start-date-input"
                    className='date-range-input'
                    value={startDate}
                    onChange={handleStartDateChange}
                />
            </div>
            {!singleDateSearch &&
                <div className="date-input">
                    <label htmlFor="searchCosts-end-date-input" className='date-range-label'>End Date</label>
                    <input
                        type="date"
                        id="searchCosts-end-date-input"
                        className='date-range-input'
                        value={endDate}
                        onChange={handleEndDateChange}
                    />
                </div>
            }
        </div>
    );
}
