import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { extendedAuthAPI } from '../injectionEndpoints/auth/authEndpoints';

export interface openModalPayload {
    modalType: string,
    modalTabToOpen?: string
}

export interface projectsState {
    projectTab: string,
}

const initialState: projectsState = {
    projectTab: "", //we don't know what this is initially as it is decided when the user logs in.
}

export const projectSlice = createSlice({
    name: "project",
    initialState,
    reducers: {
        changeProject(state, action: PayloadAction<string>) {
            return {
                ...state,
                projectTab: action.payload
            }
        }
    },
    extraReducers: (builder) => {
        builder
            .addMatcher( //to set the first project tab to be open when the user logs in
                extendedAuthAPI.endpoints.authenticateUser.matchFulfilled,
                (state, { payload }) => {
                    state.projectTab = payload.user!.projects[0]
                }
            )
            .addMatcher( //to set the first project tab to be open when the software checks for a session
                extendedAuthAPI.endpoints.checkAuthentication.matchFulfilled,
                (state, {payload}) => {
                    state.projectTab = payload.user!.projects[0]
                }
            )
    }
});

export const { changeProject } = projectSlice.actions

export default projectSlice.reducer