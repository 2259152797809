import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface propertyEditorState {
    editorOpen: boolean
}

const initialState: propertyEditorState = {
    editorOpen: false
}

export const propertyEditorSlice = createSlice({
    name: "propertyEditor",
    initialState,
    reducers: {
        closePropertyEditor(state) {
            return {
                ...state,
                editorOpen: false
            }
        },
        openPropertyEditor(state) {
            return {
                ...state,
                editorOpen: true
            }
        }
    }
});

export const { closePropertyEditor, openPropertyEditor } = propertyEditorSlice.actions

export default propertyEditorSlice.reducer