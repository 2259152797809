import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { requestUserAccessComplete } from '../../redux/reducers/authReducer'
import { useDispatch } from 'react-redux';
import { useAddUserMutation } from '../../redux/injectionEndpoints/users/userEndpoints';
import { useNavigate } from 'react-router-dom';
import { passwordStrength } from 'check-password-strength';

export const RequestAccess = () => {
    const [firstName, setfirstName] = useState<string>("")
    const [lastName, setlastName] = useState<string>("")
    const [company, setcompany] = useState<string>("")
    const [position, setposition] = useState<string>("")
    const [phone, setphone] = useState<string>("")
    const [email, setemail] = useState<string>("")
    const [password, setpassword] = useState<string>("")
    const [confirmPassword, setconfirmPassword] = useState<string>("")

    const [addUser] = useAddUserMutation();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [showErrorMessage, setshowErrorMessage] = React.useState<boolean>(false);

    const checkPasswordStrength = () => {
        var passStrength = passwordStrength(password)

        if (password === "") {
            return {
                message: "",
                id: 0
            }
        } else {
            if (passStrength.id === 0) {
                return {
                    message: "Password is weak",
                    id: passStrength.id
                }
            } else {
                return {
                    message: "Password is".concat(" ", passStrength.value.toLowerCase()),
                    id: passStrength.id
                }
            }
        }
    };

    const checkPasswordsTheSame = () => {
        if (confirmPassword === "") return {
            message: "",
            id: -1
        }
        else if (confirmPassword !== password) return {
            message: "Passwords do not match",
            id: 0
        }
        else if (confirmPassword === password) return {
            message: "Passwords match",
            id: 1
        }
        else return {
            message: "",
            id: -1
        }
    };

    const requestUserAccess = () => {

        if (password !== confirmPassword) {
            setshowErrorMessage(true)
            setTimeout(() => setshowErrorMessage(false), 3000)
            return
        }

        addUser({
            firstName,
            lastName,
            company,
            position,
            phone,
            email,
            passwords: {
                password,
                previousPasswords: []
            },
            activated: false,
            userPrivilege: 'Admin',
            projects: []
        });
        dispatch(requestUserAccessComplete())
        navigate('/requestaccessconfirmed')
    }

    return (
        <Modal show={true} centered size="lg">
            <Modal.Header closeButton onHide={() => navigate('/login')} >
                <Modal.Title >
                    Request Access
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className='please-register-container'>
                    <p>First Name</p>
                    <input required onChange={(e: React.ChangeEvent<HTMLInputElement>) => setfirstName(e.target.value)} ></input>
                    <p>Last Name</p>
                    <input required onChange={(e: React.ChangeEvent<HTMLInputElement>) => setlastName(e.target.value)} ></input>
                    <p>Company</p>
                    <input required onChange={(e: React.ChangeEvent<HTMLInputElement>) => setcompany(e.target.value)} ></input>
                    <p>Position</p>
                    <input required onChange={(e: React.ChangeEvent<HTMLInputElement>) => setposition(e.target.value)} ></input>
                    <p>Phone</p>
                    <input required onChange={(e: React.ChangeEvent<HTMLInputElement>) => setphone(e.target.value)} ></input>
                    <p>Email</p>
                    <input required onChange={(e: React.ChangeEvent<HTMLInputElement>) => setemail(e.target.value)} ></input>
                    <p>Password</p>
                    <input type="password" required onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setpassword(e.target.value) }} ></input>
                    <p style={{ color: checkPasswordStrength().id === 3 ? "green" : "red" }}>{checkPasswordStrength().message}</p>
                    <p>Confirm Password</p>
                    <input type="password" required onChange={(e: React.ChangeEvent<HTMLInputElement>) => setconfirmPassword(e.target.value)} ></input>
                    <p style={{ color: checkPasswordsTheSame().id === 0 ? "red" : "green" }}>{checkPasswordsTheSame().message}</p>
                    <button onClick={() => requestUserAccess()}>Request Access</button>
                    {showErrorMessage && <p color='red'>Your passwords do not match</p>}
                </form>
            </Modal.Body>
        </Modal>
    )
}
