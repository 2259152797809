import React from 'react';

type props = {
    changeStateFunction: (date:number) => void,
    placeholder: string
}

export const NumberInput = (props:props) => {
    const [inputValue, setInputValue] = React.useState<string>('');

    const handleInputChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setInputValue(newValue);
        props.changeStateFunction(Number(newValue)); // Notify the parent component about the input change
    };

    return (
        <div>
            <input
                type="number"
                value={inputValue}
                className="custom-number-input"
                onChange={handleInputChange}
                placeholder={props.placeholder}
            />
        </div>
    );
}
