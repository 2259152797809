import { accrual } from "../../types/accruals";

export const getAccrualYearRange = (accruals: accrual[] | undefined): string[] => {
    let yearsArray: string[] = []
    let returnYearArray: string[] = []

    if (typeof accruals === 'undefined') {
        return [new Date().getFullYear().toString()]
    } else {
        accruals.forEach(accrual => {
            yearsArray.push(accrual.year)
        })
        
        returnYearArray = yearsArray.filter((year, index) => {
            return yearsArray.indexOf(year) === index
        })
        return returnYearArray
    }
}