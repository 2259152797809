import Nav from 'react-bootstrap/Nav';
import { Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import { costTrackingDropdown } from '../dropdowns/dropdownsValues';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { Link } from 'react-router-dom';

export const ProjectInteractionHeader = () => {

    var confirmedAuthenticated = useSelector((state: RootState) => state.user.isAuthenticated);

    return (
        <div>
            {confirmedAuthenticated &&
                <Nav>
                    <Dropdown className='dropdown-style-2'>
                        <Dropdown.Toggle>
                            Setup
                        </Dropdown.Toggle>
                        <Dropdown.Menu >
                            {
                                costTrackingDropdown.map(tab => {
                                    if (tab.parent === "setup")
                                        return <Dropdown.Item>
                                            <Nav.Item>
                                                <Nav.Link as={Link} to={`/${tab.url}`} key={tab.key}>{tab.value}</Nav.Link>
                                            </Nav.Item>
                                        </Dropdown.Item>
                                })
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown className='dropdown-style-2'>
                        <Dropdown.Toggle>
                            Cost Tracking
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {
                                costTrackingDropdown.map(tab => {
                                    if (tab.parent === "costTracking")
                                        return <Dropdown.Item>
                                            <Nav.Item>
                                                <Nav.Link as={Link} to={`/${tab.url}`} key={tab.key}>{tab.value}</Nav.Link>
                                            </Nav.Item>
                                        </Dropdown.Item>
                                })
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown className='dropdown-style-2'>
                        <Dropdown.Toggle>
                            End of Month
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {
                                costTrackingDropdown.map(tab => {
                                    if (tab.parent === "endOfMonth")
                                        return <Dropdown.Item>
                                            <Nav.Item>
                                                <Nav.Link as={Link} to={`/${tab.url}`} key={tab.key}>{tab.value}</Nav.Link>
                                            </Nav.Item>
                                        </Dropdown.Item>
                                })
                            }
                        </Dropdown.Menu>
                    </Dropdown>
                </Nav>
            }
        </div>
    )
}
