import React, { useEffect, useState } from 'react';
import { Pencil, Trash3, Binoculars } from 'react-bootstrap-icons';
import Table from 'react-bootstrap/Table';
import { index } from '../../../../types';
import { AddIndex } from './add items/AddIndex';
import { EditIndex } from './editItems/EditIndex';
import { useDeleteIndexMutation, useGetIndexesQuery } from '../../../../redux/injectionEndpoints/costTracking/indexEndpoint';
import { useDispatch, useSelector } from 'react-redux';
import { ViewRates } from './viewItems/ViewRates';
import { RootState } from '../../../../redux/store';
import { openViewRates } from '../../../../redux/reducers/viewRatesReducer';
import { AddCostFromIndex } from './add items/AddCostFromIndex';

const tableHeadings = [
    "Select",
    "ID",
    "Company",
    "Description",
    "Cost Code",
    "Resource Code",
    "Rates",
    "Status"
];

export const Index = () => {
    const dispatch = useDispatch();

    const selectedProject = useSelector((state: RootState) => state.tabs.projectTab) //subsrcibe to the project currently selected

    const { data: indexData } = useGetIndexesQuery(); //for retrieving all indexes
    const [deleteSelectedIndex] = useDeleteIndexMutation()

    const [addIndex, setaddIndex] = useState(false); //true when user clicks "Add Cost"
    const [addCostFromIndex, setaddCostFromIndex] = React.useState<boolean>(false)
    const [editIndex, seteditIndex] = useState(false)

    const [checkedIndex, setcheckedIndex] = useState<string>() //indicate which companies have been selected
    // const [indexToEdit, setindexToEdit] = useState<index>();

    const viewRates = useSelector((state: RootState) => state.rates.ratesOpen)

    const manageChecks = (e: React.ChangeEvent<HTMLInputElement>, index: index) => {
        (document.getElementsByName(`${checkedIndex}`) as NodeListOf<HTMLInputElement>).forEach(element => element.checked = false)
        if (e.currentTarget.checked) {
            setcheckedIndex(e.currentTarget.name);
            // setindexToEdit(index);
        }
        else setcheckedIndex("")
    };

    const closeAddIndexModal = () => {
        setaddIndex(false)
    }

    const closeAddCostFromIndex = () => {
        setaddCostFromIndex(false)
    }

    return (
        <div>
            <div className="modal-subTabs">
                <span id="addIndex" onClick={(e: React.MouseEvent<HTMLSpanElement>) => { setaddIndex(true); seteditIndex(false) }} className="modal-subTabs-item">
                    <button className='universal-search-button'>Add Index</button>
                </span>
                {checkedIndex &&
                    <span className="modal-subTabs-item" onClick={() => { deleteSelectedIndex(checkedIndex); setcheckedIndex("") }}>
                        <button className='universal-delete-button'>Delete</button>
                    </span>
                }
            </div>
            <div className="costCodes-modal-pageSplit-column">
                {addIndex ? <div><AddIndex closeAddIndexModal={closeAddIndexModal} addIndex={addIndex} /> <hr></hr></div> : null}
                {addCostFromIndex && <AddCostFromIndex closeAddCostFromIndex={closeAddCostFromIndex} addCostFromIndex={addCostFromIndex} indexID={checkedIndex} />}
                <div className='modal-viewRates'>
                    {
                        viewRates && <ViewRates />
                    }
                </div>
                <Table className="generic-table-class" striped bordered>
                    <thead>
                        <tr>
                            {tableHeadings.map(heading => {
                                return <th>{heading}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {indexData?.map(index => {
                            if (index.project == selectedProject) {
                                return <>
                                    <tr>
                                        <td><input name={index.id} type="checkbox" onChange={(e: React.ChangeEvent<HTMLInputElement>) => manageChecks(e, index)} ></input></td>
                                        <td>{index.id}</td>
                                        <td>{index.company}</td>
                                        <td>{index.description}</td>
                                        <td>{index.subCostCode === "0" ? "N/A" : index.subCostCode}</td>
                                        <td>{index.resourceCode}</td>
                                        <td>
                                            <div >
                                                <Binoculars onClick={() => dispatch(openViewRates({ rates: index.rates, indexTitle: index.description }))} />
                                            </div>
                                        </td>
                                        <td>{index.status === 1 ? "Open" : "Closed"}</td>
                                    </tr>
                                    {checkedIndex == index.id && <>
                                        <>
                                            <EditIndex {...index} />
                                        </>
                                        <tr>
                                            <td colSpan={tableHeadings.length} >
                                                <div className='index-edit-delete-row' >
                                                    <span >
                                                        <button onClick={() => setaddCostFromIndex(true)} className='universal-add-button'>Add Cost From This Index</button>
                                                    </span>
                                                    <span onClick={() => deleteSelectedIndex(index.id!)}>
                                                        <button className='universal-delete-button'>Delete Index</button>
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                        <br></br>
                                    </>
                                    }
                                </>
                            }
                        })}
                    </tbody>
                </Table>
            </div>
        </div >
    )
}
