import { API } from "../../APIs/moduleAPI";
import { editObject } from "../../../types/edit";
import { index } from "../../../types";

export const extendedIndexAPI = API
    .enhanceEndpoints({ addTagTypes: ['Index' as const] })
    .injectEndpoints({
        endpoints: builder => ({
            getIndexes: builder.query<index[], void>({
                query: () => {
                    return {
                        url: "costTracking/getAllIndexes",
                        method: "GET"
                    }
                },
                providesTags: ["Index"]
            }),
            getIndex: builder.query<index, string | undefined>({
                query(id) {
                    return {
                        url: `costTracking/getIndex/${id}`,
                        method: 'GET'
                    }
                },
                providesTags: ["Index"]
            }),
            addIndex: builder.mutation<index, index>({
                query(index) {
                    return {
                        url: 'costTracking/createIndex',
                        method: 'POST',
                        body: index
                    }
                },
                invalidatesTags: ["Index"]
            }),
            deleteIndex: builder.mutation<index, string>({
                query(id) {
                    return {
                        url: `costTracking/deleteIndex/${id}`,
                        method: "DELETE",
                    }
                },
                invalidatesTags: ["Index"]
            }),
            editIndex: builder.mutation<index, editObject>({
                query(editObject) {
                    return {
                        url: `costTracking/editIndex/${editObject.id}`,
                        method: "POST",
                        body: editObject
                    }
                },
                invalidatesTags: ["Index"]
            }),
        }),
        overrideExisting: true
    });

export const {
    useGetIndexesQuery,
    useGetIndexQuery,
    useAddIndexMutation,
    useDeleteIndexMutation,
    useEditIndexMutation
} = extendedIndexAPI