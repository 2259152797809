import { API } from "../../APIs/moduleAPI";
import { project } from "../../../types/project";
import { editObject } from "../../../types/edit";

const extendedProjectsAPI = API
    .enhanceEndpoints({ addTagTypes: ['Project' as const] })
    .injectEndpoints({
        endpoints: builder => ({
            getProjects: builder.query<project[], void>({
                query: () => {
                    return {
                        url: 'costTracking/getAllProjects',
                        method: 'GET'
                    }
                },
                providesTags: ['Project']
            }),
            getProject: builder.query<project[], string>({
                query(id) {
                    return {
                        url: `costTracking/getIndividualProject/${id}`,
                        method: 'GET'
                    }
                },
                providesTags: ['Project']
            }),
            addProject: builder.mutation<project[], project>({
                query(project) {
                    return {
                        url: 'costTracking/createNewProject',
                        method: "POST",
                        body: project
                    }
                },
                invalidatesTags: ["Project"]
            }),
            editProject: builder.mutation<project, project>({
                query(project) {
                    return {
                        url: `costTracking/editProject/${project._id}`,
                        method: "POST",
                        body: project
                    }
                },
                invalidatesTags: ["Project"]
            })
        }),
        overrideExisting: false
    });

export const {
    useGetProjectQuery,
    useGetProjectsQuery,
    useAddProjectMutation,
    useEditProjectMutation
} = extendedProjectsAPI