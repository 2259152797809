import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { useGetIndexesQuery } from '../../../../../redux/injectionEndpoints/costTracking/indexEndpoint';
import Multiselect from 'multiselect-react-dropdown';
import { forDropdown, indexMultiselectDropdown, companyMultiselectDropdown, costCodeMultiselectDropdown, resourceCodeMultiselectDropdown } from '../../../../../functions/costTracking/indexMultiselectDropdown';
import { useGetCompaniesQuery } from '../../../../../redux/injectionEndpoints/costTracking/companiesEndpoint';
import { useGetCostCodesQuery } from '../../../../../redux/injectionEndpoints/costTracking/costCodeEndpoint';
import { useGetResourceCodesQuery } from '../../../../../redux/injectionEndpoints/costTracking/resourceCodeEndpoint';
import "./search.css"
import { useDeleteCostMutation, useGetCostsQuery, useSearchCostsQuery } from '../../../../../redux/injectionEndpoints/costTracking/costsEndpoint';
import { dollarConverter } from '../../../../../functions/dollarConverter';
import { cost } from '../../../../../types/cost';
import { EditCost } from '../editItems/EditCost';
import { refineSearch } from '../../../../../functions/costTracking/refineSearch';
import { DateRange } from '../../../../misc/DateRange';

const statusOptions = [
    "Open",
    "Approved",
    "Pending",
    "Rejected"
]

export const SearchCosts = () => {
    //DELETE COST FUNCTION
    const [deleteSelectedCost] = useDeleteCostMutation();

    const selectedProject = useSelector((state: RootState) => state.tabs.projectTab)

    //ALL OF OUR DATA
    const { data: indexData } = useGetIndexesQuery(); //for retrieving all indexes
    const { data: companyData } = useGetCompaniesQuery(); //for retrieving all companies
    const { data: costCodeData } = useGetCostCodesQuery(); //for retrieving all cost codes
    const { data: resourceCodeData } = useGetResourceCodesQuery(); //for retrieving all resource codes

    const [costSearchFields, setcostSearchFields] = useState<string[]>([]) //holds whatever fields are requested for searching
    const [costSearchValues, setcostSearchValues] = useState<Array<string[]>>([])

    //SUBSCRIBE TO BACKEND DATA FROM THE SEARCH
    const { data: searchedCosts, refetch } = useSearchCostsQuery({
        searchValues: costSearchValues,
        searchedFields: costSearchFields
    });

    const { data: costs } = useGetCostsQuery();

    //DATE RANGE
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    //UPDATE LOCAL STATE OF SEARCH INPUTS
    const selectIndexes = (selectedList: forDropdown[], selectedItem: forDropdown) => {
        if (selectedList.length > 0) { //confirm the selected list has values (something is selected)
            if (costSearchFields.find(field => field == "index")) {//if index is already searched
                const index = costSearchFields.indexOf("index"); //identify where in the array the index search field is
                setcostSearchValues(oldValues => {
                    var newVals = [...oldValues] //mutatable list
                    newVals[index] = selectedList.map(listItem => listItem.id); //index of vals from index of fields
                    return newVals //return updated vals array
                })
            }
            else {
                setcostSearchFields(oldFields => [...oldFields, "index"]) //add index to the search fields
                setcostSearchValues(oldValues => [...oldValues, selectedList.map(listItem => listItem.id)]) //add selected index array to the search values
            }
        }
        refetch();
    }
    const selectCompanies = (selectedList: forDropdown[], selectedItem: forDropdown) => {
        if (selectedList.length > 0) { //confirm the selected list has values (something is selected)
            if (costSearchFields.find(field => field == "company")) {//if companies are already searched
                const index = costSearchFields.indexOf("company"); //identify where in the array the company search field is
                setcostSearchValues(oldValues => {
                    var newVals = [...oldValues] //mutatable list
                    newVals[index] = selectedList.map(listItem => listItem.name); //index of vals from index of fields
                    return newVals //return updated vals array
                })
            }
            else {
                setcostSearchFields(oldFields => [...oldFields, "company"]) //add index to the search fields
                setcostSearchValues(oldValues => [...oldValues, selectedList.map(listItem => listItem.name)]) //add selected index array to the search values
            }
        }
        else {
            if (costSearchFields.find(field => field == "company")) {//if companies are already searched
                const index = costSearchFields.indexOf("company"); //identify where in the array the company search field is
                setcostSearchFields(oldFields => oldFields.filter(field => field !== "company"))
                setcostSearchValues(oldValues => oldValues.filter((field, fieldIndex) => fieldIndex !== index))
            }
        }
        refetch();
    }
    const selectCostCodes = (selectedList: forDropdown[], selectedItem: forDropdown) => {
        if (selectedList.length > 0) { //confirm the selected list has values (something is selected)
            if (costSearchFields.find(field => field == "costCode")) {//if companies are already searched
                const index = costSearchFields.indexOf("costCode"); //identify where in the array the company search field is
                setcostSearchValues(oldValues => {
                    var newVals = [...oldValues] //mutatable list
                    newVals[index] = selectedList.map(listItem => listItem.name); //index of vals from index of fields
                    return newVals //return updated vals array
                })
            }
            else {
                setcostSearchFields(oldFields => [...oldFields, "costCode"]) //add index to the search fields
                setcostSearchValues(oldValues => [...oldValues, selectedList.map(listItem => listItem.name)]) //add selected index array to the search values
            }
        }
        else {
            if (costSearchFields.find(field => field == "costCode")) {//if companies are already searched
                const index = costSearchFields.indexOf("costCode"); //identify where in the array the company search field is
                setcostSearchFields(oldFields => oldFields.filter(field => field !== "costCode"))
                setcostSearchValues(oldValues => oldValues.filter((field, fieldIndex) => fieldIndex !== index))
            }
        }
        refetch();
    }
    const selectStatus = (selectedList: forDropdown[], selectedItem: forDropdown) => {
        if (selectedList.length > 0) { //confirm the selected list has values (something is selected)
            if (costSearchFields.find(field => field == "status")) {//if companies are already searched
                const index = costSearchFields.indexOf("status"); //identify where in the array the company search field is
                setcostSearchValues(oldValues => {
                    var newVals = [...oldValues] //mutatable list
                    newVals[index] = selectedList.map(listItem => listItem.name); //index of vals from index of fields
                    return newVals //return updated vals array
                })
            }
            else {
                setcostSearchFields(oldFields => [...oldFields, "status"]) //add index to the search fields
                setcostSearchValues(oldValues => [...oldValues, selectedList.map(listItem => listItem.name)]) //add selected index array to the search values
            }
        }
        else {
            if (costSearchFields.find(field => field == "status")) {//if companies are already searched
                const index = costSearchFields.indexOf("status"); //identify where in the array the company search field is
                setcostSearchFields(oldFields => oldFields.filter(field => field !== "status"))
                setcostSearchValues(oldValues => oldValues.filter((field, fieldIndex) => fieldIndex !== index))
            }
        }
        refetch();
    }
    const selectResourceCodes = (selectedList: forDropdown[], selectedItem: forDropdown) => {
        if (selectedList.length > 0) { //confirm the selected list has values (something is selected)
            if (costSearchFields.find(field => field == "resourceCode")) {//if companies are already searched
                const index = costSearchFields.indexOf("resourceCode"); //identify where in the array the company search field is
                setcostSearchValues(oldValues => {
                    var newVals = [...oldValues] //mutatable list
                    newVals[index] = selectedList.map(listItem => listItem.name); //index of vals from index of fields
                    return newVals //return updated vals array
                })
            }
            else {
                setcostSearchFields(oldFields => [...oldFields, "resourceCode"]) //add index to the search fields
                setcostSearchValues(oldValues => [...oldValues, selectedList.map(listItem => listItem.name)]) //add selected index array to the search values
            }
        }
        else {
            if (costSearchFields.find(field => field == "resourceCode")) {//if companies are already searched
                const index = costSearchFields.indexOf("resourceCode"); //identify where in the array the company search field is
                setcostSearchFields(oldFields => oldFields.filter(field => field !== "resourceCode"))
                setcostSearchValues(oldValues => oldValues.filter((field, fieldIndex) => fieldIndex !== index))
            }
        }
        refetch();
    }

    const selectdate = (start: string, end: string, singleDateSearch: boolean, showBlanks: boolean) => {
        setStartDate(start);
        setEndDate(end);

        if (costSearchFields.find(field => field == "date")) {//if dates are already searched
            const index = costSearchFields.indexOf("date"); //identify where in the array the company search field is
            setcostSearchValues(oldValues => {
                var newVals = [...oldValues] //mutatable list
                if (singleDateSearch) {
                    newVals[index] = [start]; //index of vals from index of fields
                } else { //add start and end date if we are searching date range
                    newVals[index] = [start, end]; //index of vals from index of fields
                }
                return newVals //return updated vals array
            })
        }
        else {
            setcostSearchFields(oldFields => [...oldFields, "date"]) //add index to the search fields
            if (singleDateSearch) {
                setcostSearchValues(oldValues => [...oldValues, [start]]) //add selected index array to the search values
            } else {
                setcostSearchValues(oldValues => [...oldValues, [start, end]]) //add selected index array to the search values
            }
        }
    }

    const selectdocketNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length > 0) {
            if (costSearchFields.find(field => field == "docketNumber")) {//if companies are already searched
                const index = costSearchFields.indexOf("docketNumber"); //identify where in the array the company search field is
                setcostSearchValues(oldValues => {
                    var newVals = [...oldValues] //mutatable list
                    newVals[index] = [e.target.value]; //index of vals from index of fields
                    return newVals //return updated vals array
                })
            }
            else {
                setcostSearchFields(oldFields => [...oldFields, "docketNumber"]) //add index to the search fields
                setcostSearchValues(oldValues => [...oldValues, [e.target.value]]) //add selected index array to the search values
            }
        }
        else {
            if (costSearchFields.find(field => field == "docketNumber")) {//if companies are already searched
                const index = costSearchFields.indexOf("docketNumber"); //identify where in the array the company search field is
                setcostSearchFields(oldFields => oldFields.filter(field => field !== "docketNumber"))
                setcostSearchValues(oldValues => oldValues.filter((field, fieldIndex) => fieldIndex !== index))
            }
        }
    }

    const selectpurchaseOrder = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length > 0) {
            if (costSearchFields.find(field => field == "purchaseOrder")) {//if companies are already searched
                const index = costSearchFields.indexOf("purchaseOrder"); //identify where in the array the company search field is
                setcostSearchValues(oldValues => {
                    var newVals = [...oldValues] //mutatable list
                    newVals[index] = [e.target.value]; //index of vals from index of fields
                    return newVals //return updated vals array
                })
            }
            else {
                setcostSearchFields(oldFields => [...oldFields, "purchaseOrder"]
                ) //add index to the search fields
                setcostSearchValues(oldValues => [...oldValues, [e.target.value]]) //add selected index array to the search values
            }
        }
        else {
            if (costSearchFields.find(field => field == "purchaseOrder")) {//if companies are already searched
                const index = costSearchFields.indexOf("purchaseOrder"); //identify where in the array the company search field is
                setcostSearchFields(oldFields => oldFields.filter(field => field !== "purchaseOrder"))
                setcostSearchValues(oldValues => oldValues.filter((field, fieldIndex) => fieldIndex !== index))
            }
        }
    }

    const selectdescription = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length > 0) {
            if (costSearchFields.find(field => field == "description")) {//if companies are already searched
                const index = costSearchFields.indexOf("description"); //identify where in the array the company search field is
                setcostSearchValues(oldValues => {
                    var newVals = [...oldValues] //mutatable list
                    newVals[index] = [e.target.value]; //index of vals from index of fields
                    return newVals //return updated vals array
                })
            }
            else {
                setcostSearchFields(oldFields => [...oldFields, "description"]) //add index to the search fields
                setcostSearchValues(oldValues => [...oldValues, [e.target.value]]) //add selected index array to the search values
            }
        }
        else {
            if (costSearchFields.find(field => field == "description")) {//if companies are already searched
                const index = costSearchFields.indexOf("description"); //identify where in the array the company search field is
                setcostSearchFields(oldFields => oldFields.filter(field => field !== "description"))
                setcostSearchValues(oldValues => oldValues.filter((field, fieldIndex) => fieldIndex !== index))
            }
        }
    }

    const selectinvoiceNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length > 0) {
            if (costSearchFields.find(field => field == "invoiceNumber")) {//if companies are already searched
                const index = costSearchFields.indexOf("invoiceNumber"); //identify where in the array the company search field is
                setcostSearchValues(oldValues => {
                    var newVals = [...oldValues] //mutatable list
                    newVals[index] = [e.target.value]; //index of vals from index of fields
                    return newVals //return updated vals array
                })
            }
            else {
                setcostSearchFields(oldFields => [...oldFields, "invoiceNumber"]) //add index to the search fields
                setcostSearchValues(oldValues => [...oldValues, [e.target.value]]) //add selected index array to the search values
            }
        }
        else {
            if (costSearchFields.find(field => field == "invoiceNumber")) {//if companies are already searched
                const index = costSearchFields.indexOf("invoiceNumber"); //identify where in the array the company search field is
                setcostSearchFields(oldFields => oldFields.filter(field => field !== "invoiceNumber"))
                setcostSearchValues(oldValues => oldValues.filter((field, fieldIndex) => fieldIndex !== index))
            }
        }
    }

    const selecttotalCost = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.value.length > 0) {
            if (costSearchFields.find(field => field == "totalCost")) {//if companies are already searched
                const index = costSearchFields.indexOf("totalCost"); //identify where in the array the company search field is
                setcostSearchValues(oldValues => {
                    var newVals = [...oldValues] //mutatable list
                    newVals[index] = [e.target.value]; //index of vals from index of fields
                    return newVals //return updated vals array
                })
            }
            else {
                setcostSearchFields(oldFields => [...oldFields, "totalCost"]) //add index to the search fields
                setcostSearchValues(oldValues => [...oldValues, [e.target.value]]) //add selected index array to the search values
            }
        }
        else {
            if (costSearchFields.find(field => field == "totalCost")) {//if companies are already searched
                const index = costSearchFields.indexOf("totalCost"); //identify where in the array the company search field is
                setcostSearchFields(oldFields => oldFields.filter(field => field !== "totalCost"))
                setcostSearchValues(oldValues => oldValues.filter((field, fieldIndex) => fieldIndex !== index))
            }
        }
    }

    //MANAGING COSTS PER PAGE
    const [costsPerPage, setcostsPerPage] = useState<number>(50) //indicate how many costs to show per page
    const [page, setpage] = useState<number>(1);

    const pageUp = (e: React.MouseEvent<SVGElement>) => {
        const numberOfPages = Math.ceil(searchedCosts!.length / costsPerPage)
        if (page < numberOfPages) setpage(page + 1)
    };

    //CHECKED COSTS
    const [checkedCost, setcheckedCost] = useState<string[]>([]) //indicate which companies have been selected
    const manageChecks = (e: React.ChangeEvent<HTMLInputElement>, cost: cost) => {
        // (document.getElementsByName(`${checkedCost}`) as NodeListOf<HTMLInputElement>).forEach(element => element.checked = false)
        if (e.target.checked) { //if box is checked
            setcheckedCost([...checkedCost, e.target.name]); //add new cost to checked cost array
        }
        else setcheckedCost([...checkedCost.filter(cost => cost !== e.target.name)]) //else if box is unchecked, set to empty string
    };

    //DELETE COSTS
    const deleteCost = (e: React.MouseEvent<HTMLSpanElement>) => {
        e.preventDefault();
        checkedCost.forEach(cost => {
            deleteSelectedCost(cost)
        })
        setcheckedCost([]);
    };

    const deleteIndividualCost = (costId: string) => {
        deleteSelectedCost(costId)
    }

    const pickRowColor = (cost: cost) => {
        switch (cost.status) {
            case "Approved":
            case "approved":
                return "rgba(11, 156, 49, 0.4)"
            case "Rejected":
            case "rejected":
                return "rgba(255, 99, 71, 0.4)"
            case "Pending":
            case "pending":
                return "rgba(255, 255, 0, 0.4)"
            default:
                return "rgb(255, 255, 255)"
        }
    };

    return (
        <>
            <tr>
                <td></td>
                <td>
                    {indexData && <Multiselect
                        options={indexMultiselectDropdown(indexData)}
                        showCheckbox
                        displayValue='name'
                        onSelect={selectIndexes}
                        onRemove={selectIndexes}
                        style={{
                            searchBox: {
                                maxWidth: "80px"
                            }
                        }}
                    />}
                </td>
                <td>
                    <DateRange onSearch={selectdate}></DateRange>
                </td>
                <td>
                    {resourceCodeData && <Multiselect
                        options={resourceCodeMultiselectDropdown(resourceCodeData)}
                        showCheckbox
                        displayValue='name'
                        onSelect={selectResourceCodes}
                        onRemove={selectResourceCodes}
                        style={{
                            searchBox: {
                                maxWidth: "120px"
                            }
                        }}
                    />}
                </td>
                <td>
                    <input onChange={selectdocketNumber} style={{ maxWidth: "130px" }}></input>
                </td>
                <td>
                    <input onChange={selectpurchaseOrder} style={{ maxWidth: "130px" }}></input>
                </td>
                <td>
                    {companyData && <Multiselect
                        options={companyMultiselectDropdown(companyData)}
                        showCheckbox
                        displayValue='name'
                        onSelect={selectCompanies}
                        onRemove={selectCompanies}
                        style={{
                            searchBox: {
                                maxWidth: "150px"
                            }
                        }}
                    />}
                </td>
                <td>
                    <input onChange={selectdescription} style={{ maxWidth: "150px" }}></input>
                </td>
                <td>
                    {costCodeData && <Multiselect
                        options={costCodeMultiselectDropdown(costCodeData)}
                        showCheckbox
                        displayValue='name'
                        onSelect={selectCostCodes}
                        onRemove={selectCostCodes}
                        style={{
                            searchBox: {
                                maxWidth: "150px"
                            }
                        }}
                    />}
                </td>
                <td>
                    <input onChange={selectinvoiceNumber} style={{ maxWidth: "130px" }}></input>
                </td>
                <td>
                    <input type="number" onChange={selecttotalCost} style={{ maxWidth: "130px" }}></input>
                </td>
                <td>
                    <Multiselect
                        options={statusOptions.map(option => {
                            return {
                                name: option,
                                id: option
                            }
                        })}
                        showCheckbox
                        displayValue='name'
                        onSelect={selectStatus}
                        onRemove={selectStatus}
                        style={{
                            searchBox: {
                                maxWidth: "120px"
                            }
                        }}
                    />
                </td>
            </tr>
            {costs && refineSearch(costs, costSearchFields, costSearchValues).map((cost, index) => {
                if (cost.project == selectedProject) {
                    if (cost.project == selectedProject) {
                        return <>
                            <tr key={index} style={{ backgroundColor: pickRowColor(cost) }}>
                                <td><input name={cost.id?.toString()} type="checkbox" className="tableCheckbox" onChange={(e: React.ChangeEvent<HTMLInputElement>) => manageChecks(e, cost)} ></input></td>
                                <td>{cost.index}</td>
                                <td>{cost.date}</td>
                                <td>{cost.resourceCode}</td>
                                <td>{cost.docketNumber}</td>
                                <td>{cost.purchaseOrder}</td>
                                <td>{cost.company}</td>
                                <td>{cost.description}</td>
                                <td>{cost.costCode}</td>
                                <td>{cost.invoiceNumber}</td>
                                <td>{dollarConverter(cost.totalCost, "$", "")}</td>
                                <td>{cost.status}</td>
                            </tr>
                            {checkedCost.length === 1 && checkedCost[0] === cost.id!.toString() &&
                                <>
                                    <EditCost cost={costs.find(searchedCost => searchedCost.id === cost.id)!} deleteCost={deleteIndividualCost} />
                                </>
                            }
                        </>
                    }
                }
            })}
        </>
    )
}
