import React, { useState } from 'react';
import { costTemplate } from '../../../../types/costTemplate';
import { useGetCostsQuery, useDeleteCostMutation } from '../../../../redux/injectionEndpoints/costTracking/costsEndpoint';
import { AddTemplate } from './add items/AddTemplate';
import { Table } from 'react-bootstrap';
import { useDeleteTemplateMutation, useGetTemplatesQuery } from '../../../../redux/injectionEndpoints/costTracking/templatesEndpoint';
import { Binoculars } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from 'react-redux';
import { openViewCosts } from '../../../../redux/reducers/viewCostsReducer';
import { RootState } from '../../../../redux/store';
import { ViewCosts } from './viewItems/ViewCosts';

const primaryHeadings = [
    "Select",
    "Name",
    "Recurring?",
    "Occurance",
    "Start Date",
    "End Date",
    "Costs"
]

const costHeadings = [
    "Index",
    "Date",
    "Resource Code",
    "Docket Number",
    "Purchase Order",
    "Company",
    "Description",
    "Cost Code",
    "Invoice Number",
    "Total Cost"
]

export const Templates = () => {
    const dispatch = useDispatch();
    const viewCosts = useSelector((state: RootState) => state.costs.costsOpen)
    const selectedProject = useSelector((state: RootState) => state.tabs.projectTab)

    const { templates } = useGetTemplatesQuery(undefined, {
        selectFromResult: ({data}) => ({
            templates: data?.filter(template => template.project === selectedProject)
        })
    });
    const [deleteSelectedTemplate] = useDeleteTemplateMutation();

    const [addTemplate, setaddTemplate] = useState(false); //true when user clicks "Add Template"
    const [checkedTemplate, setcheckedTemplate] = useState<string>("") //indicate which templates have been selected

    const deleteTemplate = (e: React.MouseEvent<HTMLSpanElement>) => {
        deleteSelectedTemplate(checkedTemplate)
    }

    const manageChecks = (e: React.ChangeEvent<HTMLInputElement>, template: costTemplate) => {
        (document.getElementsByName(`${checkedTemplate}`) as NodeListOf<HTMLInputElement>).forEach(element => element.checked = false)

        if (e.target.checked) { //if box is checked
            setcheckedTemplate(e.target.name); //add new cost to checked cost array
            setaddTemplate(false)
        }
        else setcheckedTemplate("") //else if box is unchecked, set to empty string
    };

    const closeAddTemplate = () => {
        setaddTemplate(false)
    }

    return (
        <div>
            <div className="modal-subTabs">
                {addTemplate ? null :
                    <span onClick={(e: React.MouseEvent<HTMLSpanElement>) => { setaddTemplate(true) }} className="modal-subTabs-item">
                        <button className='universal-save-button'>Add Template</button>
                    </span>
                }
                {addTemplate ?
                    <span onClick={(e: React.MouseEvent<HTMLSpanElement>) => setaddTemplate(false)} className="modal-subTabs-item">
                        <button className='universal-delete-button'>Hide</button>
                    </span> : null
                }
                {checkedTemplate.length == 0 ? null :
                    <span className="modal-subTabs-item" onClick={deleteTemplate}>
                        <button className='universal-delete-button'>Delete</button>
                    </span>
                }
                {
                    viewCosts && <ViewCosts />
                }
            </div>
            <div className="costCodes-modal-pageSplit-column">
                {addTemplate ? <AddTemplate closeAddTemplate={closeAddTemplate} addTemplate={addTemplate} /> : null}
                <Table className="generic-table-class" bordered hover>
                    <thead>
                        <tr>
                            {primaryHeadings.map(heading => {
                                return <th>
                                    {heading}
                                </th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {templates?.map(costTemplate => {
                            if (costTemplate.project == selectedProject) {
                                return <>
                                    <tr>
                                        <td><input name={costTemplate.id?.toString()} type="checkbox" className="tableCheckbox" onChange={(e: React.ChangeEvent<HTMLInputElement>) => manageChecks(e, costTemplate)} checked={checkedTemplate === costTemplate.id!.toString()} ></input></td>
                                        <td>{costTemplate.name}</td>
                                        <td>{costTemplate.recurring ? "Yes" : "No"}</td>
                                        <td>{costTemplate.frequency}</td>
                                        <td>{costTemplate.occuranceStart}</td>
                                        <td>{costTemplate.occuranceEnd}</td>
                                        <td><Binoculars onClick={() => dispatch(openViewCosts({ costs: costTemplate.costs, costTitle: costTemplate.name }))} /></td>
                                    </tr>
                                </>
                            }
                        })}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}
