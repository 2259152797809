import { cost } from "../../../types/cost";

export const generateProductionTrackingDates = (costs: cost[] | undefined, dollarTrackingCode: string) => {
    if (!costs || !dollarTrackingCode) return []
    //costs have not been filtered by dollarTrackingCode at this point
    const filteredCosts = costs.filter(cost => cost.costCode === dollarTrackingCode);
    const dollarTrackedArrayDateFiltered = filteredCosts.sort((a, b) => { //sort from smallest (earliest) to largest (latest)
        if (Date.parse(a.date) > Date.parse(b.date)) {
            return 1
        }
        if (Date.parse(a.date) < Date.parse(b.date)) {
            return -1
        }
        else return 0
    })

    const earliestdollarTrackedDate = dollarTrackedArrayDateFiltered[0].date; //earliest date
    const latestdollarTrackedDate = dollarTrackedArrayDateFiltered[dollarTrackedArrayDateFiltered.length - 1].date; //latest date

    //calculate Dates array
    const numberOfDays = (Math.abs(Date.parse(latestdollarTrackedDate) - Date.parse(earliestdollarTrackedDate)) / 1000 / 60 / 60 / 24) + 1 //find the number of days to track consecutively.

    const firstDate = new Date(Date.parse(earliestdollarTrackedDate) - 1000 * 60 * 60 * 24);
    //need to ensure all months and days in the strings are double digits
    const firstDateMonthString = firstDate.getMonth() + 1 < 10 ? "0".concat((firstDate.getMonth() + 1).toString()) : (firstDate.getMonth() + 1).toString()
    const firstDateDayString = firstDate.getDate() < 10 ? "0".concat(firstDate.getDate().toString()) : firstDate.getDate().toString()
    //concat them all together
    const firstDateString = firstDate.getFullYear().toString().concat("-", firstDateMonthString, "-", firstDateDayString)

    let dates: string[] = [firstDateString] //initiate the dates array (x values in our graph)

    for (var i = 0; i < numberOfDays + 1; i++) { //create the dates array by consecutively adding a day to the earliest date and pushing it to the dates array until we reach the total number of days
        var currentDate = new Date(dates[i])
        var nextDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000)
        //update the number strings to have two digits in months and date fields
        const nextDateMonthString = nextDate.getMonth() + 1 < 10 ? "0".concat((nextDate.getMonth() + 1).toString()) : (nextDate.getMonth() + 1).toString()
        const nextDateDayString = nextDate.getDate() < 10 ? "0".concat((nextDate.getDate()).toString()) : (nextDate.getDate()).toString()
        dates.push(nextDate.getFullYear().toString().concat("-", nextDateMonthString, "-", nextDateDayString))
    }

    return dates
}