import { createSlice } from '@reduxjs/toolkit';

export interface searchState {
    index: boolean,
    costs: boolean,
    companies: boolean,
}

const initialState: searchState = {
    index: false,
    costs: false,
    companies: false
}

export const searchSlice = createSlice({
    name: "search",
    initialState,
    reducers: {
        openIndexSearch(state) {
            return {
                ...state, index: true, costs: false, companies: false
            }
        },
        closeIndexSearch(state) {
            return {
                ...state, index: false
            }
        },
        openCostsSearch(state) {
            return {
                ...state, index: false, costs: true, companies: false
            }
        },
        closeCostsSearch(state) {
            return {
                ...state, costs: false
            }
        },
        openCompaniesSearch(state) {
            return {
                ...state, index: false, costs: false, companies: true
            }
        },
        closeCompaniesSearch(state) {
            return {
                ...state, companies: false
            }
        }
    }
});

export const { 
    openCompaniesSearch, 
    openCostsSearch, 
    openIndexSearch, 
    closeCompaniesSearch, 
    closeCostsSearch, 
    closeIndexSearch 
} = searchSlice.actions

export default searchSlice.reducer