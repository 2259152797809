import { cost } from "../../../types/cost";
import { production } from "../../../types/productionTracking";

type returnDataPoint = {
    x: string, //dates
    y: number //production rates
}

export type returnDataWithBudget = {
    productionQuantities: returnDataPoint[],
    dailySpend: returnDataPoint[],
    budgetUnitRates: returnDataPoint[],
    budgetDailyRates: returnDataPoint[]
}

export const generateProductionTrackingData = (dollarTrackingCode: string, costs: cost[], budgetUnitRate: number, budgetDailyRate: number, productionTracked: production[]): returnDataWithBudget => {

    const dollarTrackedArray = costs.filter(cost => cost.costCode === dollarTrackingCode)

    //productionQuantities
    let productionQuantities: returnDataPoint[] = []//initial final array

    productionQuantities = productionTracked.map(prod => {
        var trackedDayCost = dollarTrackedArray
            .filter(cost => cost.date === prod.date)
            .reduce((acc, curr) => acc + curr.totalCost, 0)

        if (trackedDayCost / prod.quantity) {
            return {
                x: prod.date,
                y: trackedDayCost / prod.quantity
            }
        } else {
            return {
                x: prod.date,
                y: 0
            }
        }
    })

    //dailySpend
    let dailySpend: returnDataPoint[] = []//initial final array
    const costsForTheDailyCost = costs.filter(cost => cost.costCode === dollarTrackingCode)

    dailySpend = productionTracked.map(prod => {
        var trackedDayCost = dollarTrackedArray
            .filter(cost => cost.date === prod.date)
            .reduce((acc, curr) => acc + curr.totalCost, 0)

        return {
            x: prod.date,
            y: trackedDayCost
        }
    })

    //budgetUnitRate
    let budgetUnitRates: returnDataPoint[] = []//initial final array
    budgetUnitRates = productionTracked.map(prod => {
        return {
            x: prod.date,
            y: budgetUnitRate
        }
    })

    //budgetDailyRate
    let budgetDailyRates: returnDataPoint[] = []//initial final array
    budgetDailyRates = productionTracked.map(prod => {
        return {
            x: prod.date,
            y: budgetDailyRate
        }
    })

    return {
        productionQuantities,
        dailySpend,
        budgetUnitRates,
        budgetDailyRates,
    }
}