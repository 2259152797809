import { costCode } from "../../types/costCode";

export const costCodesBudgetCalculator = (costCodes: costCode[], costCode: string): number => {
    var totalBudget: number = 0

    costCodes.forEach(costCodeToCheck => {
        if (costCodeToCheck.code === costCode) {
            totalBudget += Number(costCodeToCheck.budget)
        }
    })

    return totalBudget
}

export const resourceCodesBudgetCalculator = (costCodes: costCode[], resourceCode: string): number => {
    var totalResourceCodeBudget: number = 0;

    costCodes.forEach(costCodeToCheck => {
        costCodeToCheck.resourceCodes.forEach(resourceCodeToCheck => {
            if (resourceCodeToCheck.resource === resourceCode) {
                totalResourceCodeBudget += Number(resourceCodeToCheck.total)
            }
        })
    })

    return totalResourceCodeBudget
}