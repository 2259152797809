import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { index } from '../../types';

export interface viewCostsPayload {
    costs: index[],
    costTitle: string
}

export interface viewCostsState {
    costsOpen: boolean,
    costs: index[] | null,
    costTitle: string
}

const initialState: viewCostsState = {
    costsOpen: false,
    costs: null,
    costTitle: ""
}

export const viewCostsSlice = createSlice({
    name: "viewCosts",
    initialState,
    reducers: {
        closeViewCosts(state) {
            return {
                ...state,
                costsOpen: false,
                costs: null,
                costTitle: ""
            }
        },
        openViewCosts(state, action: PayloadAction<viewCostsPayload>) {
            return {
                ...state,
                costsOpen: true,
                costs: action.payload.costs,
                costTitle: action.payload.costTitle
            }
        }
    }
});

export const { closeViewCosts, openViewCosts } = viewCostsSlice.actions

export default viewCostsSlice.reducer