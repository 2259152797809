import { costTemplate } from "../../../types/costTemplate";
import { dailyNotification } from '../../../types/dailyNotification'
import { editObject } from "../../../types/edit";
import { API } from "../../APIs/moduleAPI";

export const extendedTemplatesAPI = API
    .enhanceEndpoints({ addTagTypes: ['Template' as const] })
    .injectEndpoints({
        endpoints: builder => ({
            getTemplates: builder.query<costTemplate[], void>({
                query: () => {
                    return {
                        url: 'costTracking/getAllTemplates',
                        method: 'GET'
                    }
                },
                providesTags: ["Template"]
            }),
            getTemplate: builder.query<costTemplate, string>({
                query(id) {
                    return {
                        url: `costTracking/getTemplate/${id}`,
                        method: 'GET'
                    }
                },
                providesTags: ["Template"]
            }),
            addTemplate: builder.mutation<costTemplate, costTemplate>({
                query(template) {
                    return {
                        url: 'costTracking/createTemplate',
                        method: 'POST',
                        body: template
                    }
                },
                invalidatesTags: ['Template']
            }),
            deleteTemplate: builder.mutation<costTemplate, string>({
                query(id) {
                    return {
                        url: `costTracking/deleteTemplate/${id}`,
                        method: 'DELETE'
                    }
                },
                invalidatesTags: ['Template']
            }),
            editTemplate: builder.mutation<costTemplate[], editObject>({
                query(editObject) {
                    return {
                        url: `costTracking/editTemplate/${editObject.id}`,
                        method: "POST",
                        body: editObject
                    }
                },
                invalidatesTags: ['Template']
            })
        }),
        overrideExisting: false
    })

export const {
    useGetTemplatesQuery,
    useGetTemplateQuery,
    useAddTemplateMutation,
    useDeleteTemplateMutation,
    useEditTemplateMutation
} = extendedTemplatesAPI