import React, { useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import Table from 'react-bootstrap/Table';
import { useGetIndexesQuery, useGetIndexQuery } from '../../../../../redux/injectionEndpoints/costTracking/indexEndpoint';
import { useGetCostCodesQuery } from '../../../../../redux/injectionEndpoints/costTracking/costCodeEndpoint';
import { useGetResourceCodesQuery } from '../../../../../redux/injectionEndpoints/costTracking/resourceCodeEndpoint';
import { useGetCompaniesQuery } from '../../../../../redux/injectionEndpoints/costTracking/companiesEndpoint';
import { useAddCostMutation } from '../../../../../redux/injectionEndpoints/costTracking/costsEndpoint';
import { cost, costRate } from '../../../../../types/cost';
import { Trash3 } from 'react-bootstrap-icons';
import { dollarConverter } from '../../../../../functions/dollarConverter';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { NumberInput } from '../../../../misc/NumberInput';
import { StringInput } from '../../../../misc/StringInput';
import { Date } from '../../../../misc/Date';

export const topTableHeadings = [
    "Index",
    "Date",
    "Cost Code",
    "Resource Code",
    "Docket Number",
    "Purchase Order",
    "Company",
    "Description",
    "Invoice Number"
];

export const bottomTableHeadings = [
    "Description",
    "Rate",
    "Unit",
    "Quantity",
    "Sub-Total",
    ""
];

type props = {
    closeAddCostModal: () => void,
    addCost: boolean
}

export const AddCost = (props: props) => {
    const [index, setindex] = useState<string>("0");//default is no index selected. This means we render our inputs rather than index fields for the new cost

    const [date, setdate] = React.useState<string>("")
    const [costCode, setcostCode] = React.useState<string>("")
    const [resourceCode, setresourceCode] = React.useState<string>("")
    const [costDescription, setcostDescription] = React.useState<string>("")
    const [company, setcompany] = React.useState<string>("")
    const [docketNumber, setdocketNumber] = React.useState<string>("N/A")
    const [purchaseOrder, setpurchaseOrder] = React.useState<string>("N/A")
    const [invoiceNumber, setinvoiceNumber] = React.useState<string>("N/A")
    const [totalCost, settotalCost] = React.useState<number>(0)

    const project = useSelector((state: RootState) => state.tabs.projectTab);

    const [addCost] = useAddCostMutation();

    const { indexesData } = useGetIndexesQuery(undefined, {
        selectFromResult: ({ data }) => ({
            indexesData: data?.filter(costIndex => costIndex.project === project)
        })
    }); //for retrieving all indexes;
    const { data: indexData, refetch: indexRefetch } = useGetIndexQuery(index, { refetchOnMountOrArgChange: true }); //for retrieving an index
    const { costCodesData } = useGetCostCodesQuery(undefined, {
        selectFromResult: ({ data }) => ({
            costCodesData: data?.filter(costCode => costCode.project === project)
        })
    }); //for retrieving all resource codes
    const { resourceCodes } = useGetResourceCodesQuery(undefined, {
        selectFromResult: ({ data }) => ({
            resourceCodes: data?.filter(resourceCode => resourceCode.project === project)
        })
    }); //for retrieving all resource codes
    const { data: companies } = useGetCompaniesQuery();

    //adding rates to the cost
    const [rates, setrates] = useState<costRate[]>([])
    const [description, setdescription] = useState<string>("") //new rate to save
    const [rate, setrate] = useState<number>(0) //new rate to save
    const [unit, setunit] = useState<string>("") //new rate to save
    const [numberOf, setnumberOf] = useState<number>(0) //new rate to save 

    const [indexQuantities, setindexQuantities] = useState<number[]>([0]) //quantities array for indexed costs
    const [indexTotals, setindexTotals] = useState<number[]>([0]) //array with rates total costs

    const saveRate = (e: React.MouseEvent<HTMLButtonElement>) => { //save new rate from add rate option
        e.preventDefault();
        var newRate: costRate = {
            description,
            numberOf,
            unit,
            rate,
            valueOf: rate * numberOf
        }
        setrates([...rates, newRate])
        settotalCost([...rates, newRate].map(rate => rate.valueOf).reduce((prev, curr) => prev + curr, 0))
    }

    const deleteRate = (e: React.MouseEvent<SVGElement>) => { //delete rate from the add rate option
        e.preventDefault();
        const newRates = rates!.filter(rate => rate.description != e.currentTarget.id)
        setrates(newRates)
    };

    const setNewCostRates = (e: React.ChangeEvent<HTMLInputElement>, index: number, rate: number) => { //used to update rates when index not = 0 is selected
        var newIndexQuantities = [...indexQuantities]; //create new dummy rates variable
        var newindexTotals = [...indexTotals] //create new dummy totals variable

        newIndexQuantities[index] = Number(e.target.value) //update dummy rates variable based on changes in UI
        newindexTotals[index] = Number(e.target.value) * rate

        setindexQuantities(newIndexQuantities); //reset indexRates
        setindexTotals(newindexTotals); //reset totals
    }

    const saveCost = (e: React.MouseEvent<HTMLButtonElement>) => {
        const costRates: costRate[] = indexData ? indexData!.rates.map((rate, index) => ({ //set new rates for the new cost based on UI inputs and indexData
            description: rate.rateDescription!,
            rate: rate.rate!,
            unit: rate.unit!,
            numberOf: indexQuantities[index] ? indexQuantities[index] : 0,
            valueOf: indexQuantities[index] ? rate.rate! * indexQuantities[index] : 0
        })) : [];

        addCost({
            index,
            project,
            date,
            costCode: index === "0" ? costCode : indexData!.subCostCode!,
            resourceCode: index === "0" ? resourceCode : indexData!.resourceCode!,
            description: index === "0" ? costDescription : indexData!.description!,
            company: index === "0" ? company : indexData!.company!,
            rates: index === "0" ? rates : costRates,
            docketNumber,
            purchaseOrder,
            invoiceNumber,
            totalCost: index === "0" ? rates!.map(rate => rate.valueOf).reduce((prev, curr) => prev + curr, 0) : costRates!.map(rate => rate.valueOf).reduce((prev, curr) => prev + curr, 0),
            status: "Open"
        })

        setrates([])
        setdescription("")
        setrate(0)
        setunit("")
        setnumberOf(0)
    };

    return (
        <div>
            <Modal show={props.addCost} dialogClassName="modal-98w" >
                <Modal.Header closeButton onHide={() => props.closeAddCostModal()}>
                    <Modal.Title>Add Cost</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table>
                        <thead>
                            <tr>
                                {topTableHeadings.map(heading => {
                                    return <th>{heading}</th>
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <select className='universal-custom-select' name="index" onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setindex(e.target.value)} style={{ maxWidth: "180px" }}>
                                        <option value={"0"}>0</option>
                                        {indexesData && indexesData.map(index => {
                                            if (index.status === 1) {
                                                return <option value={index.id!}>{index.id} - {index.description}</option>
                                            }
                                        })}
                                    </select>
                                </td>
                                <td><Date changeStateFunction={setdate} /></td>
                                {index === "0" ?
                                    <td>
                                        <select className='universal-custom-select' name="costCode" onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setcostCode(e.target.value)}>
                                            <option value="0">Select</option>
                                            {costCodesData?.map(costCode => {
                                                return <option value={costCode.code.concat(".", costCode.subCode)}>{costCode.code.concat(".", costCode.subCode)}</option>
                                            })}
                                        </select>
                                    </td>
                                    :
                                    <td>
                                        {indexData ? <p>{indexData!.subCostCode}</p> : <p>No data</p>}
                                    </td>
                                }
                                {index === "0" ?
                                    <td>
                                        <select className='universal-custom-select' name="resourceCode" style={{ maxWidth: "80px" }} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setresourceCode(e.target.value)}>
                                            <option value="0">Select</option>
                                            {resourceCodes?.map(resourceCode => {
                                                return <option value={resourceCode.code}>{resourceCode.code}</option>
                                            })}
                                        </select>
                                    </td>
                                    :
                                    <td>
                                        {indexData ? <p>{indexData!.resourceCode}</p> : <p>No data</p>}
                                    </td>
                                }
                                <td><StringInput changeStateFunction={setdocketNumber} placeholder='Docket Number' /></td>
                                <td><StringInput changeStateFunction={setpurchaseOrder} placeholder='Purchase Order' /></td>
                                {index === "0" ?
                                    <td>
                                        <select className='universal-custom-select' name="company" onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setcompany(e.target.value)}>
                                            <option value="0">Select</option>
                                            {companies && companies.map(company => {
                                                return <option value={company.company}>{company.company}</option>
                                            })}
                                        </select>
                                    </td>
                                    :
                                    <td>
                                        {indexData ? <p>{indexData!.company}</p> : <p>No data</p>}
                                    </td>
                                }
                                {index === "0" ?
                                    <td><StringInput changeStateFunction={setcostDescription} placeholder='Description' /></td>
                                    :
                                    <td>
                                        {indexData ? <p>{indexData!.description}</p> : <p>No data</p>}
                                    </td>
                                }
                                <td><StringInput changeStateFunction={setinvoiceNumber} placeholder='Invoice Number' /></td>
                            </tr>
                        </tbody>
                    </Table>
                    <hr></hr>
                    <div id="add-cost-flex-column">
                        <Table style={{ maxWidth: "60%" }}>
                            <thead>
                                <tr>
                                    {bottomTableHeadings.map(heading => {
                                        return <th>{heading}</th>
                                    })}
                                </tr>
                            </thead>
                            {
                                index === "0" ?
                                    <tbody>
                                        <tr>
                                            <td><StringInput changeStateFunction={setdescription} placeholder='Description' /></td>
                                            <td><NumberInput changeStateFunction={setrate} placeholder='0' /></td>
                                            <td><StringInput changeStateFunction={setunit} placeholder='Unit' /></td>
                                            <td><NumberInput changeStateFunction={setnumberOf} placeholder='0' /></td>
                                            <td>{dollarConverter(rate * numberOf, "$", "")}</td>
                                            <td><button className="universal-small-save-button" onClick={saveRate}>Save Rate</button></td>
                                        </tr>
                                        {rates.map(rate => {
                                            return <tr>
                                                <td>{rate.description}</td>
                                                <td>{rate.rate}</td>
                                                <td>{rate.unit}</td>
                                                <td>{rate.numberOf}</td>
                                                <td>{dollarConverter(rate.valueOf, "$", "")}</td>
                                                <td><Trash3 id={rate.description} onClick={deleteRate}>Delete</Trash3></td>
                                            </tr>
                                        })}
                                        <tr>
                                            <th colSpan={bottomTableHeadings.length - 2}>TOTAL</th>
                                            <th>{dollarConverter(rates.map(rate => rate.valueOf).reduce((prev, curr) => prev + curr, 0), "$", "")}</th>
                                        </tr>
                                    </tbody>
                                    :
                                    <tbody>
                                        {indexData && indexData!.rates.map((rate, index) => {
                                            return <tr>
                                                <td>{rate.rateDescription}</td>
                                                <td>{rate.rate}</td>
                                                <td>{rate.unit}</td>
                                                <td><input type="number" id={rate.rateDescription} style={{ maxWidth: "80px" }} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setNewCostRates(e, index, rate.rate!) }} min={0} ></input></td>
                                                <td>{indexQuantities[index] ? indexQuantities[index] * rate.rate! : 0}</td>
                                            </tr>
                                        })
                                        }
                                        <tr>
                                            <th colSpan={bottomTableHeadings.length - 2}>TOTAL</th>
                                            <th>{
                                                indexTotals.map(total => (total)).reduce((prev, curr) => prev + curr)
                                            }</th>
                                        </tr>
                                    </tbody>
                            }
                        </Table>
                    </div>
                    <span id="save-cost-button" >
                        <button className="universal-save-button" onClick={saveCost}>Save Cost</button>
                    </span>
                </Modal.Body>
                <Modal.Footer>
                    <button className='universal-close-button' onClick={() => props.closeAddCostModal()}>Close</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
