import React, { FC } from 'react';
import { Modal, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { dollarConverter } from '../../../../../functions/dollarConverter';
import { closeViewRates } from '../../../../../redux/reducers/viewRatesReducer';
import { RootState } from '../../../../../redux/store';

const bottomTableHeadings = [
    "Rate",
    "Unit",
    "Rate Description",
]

export const ViewRates = () => {

    const dispatch = useDispatch()
    const { rates, indexTitle } = useSelector((state: RootState) => state.rates) //rates and index for display

    return (
        <div>
            <Modal show={true} size="lg">
                <Modal.Header closeButton onHide={() => dispatch(closeViewRates())}>
                    <Modal.Title>
                        View Rates: {indexTitle}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table>
                        <thead>
                            <tr>
                                {bottomTableHeadings.map(heading => {
                                    return <th>{heading}</th>
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {rates.map(rate => {
                                return <tr>
                                    <td>{dollarConverter(rate.rate!, "$", "") }</td>
                                    <td>{rate.unit }</td>
                                    <td>{rate.rateDescription}</td>
                                </tr>
                            })}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => dispatch(closeViewRates())}>Close</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
