import React, { useEffect, useState } from 'react';
import { AddCompany } from './add items/companies/AddCompany';
import Table from 'react-bootstrap/Table';
import { company } from '../../../../types/company';
import { EditCompany } from './editItems/EditCompany';
import { useGetCompaniesQuery, useDeleteCompanyMutation } from '../../../../redux/injectionEndpoints/costTracking/companiesEndpoint';
import { NoCompanies } from '../../../errorComponents/NoCompanies';
import { AddCompaniesSpreadsheet } from './add items/companies/AddCompaniesSpreadsheet';
import { utils, writeFile } from 'xlsx';

const tableHeadings = [
    "Select",
    "Company",
    "Abbreviation",
    "ABN",
    "Business Address",
    "Primary Contact",
    "Primary Contact Number",
]

export const Companies = () => {

    const { data: companies, isError: companiesIsError } = useGetCompaniesQuery();
    const [deleteSelectedCompany] = useDeleteCompanyMutation();

    const [addCompany, setaddCompany] = useState(false); //Add company section open or closed
    const [addCompanyFromSpreadsheet, setaddCompanyFromSpreadsheet] = React.useState<boolean>(false)

    const [checkedCompany, setcheckedCompany] = useState<string>("") //indicate which companies have been selected

    const deleteCompany = (e: React.MouseEvent<HTMLSpanElement>) => {
        e.preventDefault();

        deleteSelectedCompany(checkedCompany)
        setcheckedCompany("")
    }

    const manageChecks = (e: React.ChangeEvent<HTMLInputElement>, company: company) => {
        (document.getElementsByName(`${checkedCompany}`) as NodeListOf<HTMLInputElement>).forEach(element => element.checked = false)
        if (e.currentTarget.checked) {
            setcheckedCompany(e.currentTarget.name);
        }
        else setcheckedCompany("")
    };

    const generateXLSXTemplate = () => {
        const headings = ['company', 'abbreviation', 'abn', 'businessAddress', 'primaryContact', 'primaryContactNumber'];

        const workbook = utils.book_new(); //create a new workbook
        const worksheet = utils.aoa_to_sheet([[...headings]]) //create a new worksheet with our required headings as the first and only row
        utils.book_append_sheet(workbook, worksheet, "companies") //append the sheet to the workbook
        writeFile(workbook, "template.xlsx") //download the file when the user elects to add from spreadsheet
    }

    const closeAddCompany = () => {
        setaddCompany(false)
    }

    return (
        <div id="companies-modal">
            <div className="modal-subTabs">
                {addCompany ? null :
                    <span id="addCompany" onClick={(e: React.MouseEvent<HTMLSpanElement>) => { setaddCompany(true) }} className="modal-subTabs-item">
                        <button className='universal-save-button'>Add Company</button>
                    </span>
                }
                {addCompany ?
                    <span id="modal-subTabs-saveCompany" onClick={(e: React.MouseEvent<HTMLSpanElement>) => setaddCompany(false)} className="modal-subTabs-item">
                        <button className='universal-delete-button'>Hide</button>
                    </span> : null
                }
                {checkedCompany.length == 0 ? null :
                    <span className="modal-subTabs-item" onClick={deleteCompany}>
                        <button className='universal-delete-button'>Delete</button>
                    </span>
                }
                <span className="modal-subTabs-item" onClick={() => {setaddCompanyFromSpreadsheet(prevVal => {return !prevVal}); setaddCompany(false); generateXLSXTemplate()}}><button className='universal-search-button'>Add from Spreadsheet</button></span>
            </div>
            {addCompany && <AddCompany closeAddCompany={closeAddCompany} addCompany={addCompany} />}
            {addCompanyFromSpreadsheet && <AddCompaniesSpreadsheet />}
            <div className="costCodes-modal-pageSplit">
                <Table className="generic-table-class" striped bordered>
                    <thead>
                        <tr>
                            {tableHeadings.map(heading => {
                                return <th>{heading}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {companiesIsError ? <tr><NoCompanies /></tr> :
                            companies?.map(company => {
                                return <>
                                    <tr>
                                        <td><input name={company.id!.toString()} type="checkbox" onChange={(e: React.ChangeEvent<HTMLInputElement>) => manageChecks(e, company)} ></input></td>
                                        <td>{company.company}</td>
                                        <td>{company.abbreviation}</td>
                                        <td>{company.abn}</td>
                                        <td>{company.businessAddress}</td>
                                        <td>{company.primaryContact}</td>
                                        <td>{company.primaryContactNumber}</td>
                                    </tr>
                                    {
                                        checkedCompany == company.id!.toString() &&
                                        <>
                                            <EditCompany {...companies.find(companyToFind => companyToFind.id === company.id)!} />
                                        </>
                                    }
                                </>
                            })
                        }
                    </tbody>
                </Table>
            </div>
        </div >
    )
}
