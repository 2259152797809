import React, { FC, HTMLInputTypeAttribute, useState } from 'react'
import { Modal, Table } from 'react-bootstrap'
import { closeEditUserPage } from '../../redux/reducers/adminReducer'
import { useDispatch } from 'react-redux'
import { user } from '../../types/user'
import { useEditUserMutation } from '../../redux/injectionEndpoints/users/userEndpoints'
import { useGetProjectsQuery } from '../../redux/injectionEndpoints/costTracking/projectsEndpoint'
import { Trash } from 'react-bootstrap-icons'

type props = user

export const EditUser: FC<props> = (user) => {
    const [firstName, setfirstName] = useState<string>("")
    const [lastName, setlastName] = useState<string>("")
    const [company, setcompany] = useState<string>("")
    const [position, setposition] = useState<string>("")
    const [email, setemail] = useState<string>("")
    const [phone, setphone] = useState<string>("")
    const [userPrivilege, setuserPrivilege] = useState<"God" | "Engineer" | "Admin" | "">("")
    const [projects, setprojects] = useState<string[]>(user.projects)
    const [activated, setactivated] = useState<boolean>(user.activated);

    const [projectToAdd, setprojectToAdd] = useState<string>("0")

    const { data: projectsRTK } = useGetProjectsQuery()

    const [editUser] = useEditUserMutation();

    const userUpdate = (field: string) => {
        //when we hit the confirm button, update the correct field
        switch (field) {
            case "firstName":
                editUser({ ...user, firstName });
                setfirstName("")
                break
            case "lastName":
                editUser({ ...user, lastName });
                setlastName("")
                break
            case "company":
                editUser({ ...user, company })
                setcompany("")
                break
            case "position":
                editUser({ ...user, position })
                setposition("")
                break
            case "email":
                editUser({ ...user, email })
                setemail("")
                break
            case "phone":
                editUser({ ...user, phone })
                setphone("")
                break
            case "userPrivilege":
                editUser({ ...user, userPrivilege })
                break
            case "projects":
                if (projectToAdd == "0" || projectToAdd == "") break
                editUser({ ...user, projects: [...user.projects, projectToAdd] })
                break
            case "activated":
                editUser({ ...user, activated })
                break
        }
    }

    const dispatch = useDispatch();

    return (
        <Modal show={true} size='lg'>
            <Modal.Header closeButton onHide={() => dispatch(closeEditUserPage())}>
                <Modal.Title style={{ width: "80%" }} >
                    <p><b>Update User:</b> {user.firstName.concat(" ", user.lastName)}</p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table>
                    <thead>
                        <tr>
                            <th>Field</th>
                            <th>Current Value</th>
                            <th>Updated Value</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>First Name</th>
                            <td>{user.firstName}</td>
                            <td><input value={firstName} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setfirstName(e.currentTarget.value)}></input></td>
                            <td>{firstName !== "" && <button onClick={() => userUpdate("firstName")}>Confirm</button>}</td>
                        </tr>
                        <tr>
                            <th>Last Name</th>
                            <td>{user.lastName}</td>
                            <td><input value={lastName} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setlastName(e.currentTarget.value)}></input></td>
                            <td>{lastName !== "" && <button onClick={() => userUpdate("lastName")}>Confirm</button>}</td>
                        </tr>
                        <tr>
                            <th>Company</th>
                            <td>{user.company}</td>
                            <td><input value={company} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setcompany(e.currentTarget.value)}></input></td>
                            <td>{company !== "" && <button onClick={() => userUpdate("company")}>Confirm</button>}</td>
                        </tr>
                        <tr>
                            <th>Position</th>
                            <td>{user.position}</td>
                            <td><input value={position} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setposition(e.currentTarget.value)}></input></td>
                            <td>{position !== "" && <button onClick={() => userUpdate("position")}>Confirm</button>}</td>
                        </tr>
                        <tr>
                            <th>Email</th>
                            <td>{user.email}</td>
                            <td><input value={email} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setemail(e.currentTarget.value)}></input></td>
                            <td>{email !== "" && <button onClick={() => userUpdate("email")}>Confirm</button>}</td>
                        </tr>
                        <tr>
                            <th>Phone</th>
                            <td>{user.phone}</td>
                            <td><input value={phone} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setphone(e.currentTarget.value)}></input></td>
                            <td>{phone !== "" && <button onClick={() => userUpdate("phone")}>Confirm</button>}</td>
                        </tr>
                        <tr>
                            <th>User Privilege</th>
                            <td>{user.userPrivilege}</td>
                            {user.userPrivilege !== "God" ? <td>
                                <select value={userPrivilege} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
                                    switch (e.target.value) {
                                        case "God": setuserPrivilege("God"); break
                                        case "Engineer": setuserPrivilege("Engineer"); break
                                        case "Admin": setuserPrivilege("Admin"); break
                                    }
                                    
                                }}>
                                    <option value="0">Select</option>
                                    <option value="God">God</option>
                                    <option value="Admin">Admin</option>
                                    <option value="Engineer">Engineer</option>
                                </select>
                            </td> : <td></td>}
                            <td>{(userPrivilege !== "" && userPrivilege !== user.userPrivilege) && <button onClick={() => userUpdate("userPrivilege")}>Confirm</button>}</td>
                        </tr>
                        <tr>
                            <th>Projects</th>
                            <td>{user.projects.map(project => {
                                return <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                    <p>{projectsRTK && projectsRTK?.find(projectRTK => projectRTK._id == project)?.projectCode.concat(" - ", projectsRTK!.find(projectRTK => projectRTK._id == project)!.projectName)}</p>
                                    <Trash onClick={() => editUser({ ...user, projects: (user.projects.filter(projectToRemove => projectToRemove !== project)) })} />
                                </div>
                            })}</td>
                            <td>
                                <select style={{ maxWidth: "200px" }} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setprojectToAdd(e.currentTarget.value)}>
                                    <option value="0">SELECT</option>
                                    {projectsRTK && projectsRTK.map(project => {
                                        return <option value={project._id}>{project.projectCode.concat(" - ", project.projectName)}</option>
                                    })}
                                </select>
                            </td>
                            <td>{(projectToAdd !== "0" && (!user.projects.find(project => project == projectToAdd))) && <button onClick={() => userUpdate("projects")}>Add</button>}</td>
                        </tr>
                        <tr>
                            <th>Activated</th>
                            <td>{user.activated ? "Yes" : "No"}</td>
                            <td>
                                <select value={activated ? "1" : "0"} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => { if (e.currentTarget.value === "1") setactivated(true); else setactivated(false) }}>
                                    <option value={1}>Yes</option>
                                    <option value={0}>No</option>
                                </select>
                            </td>
                            <td>{activated !== user.activated && <button onClick={() => userUpdate("activated")}>Confirm</button>}</td>
                        </tr>
                    </tbody>
                </Table>
            </Modal.Body>
        </Modal>
    )
}
