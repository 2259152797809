import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { resourceCode } from '../../../../../types/resourceCode';
import { editProperty } from '../../../../../types/editPropertyProps';
import { EditProperty } from './EditProperty';
import { Modal, Table } from 'react-bootstrap';
import { dollarConverter } from '../../../../../functions/dollarConverter';
import { useEditCostMutation, useGetCostsQuery } from '../../../../../redux/injectionEndpoints/costTracking/costsEndpoint';
import { useGetCostCodesQuery } from '../../../../../redux/injectionEndpoints/costTracking/costCodeEndpoint';
import { resourceCodesBudgetCalculator } from '../../../../../functions/costTracking/costSummaries';
import { resourceCodeCostSummary } from '../../../../../functions/costTracking/resourceCodeSummaries';
import { useEditResourceCodeMutation } from '../../../../../redux/injectionEndpoints/costTracking/resourceCodeEndpoint';
import { useEditIndexMutation, useGetIndexesQuery } from '../../../../../redux/injectionEndpoints/costTracking/indexEndpoint';

const tableHeadings = [
    "",
    "Resource Code",
    "Name",
    "Project Budget",
    "Cost to Date"
];

export const EditResourceCode = (props: resourceCode) => {
    const { data: costs, isSuccess: costsSuccess } = useGetCostsQuery(); //retrieving all costs
    const { data: indexes } = useGetIndexesQuery(); //retrieving all indexes
    const { data: costCodes, refetch: costCodesRefetch, isLoading: costCodesIsLoading, isError: costCodesIsError, error: costCodesError } = useGetCostCodesQuery(); //for retrieving all resource codes

    const [editResourceCode] = useEditResourceCodeMutation();
    const [editCost] = useEditCostMutation();
    const [editIndex] = useEditIndexMutation();

    const openEditor = useSelector((state: RootState) => state.property.editorOpen); //set to true when editor is open, set to false when editor is closed

    const [code, setcode] = useState<string>(props.code)
    const [name, setname] = useState<string>(props.name);

    const [areYouSure, setareYouSure] = useState<boolean>(false)

    const [propertyProps, setpropertyProps] = useState<editProperty>({ //to be sent to EditProperty FC for updating
        data: props!,
        propertyType: "Resource Code",
        updateField: "",
        arrayIndex: undefined,
        updateSubField: "",
        updateValue: ""
    });

    const updateResourceCode = (e: React.MouseEvent<HTMLButtonElement>) => {
        editResourceCode({ id: props.id!, field: "code", value: code }) //update the code
        costs && costs.filter(cost => cost.resourceCode === props.code) //update each cost that contains that code
            .forEach(cost => editCost({ id: cost.id!, field: "resourceCode", value: code }))

        indexes && indexes.filter(index => index.resourceCode == props.code)
            .forEach(index => editIndex({id: index.id!, field: "resourceCode", value: code}))

        editResourceCode({ id: props.id!, field: "name", value: name }) //update the name

        setareYouSure(false) //close modal
    }

    return (
        <>
            {openEditor ? <EditProperty {...propertyProps} /> : null}
            <tr>
                <td><button onClick={() => setareYouSure(true)} className='universal-small-save-button'>Save</button></td>
                <td><input name="code" value={code} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setcode(e.target.value)}></input></td>
                <td><input name="name" value={name} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setname(e.target.value)}></input></td>
                <td>{costCodes && dollarConverter(resourceCodesBudgetCalculator(costCodes, props.code), "$", "")}</td>
                <td>{costsSuccess && dollarConverter(resourceCodeCostSummary(costs, props.code), "$", "")}</td>
                <td></td>
            </tr>
            {areYouSure &&
                <Modal show={true} centered size='lg'>
                    <Modal.Header>
                        <Modal.Title>Are You Sure...</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Updating Resource Codes at this level will update ALL Indexes and ALL Costs assigned this Resource Code</p>
                        <hr></hr>
                        <button onClick={updateResourceCode} >Yes I am sure</button>
                        <button onClick={() => setareYouSure(false)}>No I am not</button>
                    </Modal.Body>
                </Modal>
            }
        </>
    )
}
