import React from 'react';
import '../../css files/TopHeader.css'
import { SettingsDropdown } from '../dropdowns/SettingsDropdown';
import { SignIn } from '../dropdowns/SignIn';
import { SignOutDropdown } from '../dropdowns/SignOutDropdown';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

export const TopHeader = () => {

    const confirmedAuthenticated = useSelector((state: RootState) => state.user.isAuthenticated)
    const userProfile = useSelector((state: RootState) => state.user.userProfile)

    return (
        <div id="topHeader">
            <h3 style={{ padding: "1% 2%", margin: "0" }}>MDJV Cost Tracking</h3>
            <div id="topHeader-Options">
                {userProfile && confirmedAuthenticated ?
                    <>
                        <p>Hello {userProfile.firstName}</p>
                        <SettingsDropdown />
                        <SignOutDropdown />
                    </>
                    :
                    <SignIn />
                }
            </div>
        </div>
    )
}
