import { editObject } from "../../../types/edit";
import { API } from "../../APIs/moduleAPI";
import { productionTracking } from "../../../types/productionTracking";

export const extendedProductionTrackingAPI = API
    .enhanceEndpoints({ addTagTypes: ['ProductionTracking' as const] })
    .injectEndpoints({
        endpoints: builder => ({
            getProductionTracking: builder.query<productionTracking[], void>({
                query: () => {
                    return {
                        url: 'productionTracking/getAllProductionTracking',
                        method: "GET"
                    }
                },
                providesTags: ["ProductionTracking"]
            }),
            addProductionTracking: builder.mutation<productionTracking, productionTracking>({
                query(cost) {
                    return {
                        url: 'productionTracking/addProductionTracking',
                        method: "POST",
                        body: cost
                    }
                },
                invalidatesTags: ['ProductionTracking']
            }),
            editProductionTracking: builder.mutation<productionTracking, editObject>({
                query(editObject) {
                    return {
                        url: `productionTracking/editProductionTracking/${editObject.id}`,
                        method: "POST",
                        body: editObject
                    }
                },
                invalidatesTags: ["ProductionTracking"]
            }),
            deleteProductionTracking: builder.mutation<productionTracking, string>({
                query(id) {
                    return {
                        url: `productionTracking/deleteProductionTracking/${id}`,
                        method: "DELETE",
                    }
                },
                invalidatesTags: ["ProductionTracking"]
            })
        })
    })

    export const {
        useGetProductionTrackingQuery,
        useAddProductionTrackingMutation,
        useEditProductionTrackingMutation,
        useDeleteProductionTrackingMutation
    } = extendedProductionTrackingAPI