import React, { useState } from 'react'
import { costCode, resourceCode } from '../../../../../types/costCode';
import Table from 'react-bootstrap/Table';
import { dollarConverter } from '../../../../../functions/dollarConverter';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { useDeleteCostCodeMutation } from '../../../../../redux/injectionEndpoints/costTracking/costCodeEndpoint';
import { editProperty } from '../../../../../types/editPropertyProps';
import { Trash } from 'react-bootstrap-icons';
import { useGetResourceCodesQuery } from '../../../../../redux/injectionEndpoints/costTracking/resourceCodeEndpoint';
import { useEditCostCodeMutation } from '../../../../../redux/injectionEndpoints/costTracking/costCodeEndpoint';
import { useGetCostCodeQuery } from '../../../../../redux/injectionEndpoints/costTracking/costCodeEndpoint';
import { Modal } from 'react-bootstrap';

const tableHeadings = [
    "Code",
    "Code Name",
    "Sub-Code",
    "Sub-Code Name",
    "Budget",
    "Status"
];

const resourceCodeHeadings = [
    "Code",
    "Rate",
    "Unit",
    "Quantity",
    "Total",
    ""
];

type props = {
    costCode: costCode,
    openEditCostCode: boolean,
    closeEditCostCode: () => void
}

export const EditCostCode = (props: props) => {

    const { data: costCodeData, isSuccess: costCodeSuccess } = useGetCostCodeQuery(props.costCode.id!.toString())
    const { data: resourceCodesData, isSuccess: resourceCodesDataSuccess } = useGetResourceCodesQuery();
    const [editCostCodeProperty] = useEditCostCodeMutation();

    const [deleteCostCode] = useDeleteCostCodeMutation(); //for deleting codes from the server

    const deleteCostCodeFromServer = (e: React.MouseEvent<HTMLSpanElement>) => {
        deleteCostCode(props.costCode.id!.toString())
    };

    //FOR WHEN EDITING FIELDS
    const [code, setcode] = useState<string>(props.costCode.code)
    const [codeName, setcodeName] = useState<string>(props.costCode.codeName)
    const [subCode, setsubCode] = useState<string>(props.costCode.subCode)
    const [subCodeName, setsubCodeName] = useState<string>(props.costCode.subCodeName)
    const [status, setstatus] = useState<string>(props.costCode.status ? "1" : "0")

    const [resourceCodes, setresourceCodes] = useState<resourceCode[]>([...props.costCode.resourceCodes])

    ///NEW RESOURCE CODE IF EDITING
    const [resource, setresource] = useState<string>("") //new resource code to save
    const [rate, setrate] = useState<number>(0) //new rate to save
    const [unit, setunit] = useState<string>("") //new rate to save
    const [quantity, setquantity] = useState<number>(0) //new rate to save 

    const saveCode = (e: React.MouseEvent<HTMLButtonElement>) => { //save new resource code from add cost code option
        e.preventDefault();
        var newResourceCode: resourceCode = {
            resource,
            quantity,
            unit,
            rate,
            total: rate * quantity
        }
        setresourceCodes([...resourceCodes!, newResourceCode])
    }

    const deleteCode = (e: React.MouseEvent<SVGElement>) => { //delete resource code from the add cost code option
        e.preventDefault();
        const newCodes = resourceCodes!.filter(code => code.resource != e.currentTarget.id)
        setresourceCodes(newCodes)
    };

    const updateCostCode = (e: React.MouseEvent<HTMLButtonElement>) => {
        editCostCodeProperty({ id: props.costCode.id!, field: "resourceCodes", value: resourceCodes })
        editCostCodeProperty({ id: props.costCode.id!, field: "budget", value: resourceCodes.map(resourceCode => resourceCode.total).reduce((prev, curr) => prev + curr, 0) })
    }

    //for updating resource codes rates array
    const updateResourceCodesState = (e: React.ChangeEvent<HTMLInputElement>, field: string, index: number) => {
        setresourceCodes(prevRates => {
            let newRates = [...prevRates];
            let newRate = { ...newRates[index] }
            switch (field) {
                case "resource":
                    newRate.resource = e.target.value;
                    break
                case "rate":
                    newRate.rate = Number(e.target.value);
                    newRate.total = Number(e.target.value) * newRate.quantity;
                    break
                case "unit":
                    newRate.unit = e.target.value;
                    break
                case "quantity":
                    newRate.quantity = Number(e.target.value);
                    newRate.total = Number(e.target.value) * newRate.rate;
                    break
            }
            newRates[index] = newRate;
            return newRates;
        })
    }

    const saveCostCode = (e: React.MouseEvent<HTMLButtonElement>) => {
        editCostCodeProperty({ id: props.costCode.id!, field: "code", value: code })
        editCostCodeProperty({ id: props.costCode.id!, field: "codeName", value: codeName })
        editCostCodeProperty({ id: props.costCode.id!, field: "subCode", value: subCode })
        editCostCodeProperty({ id: props.costCode.id!, field: "subCodeName", value: subCodeName })
        editCostCodeProperty({ id: props.costCode.id!, field: "status", value: status === "1" ? true : false })
    }

    return (
        <Modal show={props.openEditCostCode} dialogClassName="modal-90w" >
            <Modal.Header closeButton onHide={() => props.closeEditCostCode()}>
                <Modal.Title>Edit Cost Code: {props.costCode.codeName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table style={{ width: "100%" }} bordered>
                    <thead>
                        <tr>
                            {tableHeadings.map(heading => {
                                return <th>{heading}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {costCodeData && <tr>
                            <td><input name="code" style={{maxWidth: "110px"}} value={code} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setcode(e.currentTarget.value)} ></input></td>
                            <td><input name="codeName"  value={codeName} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setcodeName(e.currentTarget.value)}></input></td>
                            <td><input name="subCode" style={{maxWidth: "80px"}} value={subCode} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setsubCode(e.currentTarget.value)} ></input></td>
                            <td><input name="subCodeName"  value={subCodeName} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setsubCodeName(e.currentTarget.value)} ></input></td>
                            <td>
                                <Table>
                                    <thead>
                                        <tr>
                                            {resourceCodeHeadings.map(heading => {
                                                return <th>{heading}</th>
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td id="editCodeCodeRate-resourceCode"><select onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setresource(e.target.value)}>
                                                <option>SELECT</option>
                                                {resourceCodesData ? resourceCodesData.map(resourceCode => {
                                                    return <option id={resourceCode.id?.toString()} value={resourceCode.code} >{resourceCode.code}</option>
                                                }) : null}
                                            </select></td>
                                            <td id="editCodeCodeRate-rate" ><input type="number" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setrate(Number(e.target.value))}></input> </td>
                                            <td id="editCodeCodeRate-unit"><input onChange={(e: React.ChangeEvent<HTMLInputElement>) => setunit(e.target.value)}></input> </td>
                                            <td id="editCodeCodeRate-quantity"><input type="number" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setquantity(Number(e.target.value))}></input> </td>
                                            <td>{dollarConverter(rate * quantity, "$", "")}</td>
                                            <td><button className="universal-small-save-button" onClick={saveCode}>Save</button></td>
                                        </tr>
                                        {
                                            resourceCodes.map((resourceCode, index) => {
                                                return <tr>
                                                    <td><input name="resource"  value={resourceCodes[index].resource} onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateResourceCodesState(e, "resource", index)}></input></td>
                                                    <td><input name="rate" type="number"  value={resourceCodes[index].rate} onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateResourceCodesState(e, "rate", index)}></input></td>
                                                    <td><input name="unit"  value={resourceCodes[index].unit} onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateResourceCodesState(e, "unit", index)}></input></td>
                                                    <td><input name="quantity" type="number"  value={resourceCodes[index].quantity.toString()} onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateResourceCodesState(e, "quantity", index)}></input></td>
                                                    <td><input disabled name="total"  value={dollarConverter(resourceCodes[index].total, "$", "")} ></input></td>
                                                    <td><Trash  id={resourceCodes[index].resource} onClick={deleteCode} /></td>
                                                </tr>
                                            })
                                        }
                                        <tr>
                                            <th colSpan={resourceCodeHeadings.length - 2}>Total</th>
                                            <th>{dollarConverter(resourceCodes.map(resourceCode => resourceCode.total).reduce((prev, curr) => prev + curr, 0), "$", "")}</th>
                                            <td></td>
                                        </tr>
                                        <tr>
                                            <td colSpan={resourceCodeHeadings.length} style={{textAlign:"right"}}>
                                                <button className="universal-save-button"  onClick={updateCostCode}>Update Resource Codes</button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </td>
                            <td>
                                <select name="status"  value={status} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setstatus(e.target.value)}>
                                    <option value={"1"}>Open</option>
                                    <option value={"0"}>Closed</option>
                                </select>
                            </td>
                        </tr>}
                    </tbody>
                </Table>
                <div className="costCodes-save-bar">
                    <span onClick={saveCostCode}>
                        <button className="universal-save-button">Save</button>
                    </span>
                    <span onClick={deleteCostCodeFromServer} >
                        <button className="universal-delete-button">Delete</button>
                    </span>
                </div>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal>
    )
}
