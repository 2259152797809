import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import '../../css files/Dropdowns.css';
import { RootState } from '../../redux/store';
import { useSelector, useDispatch } from 'react-redux';

export const SettingsDropdown = () => {

    const confirmedAuthenticated = useSelector((state: RootState) => state.user.isAuthenticated)
    const userProfile = useSelector((state: RootState) => state.user.userProfile)

    return (
        <div id="settings-heading-dropdown">
            <Dropdown >
                <Dropdown.Toggle id="settings-heading-dropdown-button">
                    Settings
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-style-1">
                    {userProfile && confirmedAuthenticated && (userProfile.userPrivilege === "God" || "Admin") &&
                        <>
                            <Dropdown.Item as={Link} to="/Users" >Users</Dropdown.Item>
                        </>
                    }
                    {userProfile && confirmedAuthenticated && (userProfile.userPrivilege === "God" || "Admin") &&
                        <>
                            <Dropdown.Divider />
                            <Dropdown.Item as={Link} to="/Projects" >Projects</Dropdown.Item>
                        </>
                    }
                    <Dropdown.Divider />
                    <Dropdown.Item as={Link} to="/ChangePassword">Change Password</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}
