import React, { useEffect, useState } from 'react';
import { Pencil, Trash3 } from 'react-bootstrap-icons';
import Table from 'react-bootstrap/Table';
import { resourceCode } from '../../../../types/resourceCode'
import { dollarConverter } from '../../../../functions/dollarConverter';
import { AddResourceCode } from './add items/AddResourceCode';
import { EditResourceCode } from './editItems/EditResourceCode';
import { useGetResourceCodesQuery, useDeleteResourceCodeMutation } from '../../../../redux/injectionEndpoints/costTracking/resourceCodeEndpoint';
import { useGetCostCodesQuery } from '../../../../redux/injectionEndpoints/costTracking/costCodeEndpoint';
import { useGetCostsQuery } from '../../../../redux/injectionEndpoints/costTracking/costsEndpoint';
import { resourceCodesBudgetCalculator } from '../../../../functions/costTracking/costSummaries';
import { monthlyResourceCodeCostSummary, resourceCodeCostSummary } from '../../../../functions/costTracking/resourceCodeSummaries';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';

const tableHeadings = [
    "Select",
    "Resource Code",
    "Name",
    "Project Budget",
    "Cost to Date",
    "Monthly Spend"
]

export const ResourceCodes = () => {
    const { data: resourceCodes, refetch: resourceCodesRefecth, isLoading: resourceCodesIsLoading, isError: resourceCodesIsError, error: resourceCodesError } = useGetResourceCodesQuery(); //for retrieving all resource codes
    const { data: costCodes, refetch: costCodesRefetch, isLoading: costCodesIsLoading, isError: costCodesIsError, error: costCodesError } = useGetCostCodesQuery(); //for retrieving all resource codes
    const { data: costs, isSuccess: costsSuccess } = useGetCostsQuery(); //retrieving all costs

    const selectedProject = useSelector((state: RootState) => state.tabs.projectTab)

    const [deleteSelectedResourceCode] = useDeleteResourceCodeMutation(); // deleting resource codes

    const [addResourceCode, setaddResourceCode] = useState(false); //true if adding new resource code
    const [editResourceCode, seteditResourceCode] = useState(false); //true if editing an existing resource code

    const [checkedResourceCode, setcheckedResourceCode] = useState<string>("") //indicate which cost codes have been selected
    const [resourceCodeToEdit, setresourceCodeToEdit] = useState<resourceCode>()

    const manageChecks = (e: React.ChangeEvent<HTMLInputElement>, resourceCode: resourceCode) => {
        (document.getElementsByName(`${checkedResourceCode}`) as NodeListOf<HTMLInputElement>).forEach(element => element.checked = false)

        if (e.currentTarget.checked) {
            setcheckedResourceCode(e.target.name);
        }
        else setcheckedResourceCode("")
    };

    useEffect(() => {
        if (checkedResourceCode.length > 1) seteditResourceCode(false) //if a user selects more than one, take away the option to edit as we don't edit two pieces of data at once.
        else if (checkedResourceCode.length === 0) seteditResourceCode(false) //if a user deselects the data to edit while editing, close the edit
    }, [manageChecks])

    const closeAddResourceCode = () => {
        setaddResourceCode(false)
    }

    return (
        <div>
            <div className="modal-subTabs">
                <span id="addIndex" onClick={() => { setaddResourceCode(true); seteditResourceCode(false) }} className="modal-subTabs-item">
                    <button className='universal-save-button'>Add Resource Code</button>
                </span>
            </div>
            {addResourceCode ? <div style={{ width: "50%" }}><AddResourceCode closeAddResourceCode={closeAddResourceCode} addResourceCode={addResourceCode} /><hr></hr></div> : null}
            {editResourceCode ? <EditResourceCode {...resourceCodeToEdit!} /> : null}
            {editResourceCode ? <span onClick={(e: React.MouseEvent<HTMLSpanElement>) => seteditResourceCode(false)}>
                <button>Close</button>
            </span>
                : null
            }
            <div className="costCodes-modal-pageSplit">
                <Table className="generic-table-class" bordered hover>
                    <thead>
                        <tr>
                            {tableHeadings.map(heading => {
                                return <th>{heading}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {resourceCodes?.map(resourceCode => {
                            if (resourceCode.project == selectedProject) {
                                return <>
                                    <tr>
                                        <td><input name={resourceCode.id!.toString()} type="checkbox" onChange={(e: React.ChangeEvent<HTMLInputElement>) => { manageChecks(e, resourceCode) }} ></input></td>
                                        <td>{resourceCode.code}</td>
                                        <td>{resourceCode.name}</td>
                                        <td>{costCodes && dollarConverter(resourceCodesBudgetCalculator(costCodes, resourceCode.code), "$", "")}</td>
                                        <td>{costsSuccess && dollarConverter(resourceCodeCostSummary(costs, resourceCode.code), "$", "")}</td>
                                        <td>{costsSuccess && dollarConverter(monthlyResourceCodeCostSummary(costs, resourceCode.code), "$", "")}</td>
                                    </tr>
                                    {checkedResourceCode == resourceCode.id!.toString() ? <>
                                        <>
                                            <EditResourceCode {...resourceCodes.find(codeToEdit => codeToEdit.id === resourceCode.id)!} />
                                        </>
                                    </> : null}
                                </>
                            }
                        })}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}
