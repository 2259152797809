import { API } from "../../APIs/moduleAPI";
import { user } from "../../../types/user";
import { authLoginReq, authRes, resetPasswordRes } from "../../../types/auth";
import { authError, loginSuccess, logout, userLoading } from "../../reducers/authReducer";

export const extendedAuthAPI = API
    .enhanceEndpoints({ addTagTypes: ['Auth' as const] })
    .injectEndpoints({
        endpoints: builder => ({
            authenticateUser: builder.mutation<authRes, authLoginReq>({
                query(authRequest) { //request to the server with login credentials
                    return {
                        url: 'auth/authUser',
                        method: "POST",
                        body: authRequest
                    }
                },
                invalidatesTags: ['Auth'], //invalidates our auth
            }),
            logout: builder.mutation<authRes, string>({
                query(email) {
                    return {
                        url: `auth/logout/${email}`,
                        method: 'GET'
                    }
                },
                invalidatesTags: ['Auth'], //invalidates our auth
            }),
            checkAuthentication: builder.query<authRes, void>({
                query() {
                    return {
                        url: "auth/checkIsAuthorised",
                        method: "GET",
                    }
                },
                providesTags: ['Auth'],
            }),
            forgotPassword: builder.mutation<resetPasswordRes, string>({
                query(email) {
                    return {
                        url: `auth/forgot-password/${email}`,
                        method: 'POST',
                    }
                },
                invalidatesTags: ["Auth"]
            }),
            resetPassword: builder.mutation<resetPasswordRes, {token:string, newPassword: string}>({
                query(body) {
                    return {
                        url: 'auth/reset-password',
                        method: 'POST',
                        body
                    }
                },
                invalidatesTags: ["Auth"]
            })
        })
    })

export const { useAuthenticateUserMutation, useLogoutMutation, useCheckAuthenticationQuery, useForgotPasswordMutation, useResetPasswordMutation } = extendedAuthAPI