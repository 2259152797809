import React, { FC } from 'react';
import { production } from '../../../../../types/productionTracking';
import { useGetCostsQuery } from '../../../../../redux/injectionEndpoints/costTracking/costsEndpoint';
import { useGetCostCodesQuery } from '../../../../../redux/injectionEndpoints/costTracking/costCodeEndpoint';
import { returnDataWithBudget } from '../../../../../functions/costTracking/productionTracking/generateProductionTrackingData';
import { generateProductionTrackingData } from '../../../../../functions/costTracking/productionTracking/generateProductionTrackingData';
import { XYPlot, XAxis, YAxis, ChartLabel, HorizontalGridLines, VerticalGridLines, LineMarkSeries, DiscreteColorLegend } from 'react-vis';


type props = {
    project: string,
    dollarTrackingCode: string,
    budgetUnitRate: number,
    budgetDailyRate: number,
    productionTracked: production[]
}

export const DailyGraph: FC<props> = (props) => {
    const { filteredCosts } = useGetCostsQuery(undefined, {
        selectFromResult: ({ data }) => ({
            filteredCosts: data?.filter((cost) => cost.project === props.project)
        })
    });

    const { dollarTrackedCodeName } = useGetCostCodesQuery(undefined, {
        selectFromResult: ({ data }) => ({
            dollarTrackedCodeName: data?.find(costCode => costCode.code.concat(".", costCode.subCode) === props.dollarTrackingCode)
        })
    })

    const items = [
        {
            title: dollarTrackedCodeName ? props.dollarTrackingCode.concat(" - ", dollarTrackedCodeName.subCodeName, " - ", "Daily Cost Tracking") : props.dollarTrackingCode,
            disabled: false,
            color: "blue"
        },
        {
            title: "Budget Daily Rate",
            disabled: false,
            color: "green"
        }
    ];

    const returnData = (): returnDataWithBudget => {
        if (filteredCosts) {
            const dollarTrackedArray = filteredCosts.filter(cost => cost.costCode === props.dollarTrackingCode);

            const undefinedArray = {
                productionQuantities: [{ x: "0", y: 0 }],
                dailySpend: [{ x: "0", y: 0 }],
                budgetUnitRates: [{ x: "0", y: 0 }],
                budgetDailyRates: [{ x: "0", y: 0 }],
            }

            if (!Array.isArray(dollarTrackedArray) || !dollarTrackedArray.length) return undefinedArray
            else if (filteredCosts) return generateProductionTrackingData(props.dollarTrackingCode, filteredCosts, props.budgetUnitRate, props.budgetDailyRate, props.productionTracked)
            else return undefinedArray
        } else return {
            productionQuantities: [{ x: "0", y: 0 }],
            dailySpend: [{ x: "0", y: 0 }],
            budgetUnitRates: [{ x: "0", y: 0 }],
            budgetDailyRates: [{ x: "0", y: 0 }],
        }
    }

    return (
        <>
            <XYPlot xType="time" width={1100} height={320} dontCheckIfEmpty >
                <HorizontalGridLines />
                <VerticalGridLines />
                <XAxis />
                <YAxis />
                <ChartLabel
                    text="Date"
                    className="alt-x-label"
                    includeMargin={false}
                    xPercent={0.025}
                    yPercent={1.01}
                />
                <ChartLabel
                    text="$"
                    className="alt-y-label"
                    includeMargin={false}
                    xPercent={0.06}
                    yPercent={0.1}
                    style={{
                        textAnchor: 'end'
                    }}
                />
                {filteredCosts &&
                    <LineMarkSeries
                        className="first-series"
                        style={{ strokeLinejoin: "round" }}
                        lineStyle={{ stroke: "blue" }}
                        markStyle={{ stroke: "red" }}
                        data={returnData()!.dailySpend.map(dataPoint => {
                            return {
                                x: new Date(dataPoint.x).getTime(),
                                y: dataPoint.y
                            }
                        })}
                    />
                }
                {filteredCosts &&
                    <LineMarkSeries
                        className="budget-series"
                        style={{ strokeLinejoin: "round" }}
                        lineStyle={{ stroke: "green" }}
                        markStyle={{ stroke: "brown" }}
                        data={returnData()!.budgetDailyRates.map(dataPoint => {
                            return {
                                x: new Date(dataPoint.x).getTime(),
                                y: dataPoint.y
                            }
                        })}
                    />
                }
            </XYPlot>
            <DiscreteColorLegend items={items} />
        </>
    )
}
