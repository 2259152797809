import { cost } from "../../types/cost";

export const refineSearch = (searchedCosts: cost[] | [], searchedFields: string[], searchedValues: Array<string[]>): cost[] => {
    let refinedSearchedCosts: cost[] = searchedCosts; // initiate our return array

    if (!searchedFields.length || !searchedValues.length) return searchedCosts //return the original search if no further search fields

    else if (!searchedCosts.length) return []; //if for some reason we get an empty initial search. this shouldn't happen, but you never know

    else if (searchedFields.length == searchedValues.length) { //ensure fields and values array are same length
        let newFilterIteration
        for (var i = 0; i < searchedFields.length; i++) {
            switch (searchedFields[i]) {
                case 'index':
                case 'costCode':
                case 'resourceCode':
                case 'company':
                case 'status':
                    newFilterIteration = refinedSearchedCosts.filter(cost => Object.entries(cost) //start filtering by each search field
                        .find(keyValuePair => keyValuePair[0] == searchedFields[i] && searchedValues[i].find(value => value == keyValuePair[1]))) //return a cost under two conditions: 1. when the key of the cost matches the search field, 2. when the value corresponding to that key is included in the searched values array
                    refinedSearchedCosts = newFilterIteration; //update the refined searches array based on the last iteration, to be passed in to the next iteration
                    break
                case 'description':
                case 'docketNumber':
                case 'purchaseOrder':
                case 'invoiceNumber':
                    newFilterIteration = refinedSearchedCosts.filter(cost => Object.entries(cost)
                        .find(keyValuePair => keyValuePair[0] == searchedFields[i] && (keyValuePair[1] as string).toLowerCase().includes(searchedValues[i][0].toLowerCase())))// same as above but slight different as for this case the searhed value is a single string in an array. so we use the includes() method to find the string that was searched, as used toLowerCase() on both the cost value and the search so it is not a case sensitive search
                    refinedSearchedCosts = newFilterIteration; //update the refined searches array based on the last iteration, to be passed in to the next iteration
                    break
                case 'date':
                    const startDate = searchedValues[i][0] ? new Date(searchedValues[i][0]) : null
                    const endDate = searchedValues[i][1] ? new Date(searchedValues[i][1]) : null

                    if (searchedValues[i].length === 1) { //this is the case where we are doing a single date search
                        if (startDate !== null) {
                            newFilterIteration = refinedSearchedCosts.filter(cost => cost.date === searchedValues[i][0])
                        } else {
                            newFilterIteration = refinedSearchedCosts
                        }
                        refinedSearchedCosts = newFilterIteration
                    } else { // this is the case where we are doing a date range search
                        if (startDate !== null && endDate !== null) {
                            newFilterIteration = refinedSearchedCosts.filter(cost => new Date(cost.date) >= startDate && new Date(cost.date) <= endDate)
                        } else if (startDate !== null) {
                            newFilterIteration = refinedSearchedCosts.filter(cost => new Date(cost.date) >= startDate)
                        } else if (endDate !== null) {
                            newFilterIteration = refinedSearchedCosts.filter(cost => new Date(cost.date) <= endDate)
                        } else {
                            newFilterIteration = refinedSearchedCosts
                        }
                        refinedSearchedCosts = newFilterIteration
                    }
                    break
            }
        }
    }
    return refinedSearchedCosts
}