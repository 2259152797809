import React, { useState } from 'react';
import { CreateProject } from './CreateProject';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../redux/store';
import { Table } from 'react-bootstrap';
import { useGetProjectsQuery } from '../../redux/injectionEndpoints/costTracking/projectsEndpoint';
import { dollarConverter } from '../../functions/dollarConverter';
import { openAddProjectPage, openEditProjectPage } from '../../redux/reducers/adminReducer';
import { EditProject } from './EditProject';
import { useGetCostsQuery } from '../../redux/injectionEndpoints/costTracking/costsEndpoint';

const headings = [
    "Select",
    "Project",
    "Project Name",
    "Project Sub-Code",
    "Project Number",
    "CTD",
    "Project Status"
]

export const ViewProjects = () => {

    const dispatch = useDispatch()

    const { data: projects } = useGetProjectsQuery();
    const { data: costs } = useGetCostsQuery();

    const addProject = useSelector((state: RootState) => state.admin.addProjectPageOpen)
    const editProject = useSelector((state: RootState) => state.admin.editProjectPageOpen)
    const [checkedProject, setcheckedProject] = useState<string>("");

    const manageChecks = (e: React.ChangeEvent<HTMLInputElement>) => {
        (document.getElementsByName(`${checkedProject}`) as NodeListOf<HTMLInputElement>).forEach(element => element.checked = false)
        if (e.currentTarget.checked) { //if box is checked
            setcheckedProject(e.currentTarget.name); //add new user to checked user
        }
        else setcheckedProject("")
    };

    const calculateProjectCost = (projectID: string) => {
        let totalCost = 0
        if (costs) {
            costs.forEach(cost => {
                if (cost.project === projectID) totalCost += cost.totalCost
            })
        }
        else return 0

        return totalCost
    }

    return (
        <>
            <div className="modal-subTabs">
                <span onClick={() => { dispatch(openAddProjectPage()) }} className="modal-subTabs-item">
                    <p>Add Project</p>
                </span>
                {checkedProject &&
                    <span className="modal-subTabs-item" onClick={() => dispatch(openEditProjectPage())}>
                        <p>Edit</p>
                    </span>
                }
            </div>
            {addProject && <CreateProject />}
            {editProject && <EditProject {...projects?.find(project => project._id === checkedProject)!} />}
            <div className="costCodes-modal-pageSplit-column">
                <Table>
                    <thead>
                        <tr>
                            {headings.map(heading => {
                                return <th>{heading}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {projects &&
                            projects.map(project => {
                                return <tr>
                                    <td><input name={project._id} type="checkbox" className='tableCheckbox' onChange={(e: React.ChangeEvent<HTMLInputElement>) => manageChecks(e)}></input></td>
                                    <td>{project.projectCode}</td>
                                    <td>{project.projectName}</td>
                                    <td>{project.projectSubCode}</td>
                                    <td>{project.projectNumber}</td>
                                    <td>{dollarConverter(calculateProjectCost(project._id!), "$", "")}</td>
                                    <td>{project.status ? "Open" : "Closed"}</td>
                                </tr>
                            })
                        }
                    </tbody>
                </Table>
            </div>
        </>
    )
}
