import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useAddProjectMutation } from '../../redux/injectionEndpoints/costTracking/projectsEndpoint';
import { closeAddProjectPage } from '../../redux/reducers/adminReducer';

export const CreateProject = () => {

    const dispatch = useDispatch();

    const [addProject] = useAddProjectMutation();

    const [projectName, setprojectName] = useState<string>("");
    const [projectCode, setprojectCode] = useState<string>("");
    const [projectSubCode, setprojectSubCode] = useState<string>("");
    const [projectNumber, setprojectNumber] = useState<string>("");

    const submitProject = (e:React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        addProject({
            projectName,
            projectCode,
            projectSubCode,
            projectNumber,
            status: true
        })

        dispatch(closeAddProjectPage())
    }

    return (
        <Modal show={true} centered size='lg'>
            <Modal.Header closeButton onHide={() => dispatch(closeAddProjectPage())}>
                <Modal.Title >
                    Add New Project
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className='please-register-container'>
                    <p>Project Name</p>
                    <input required onChange={(e:React.ChangeEvent<HTMLInputElement>) => setprojectName(e.target.value)}></input>
                    <p>Project Code</p>
                    <input required onChange={(e:React.ChangeEvent<HTMLInputElement>) => setprojectCode(e.target.value)}></input>
                    <p>Project Sub-Code (optional)</p>
                    <input onChange={(e:React.ChangeEvent<HTMLInputElement>) => setprojectSubCode(e.target.value)}></input>
                    <p>Project Number (optional)</p>
                    <input onChange={(e:React.ChangeEvent<HTMLInputElement>) => setprojectNumber(e.target.value)}></input>
                    <button onClick={(e:React.MouseEvent<HTMLButtonElement>) => submitProject(e)}>Create New Project</button>
                </form>
            </Modal.Body>
        </Modal>
    )
}
