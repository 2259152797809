import React from 'react';
import { company } from '../../../../../../types/company'
import { read, utils } from 'xlsx';
import { Modal, Table } from 'react-bootstrap';
import { useAddCompanyMutation, useGetCompaniesQuery } from '../../../../../../redux/injectionEndpoints/costTracking/companiesEndpoint';

const tableHeadings = [
	"Company",
	"Abbreviation",
	"ABN",
	"Business Address",
	"Primary Contact",
	"Primary Contact Number",
]

export const AddCompaniesSpreadsheet = () => {

	const [checkSubmissionActive, setcheckSubmissionActive] = React.useState<boolean>(false)
	const [uploadedData, setuploadedData] = React.useState<company[]>([]);

	const [displayErrorMessage, setdisplayErrorMessage] = React.useState<boolean>(false)

	const { data: companies, isSuccess } = useGetCompaniesQuery();
	const [ addCompany ] = useAddCompanyMutation();

	const fileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {

		const file = e.target.files![0];
		const bufferFile = await file.arrayBuffer();
		/* data is an ArrayBuffer */
		const workbook = read(bufferFile);
		const data = utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]])
		const spreadsheetHeader = utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], {header: 1, defval: ""})[0] as string[]
		const expectedHeader = ['company', 'abbreviation', 'abn', 'businessAddress', 'primaryContact', 'primaryContactNumber']
		
		if (spreadsheetHeader.toString() !== expectedHeader.toString()) {
			setdisplayErrorMessage(true)
			window.setTimeout(() => setdisplayErrorMessage(false), 5000)
		} else {
			setuploadedData(data as company[])
			setdisplayErrorMessage(false)
		}
	}

	const submitCompanies = () => {
		if (isSuccess) { //only if we have company data
			uploadedData.forEach(uploadedCompany => {
				if (companies!.find(company => company.company === uploadedCompany.company)) {
					return 
				} else if (companies!.find(company => company.abbreviation === uploadedCompany.abbreviation)) {
					return
				} else {
					addCompany({...uploadedCompany})
					setcheckSubmissionActive(false)
				}
			})
		}
	}

	const returnErrorMessage = (company: company) => {
		let errorMsg: string = ""

		if (isSuccess) { //only if we have company data
			if (companies!.find(dbCompany => dbCompany.company === company.company)) {
				errorMsg = "A company with this name already exists and won't be added"
			} else if (companies!.find(dbCompany => dbCompany.abbreviation === company.abbreviation)) {
				errorMsg = "A company with this abbreviation already exists and won't be added"
			} else errorMsg = ""
		}

		return errorMsg
	}

	return (
		<div>
			<p>Please fill in the spreadsheet with your companies under the headings provided. Do not alter these headings. Once you have completed filling in the sheet, upload it below.</p>
			<input type="file" onChange={fileUpload}></input>
			{Array.isArray(uploadedData) && uploadedData.length && <button onClick={() => setcheckSubmissionActive(true)}>Confirm Upload</button>}
			<br></br>
			<br></br>
			{displayErrorMessage && <p>Oops, your headings are incorrect. Please resubmit with the correct template</p>}
			<Modal show={checkSubmissionActive} size="xl" centered>
				<Modal.Header closeButton onHide={() => setcheckSubmissionActive(false)}>
					<Modal.Title>Review and Confirm Your Submission</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Table style={{ width: "100%" }} bordered hover>
						<thead>
							<tr>
								{tableHeadings.map(heading => {
									return <th>{heading}</th>
								})}
								<th>Message</th>
							</tr>
						</thead>
						<tbody>
							{Array.isArray(uploadedData) && uploadedData.length && uploadedData.map(company => {
								return <tr>
									<td>{company.company}</td>
									<td>{company.abbreviation}</td>
									<td>{company.abn}</td>
									<td>{company.businessAddress}</td>
									<td>{company.primaryContact}</td>
									<td>{company.primaryContactNumber}</td>
									<td>{returnErrorMessage(company)}</td>
								</tr>
							})}
						</tbody>
					</Table>
				</Modal.Body>
				<Modal.Footer><button onClick={() => submitCompanies()}>Confirm Submission</button></Modal.Footer>
			</Modal>
		</div>
	)
}
