import { costCode, resourceCode } from "../../../types/costCode";
import { editObject } from "../../../types/edit";
import { API } from "../../APIs/moduleAPI";

export const extendedCostCodesAPI = API
    .enhanceEndpoints({ addTagTypes: ['CostCode' as const] })
    .injectEndpoints({
        endpoints: builder => ({
            getCostCodes: builder.query<costCode[], void>({
                query: () => {
                    return {
                        url: "costTracking/getAllCostCodes",
                        method: "GET"
                    }
                },
                providesTags: ["CostCode"]
            }),
            getCostCode: builder.query<costCode[], string>({
                query(id) {
                    return {
                        url: `costTracking/getCostCode/${id}`,
                        method: 'GET'
                    }
                },
                providesTags: ["CostCode"]
            }),
            getCostCodeName: builder.query<{codeName: string}, string>({
                query(code) {
                    return {
                        url: `costTracking/getCodeName/${code}`,
                        method: 'GET'
                    }
                }
            }),
            addCostCode: builder.mutation<costCode, costCode>({
                query(costCode) {
                    return {
                        url: 'costTracking/createCostCode',
                        method: 'POST',
                        body: costCode
                    }
                },
                invalidatesTags: ["CostCode"]
            }),
            deleteCostCode: builder.mutation<costCode, string>({
                query(id) {
                    return {
                        url: `costTracking/deleteCostCode/${id}`,
                        method: "DELETE",
                    }
                },
                invalidatesTags: ["CostCode"]
            }),
            editCostCode: builder.mutation<costCode, editObject>({
                query(editObject) {
                    return {
                        url: `costTracking/editCostCode/${editObject.id}`,
                        method: "POST",
                        body: editObject
                    }
                },
                invalidatesTags: ["CostCode"]
            }),
        })
    })

export const {
    useGetCostCodesQuery,
    useGetCostCodeQuery,
    useLazyGetCostCodeNameQuery,
    useAddCostCodeMutation,
    useDeleteCostCodeMutation,
    useEditCostCodeMutation
} = extendedCostCodesAPI