import React, { useState } from 'react'
import { company } from '../../../../../types/company';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { editProperty } from '../../../../../types/editPropertyProps';
import { EditProperty } from './EditProperty';
import { Modal, Table } from 'react-bootstrap';
import { openPropertyEditor } from '../../../../../redux/reducers/editPropertyReducer';
import { useEditCompanyMutation } from '../../../../../redux/injectionEndpoints/costTracking/companiesEndpoint';
import { useEditIndexMutation, useGetIndexesQuery } from '../../../../../redux/injectionEndpoints/costTracking/indexEndpoint';
import { useEditCostMutation, useGetCostsQuery } from '../../../../../redux/injectionEndpoints/costTracking/costsEndpoint';

const tableHeadings = [
    "",
    "Company",
    "Abbreviation",
    "ABN",
    "Business Address",
    "Primary Contact",
    "Primary Contact Number",
]

export const EditCompany = (props: company) => {
    //DATA FOR UPDATING INDEXES AND COSTS WHEN A COMPANY IS EDITED
    const { data: indexes } = useGetIndexesQuery();
    const { data: costs } = useGetCostsQuery();

    //EDIT SERVER SIDE DATA FUNCTIONS FROM RTK QUERY
    const [editCompany] = useEditCompanyMutation();
    const [editIndex] = useEditIndexMutation();
    const [editCost] = useEditCostMutation();

    //WHEN EDITING
    const [company, setcompany] = useState<string>(props.company)
    const [abbreviation, setabbreviation] = useState<string>(props.abbreviation)
    const [abn, setabn] = useState<string>(props.abn)
    const [primaryContact, setprimaryContact] = useState<string>(props.primaryContact)
    const [businessAddress, setbusinessAddress] = useState<string>(props.businessAddress)
    const [primaryContactNumber, setprimaryContactNumber] = useState<string>(props.primaryContactNumber);

    //MODAL FOR DOUBLE CHECKING IF THE USER IS SURE
    const [areYouSure, setareYouSure] = useState<boolean>(false)

    const updateCompany = (e: React.MouseEvent<HTMLButtonElement>) => {
        Object.entries(props).forEach(keyValuePair => {
            switch (keyValuePair[0]) {
                case "company":
                    if (keyValuePair[1] !== company) {
                        editCompany({ id: props.id!, field: "company", value: company }) //update company name
                        indexes && indexes.forEach(index => { //update each index assigned that company
                            if (index.company == props.company) editIndex({ id: index.id!, field: "company", value: company })
                        })
                        costs && costs.filter(cost => cost.company == props.company) // update each cost assigned with that company
                            .forEach(cost => editCost({ id: cost.id!, field: "company", value: company }))
                    }
                    break
                case "abbreviation":
                    if (keyValuePair[1] !== abbreviation) editCompany({ id: props.id!, field: "abbreviation", value: abbreviation })
                    break
                case "abn":
                    if (keyValuePair[1] !== abn) editCompany({ id: props.id!, field: "abn", value: abn })
                    break
                case "primaryContact":
                    if (keyValuePair[1] !== primaryContact) editCompany({ id: props.id!, field: "primaryContact", value: primaryContact })
                    break
                case "businessAddress":
                    if (keyValuePair[1] !== businessAddress) editCompany({ id: props.id!, field: "businessAddress", value: businessAddress })
                    break
                case "primaryContactNumber":
                    if (keyValuePair[1] !== primaryContactNumber) editCompany({ id: props.id!, field: "primaryContactNumber", value: primaryContactNumber })
                    break
            }
        })
        setareYouSure(false)
    }

    return (
        <>
            {areYouSure &&
                <Modal show={true} centered size='lg'>
                    <Modal.Header>
                        <Modal.Title>Are You Sure...</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>Updating Companies at this level will update ALL Indexes and ALL Costs assigned this Company</p>
                        <p>An alternative is to create a new company to start assigning costs to</p>
                        <hr></hr>
                        <button onClick={updateCompany} >Yes I am sure</button>
                        <button onClick={() => setareYouSure(false)}>No I am not</button>
                    </Modal.Body>
                </Modal>}
            <tr>
                <td><button onClick={() => setareYouSure(true)} className='universal-small-save-button'>Save</button></td>
                <td><input name='company' value={company} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setcompany(e.currentTarget.value)}></input></td>
                <td><input name='abbreviation' value={abbreviation} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setabbreviation(e.currentTarget.value)} ></input></td>
                <td ><input name='abn' value={abn} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setabn(e.currentTarget.value)}></input></td>
                <td><input name='businessAddress' value={businessAddress} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setbusinessAddress(e.currentTarget.value)}></input></td>
                <td ><input id='primaryContact' value={primaryContact} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setprimaryContact(e.currentTarget.value)}></input></td>
                <td><input id='primaryContactNumber' value={primaryContactNumber} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setprimaryContactNumber(e.currentTarget.value)}></input></td>
            </tr>
        </>
    )
}
