import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { resourceCode } from '../../types/costCode';

export interface viewResourceCodesPayload {
    resourceCodes: resourceCode[],
    codeTitle: string
}

export interface viewResourceCodesState {
    resourceCodesOpen: boolean,
    resourceCodes: resourceCode[],
    codeTitle: string
}

const initialState: viewResourceCodesState = {
    resourceCodesOpen: false,
    resourceCodes: [],
    codeTitle: ""
}

export const viewResourceCodesSlice = createSlice({
    name: "viewResourceCodes",
    initialState,
    reducers: {
        closeViewResourceCodes(state) {
            return {
                ...state,
                resourceCodesOpen: false,
                resourceCodes: [],
                codeTitle: ""
            }
        },
        openViewResourceCodes(state, action: PayloadAction<viewResourceCodesPayload>) {
            return {
                ...state,
                resourceCodesOpen: true,
                resourceCodes: action.payload.resourceCodes,
                codeTitle: action.payload.codeTitle
            }
        }
    }
});

export const { closeViewResourceCodes, openViewResourceCodes } = viewResourceCodesSlice.actions

export default viewResourceCodesSlice.reducer