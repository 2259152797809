import React, { FC, useState } from 'react'
import { Modal, Table } from 'react-bootstrap'
import { useDispatch } from 'react-redux'
import { useEditProjectMutation } from '../../redux/injectionEndpoints/costTracking/projectsEndpoint';
import { closeEditProjectPage } from '../../redux/reducers/adminReducer';
import { project } from '../../types/project';

type props = project

export const EditProject: FC<props> = (props) => {
    const dispatch = useDispatch();

    const [editProject] = useEditProjectMutation();

    const [projectName, setprojectName] = useState<string>("");
    const [projectCode, setprojectCode] = useState<string>("");
    const [projectSubCode, setprojectSubCode] = useState<string>("");
    const [projectNumber, setprojectNumber] = useState<string>("");
    const [status, setstatus] = useState<boolean>(props.status);

    const projectUpdate = (field: string) => {
        switch (field) {
            case "projectName":
                editProject({ ...props, projectName });
                setprojectName("")
                break
            case "projectCode":
                editProject({ ...props, projectCode })
                setprojectCode("")
                break
            case "projectSubCode":
                editProject({...props, projectSubCode});
                setprojectSubCode("")
                break
            case "projectNumber":
                editProject({...props, projectNumber});
                setprojectNumber("")
                break
            case "status":
                editProject({...props, status})
                break
        }
    }

    return (
        <Modal show={true} size={'lg'}>
            <Modal.Header closeButton onHide={() => dispatch(closeEditProjectPage())}>
                <Modal.Title >
                    Edit Project
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table >
                    <thead>
                        <tr>
                            <th>Field</th>
                            <th>Current Value</th>
                            <th>Updated Value</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th>Project Name</th>
                            <td>{props.projectName}</td>
                            <td><input value={projectName} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setprojectName(e.currentTarget.value)}></input></td>
                            <td>{(projectName !== props.projectName && projectName !== "") && <button onClick={() => projectUpdate("projectName")}>Confirm</button>}</td>
                        </tr>
                        <tr>
                            <th>Project Code</th>
                            <td>{props.projectCode}</td>
                            <td><input value={projectCode} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setprojectCode(e.currentTarget.value)}></input></td>
                            <td>{(projectCode !== props.projectCode && projectCode !== "") && <button onClick={() => projectUpdate("projectCode")} >Confirm</button>}</td>
                        </tr>
                        <tr>
                            <th>Project Sub-Code</th>
                            <td>{props.projectSubCode}</td>
                            <td><input value={projectSubCode} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setprojectSubCode(e.currentTarget.value)}></input></td>
                            <td>{(projectSubCode !== props.projectSubCode && projectSubCode !== "") && <button onClick={() => projectUpdate("projectSubCode")} >Confirm</button>}</td>
                        </tr>
                        <tr>
                            <th>Project Number</th>
                            <td>{props.projectNumber}</td>
                            <td><input value={projectNumber} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setprojectNumber(e.currentTarget.value)}></input></td>
                            <td>{(projectNumber !== props.projectNumber && projectNumber !== "") && <button onClick={() => projectUpdate("projectNumber")} >Confirm</button>}</td>
                        </tr>
                        <tr>
                            <th>Status</th>
                            <td>{props.status ? "Open" : "Closed"}</td>
                            <td>
                                <select value={status ? 1 : 0} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setstatus(Number(e.target.value) == 1 ? true : false)}>
                                    <option value={1}>Open</option>
                                    <option value={0}>Closed</option>
                                </select>
                            </td>
                            <td>{(status !== props.status) && <button onClick={() => projectUpdate("status")} >Confirm</button>}</td>
                        </tr>
                    </tbody>
                </Table>
            </Modal.Body>
        </Modal>
    )
}
