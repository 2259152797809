import { index } from "../../types";
import { company } from "../../types/company";
import { costCode } from "../../types/costCode";
import { resourceCode } from "../../types/resourceCode";

export interface forDropdown {
    name: string,
    id: string
}

export const indexMultiselectDropdown = (indexes: index[]) => {
    let indexesDropdown: forDropdown[] = []

    indexes.forEach(index => {
        indexesDropdown.push({
            name: index.id!,
            id: index.id!
        })
    })

    return indexesDropdown
}

export const companyMultiselectDropdown = (companies: company[]) => {
    let companiesDropdown: forDropdown[] = []

    companies.forEach(company => {
        companiesDropdown.push({
            name: company.company,
            id: company.id!.toString()
        })
    })

    return companiesDropdown
}

export const costCodeMultiselectDropdown = (costCodes: costCode[]) => {
    let costCodesDropdown: forDropdown[] = []

    costCodes.forEach(costCode => {
        costCodesDropdown.push({
            name: costCode.code.concat(".", costCode.subCode),
            id: costCode.id!.toString(),
        })
    })

    return costCodesDropdown
}

export const resourceCodeMultiselectDropdown = (resourceCodes: resourceCode[]) => {
    let resourceCodesDropdown: forDropdown[] = []

    resourceCodes.forEach(resourceCode => {
        resourceCodesDropdown.push({
            name: resourceCode.code,
            id: resourceCode.id!.toString()
        })
    })

    return resourceCodesDropdown
}