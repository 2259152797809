import React, { FC } from 'react';
import '../../../../../../node_modules/react-vis/dist/style.css'
import { XYPlot, XAxis, YAxis, ChartLabel, HorizontalGridLines, VerticalGridLines, LineMarkSeries, DiscreteColorLegend } from 'react-vis';
import { useGetCostsQuery } from '../../../../../redux/injectionEndpoints/costTracking/costsEndpoint';
import { generateProductionTrackingData, returnDataWithBudget } from '../../../../../functions/costTracking/productionTracking/generateProductionTrackingData';
import { useGetCostCodesQuery } from '../../../../../redux/injectionEndpoints/costTracking/costCodeEndpoint';
import { production } from '../../../../../types/productionTracking';
import { DailyGraph } from './DailyGraph';
import { UnitGraph } from './UnitGraph';

type props = {
    project: string,
    dollarTrackingCode: string,
    budgetUnitRate: number,
    budgetDailyRate: number,
    productionTracked: production[]
}

const TrackingGraph: FC<props> = (props) => {
    const { filteredCosts } = useGetCostsQuery(undefined, {
        selectFromResult: ({ data }) => ({
            filteredCosts: data?.filter((cost) => cost.project === props.project)
        })
    });

    const { dollarTrackedCodeName } = useGetCostCodesQuery(undefined, {
        selectFromResult: ({ data }) => ({
            dollarTrackedCodeName: data?.find(costCode => costCode.code.concat(".", costCode.subCode) === props.dollarTrackingCode)
        })
    });

    const [graphToShow, setgraphToShow] = React.useState<"unitRates" | "dailyRates">("unitRates")

    const items = [
        {
            title: dollarTrackedCodeName ? props.dollarTrackingCode.concat(" - ", dollarTrackedCodeName.subCodeName) : props.dollarTrackingCode,
            disabled: false,
            color: "blue"
        },
        {
            title: "Budget Unit Rate",
            disabled: false,
            color: "green"
        }
    ];

    const returnData = (): returnDataWithBudget => {
        if (filteredCosts) {
            const dollarTrackedArray = filteredCosts.filter(cost => cost.costCode === props.dollarTrackingCode);

            const undefinedArray = {
                productionQuantities: [{ x: "0", y: 0 }],
                dailySpend: [{ x: "0", y: 0 }],
                budgetUnitRates: [{ x: "0", y: 0 }],
                budgetDailyRates: [{ x: "0", y: 0 }],
            }

            if (!Array.isArray(dollarTrackedArray) || !dollarTrackedArray.length) return undefinedArray
            else if (filteredCosts) return generateProductionTrackingData(props.dollarTrackingCode, filteredCosts, props.budgetUnitRate, props.budgetDailyRate, props.productionTracked)
            else return undefinedArray
        } else return {
            productionQuantities: [{ x: "0", y: 0 }],
            dailySpend: [{ x: "0", y: 0 }],
            budgetUnitRates: [{ x: "0", y: 0 }],
            budgetDailyRates: [{ x: "0", y: 0 }],
        }
    };

    const chooseGraph = () => {
        switch (graphToShow) {
            case "dailyRates": return <DailyGraph project={props.project} dollarTrackingCode={props.dollarTrackingCode} budgetUnitRate={props.budgetUnitRate} budgetDailyRate={props.budgetDailyRate} productionTracked={[...props.productionTracked]} />
            case "unitRates": return <UnitGraph project={props.project} dollarTrackingCode={props.dollarTrackingCode} budgetUnitRate={props.budgetUnitRate} budgetDailyRate={props.budgetDailyRate} productionTracked={[...props.productionTracked]} />
            default: <UnitGraph project={props.project} dollarTrackingCode={props.dollarTrackingCode} budgetUnitRate={props.budgetUnitRate} budgetDailyRate={props.budgetDailyRate} productionTracked={[...props.productionTracked]} />
        }
    }

    return (
        <>
            <div>
                <button onClick={() => setgraphToShow("unitRates")}>Unit Costs</button>
                <button onClick={() => setgraphToShow("dailyRates")}>Daily Costs</button>
            </div>
            {chooseGraph()}
        </>
    )
}

export default TrackingGraph