import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { authRes, authState } from '../../types/auth';
import { API } from '../APIs/moduleAPI';
import { extendedAuthAPI } from '../injectionEndpoints/auth/authEndpoints';

const initialState: authState = {
    isAuthenticated: false,
    isLoading: false,
    userProfile: {
        firstName: "", //first name
        lastName: "",//last name
        position: "", //job position
        company: "", //company worked for
        email: "", //user email address
        phone: "", //user phone number
        userPrivilege: "",
        projects: [""],
        activated: false,
        token: ""
    },
    requestAccess: false,
    requestAccessMessage: ""
};

export const authSlice = createSlice({
    name: "auth", //auth reducer name
    initialState, //initial state from above
    reducers: {
        userLoading: state => { //for loading the user
            return {
                ...state,
                isLoading: true,
                isAuthenticated: false,
                userProfile: null,
                requestAccess: false,
                requestAccessMessage: ""
            }
        },
        loginSuccess(state, action: PayloadAction<authRes>) { //for login success
            return {
                ...state,
                isAuthenticated: true,
                isLoading: false,
                userProfile: action.payload.user,
                requestAccess: false,
                requestAccessMessage: ""
            }
        },
        logout(state) { //for logout
            return {
                ...state,
                isAuthenticated: false,
                isLoading: false,
                userProfile: null,
                requestAccess: false,
                requestAccessMessage: ""
            }
        },
        authError: state => { //for when authentication doesnt work
            return {
                ...state,
                isAuthenticated: false,
                isLoading: false,
                userProfile: null,
                requestAccess: false,
                requestAccessMessage: ""
            }
        },
        requestUserAccess(state) { //for opening window with request user access form
            return {
                ...state,
                isAuthenticated: false,
                isLoading: false,
                userProfile: null,
                requestAccess: true,
                requestAccessMessage: ""
            }
        },
        requestUserAccessComplete: state => { //for when a user requests regisgtration successfully and we return them to the login page
            return {
                ...state,
                isAuthenticated: false,
                isLoading: false,
                userProfile: null,
                requestAccess: false,
                requestAccessMessage: "Your request has been received. You will receive confirmation shortly"
            }
        },
        removeRequestUserAccess: state => { //for when we close the registration request page with no attempt at registration
            return {
                ...state,
                isAuthenticated: false,
                isLoading: false,
                userProfile: null,
                requestAccess: false,
                requestAccessMessage: ""
            }
        }
    },
    //EXTRA REDUCERS FOR HANDLING BACKEND API ACTIONS AND THEIR RESULTS
    extraReducers: (builder) => {
        builder.
            addMatcher(//this is for loading the user
                extendedAuthAPI.endpoints.authenticateUser.matchPending,
                (state, { payload }) => {
                    state.isLoading = true
                    state.isAuthenticated = false
                    state.userProfile = null
                    state.requestAccess = false
                    state.requestAccessMessage = ""
                }
            )
            .addMatcher( //this matcher is for a successful login
                extendedAuthAPI.endpoints.authenticateUser.matchFulfilled,
                (state, { payload }) => {
                    state.isAuthenticated = true
                    state.isLoading = false
                    state.userProfile = payload.user
                    state.requestAccess = false
                    state.requestAccessMessage = ""
                }
            )
            .addMatcher( //this matcher is for an unsuccessful login
                extendedAuthAPI.endpoints.authenticateUser.matchRejected,
                (state, { payload }) => {
                    state.isAuthenticated = false
                    state.isLoading = false
                    state.userProfile = null
                    state.requestAccess = false
                    state.requestAccessMessage = ""
                }
            )
            .addMatcher( //this matcher is for re-authenticating (for example when refreshing a page)
                extendedAuthAPI.endpoints.checkAuthentication.matchFulfilled,
                (state, { payload }) => {
                    state.isAuthenticated = true
                    state.isLoading = false
                    state.userProfile = payload.user
                    state.requestAccess = false
                    state.requestAccessMessage = ""
                }
            )
            .addMatcher( //this matcher is for re-authenticating pending (for example when refreshing a page)
                extendedAuthAPI.endpoints.checkAuthentication.matchPending,
                (state, { payload }) => {
                    state.isAuthenticated = false
                    state.isLoading = true
                    state.userProfile = null
                    state.requestAccess = false
                    state.requestAccessMessage = ""
                }
            )
            .addMatcher( //this matcher is for re-authenticating failure (for example when refreshing a page)
                extendedAuthAPI.endpoints.checkAuthentication.matchPending,
                (state, { payload }) => {
                    state.isAuthenticated = false
                    state.isLoading = false
                    state.userProfile = null
                    state.requestAccess = false
                    state.requestAccessMessage = ""
                }
            )
            .addMatcher( //this matcher is for logging out
                extendedAuthAPI.endpoints.logout.matchFulfilled,
                (state, { payload }) => {
                    state.isAuthenticated = false
                    state.isLoading = false
                    state.userProfile = null
                    state.requestAccess = false
                    state.requestAccessMessage = ""
                }
            )
    }
})

export const { userLoading, loginSuccess, logout, authError, requestUserAccess, requestUserAccessComplete, removeRequestUserAccess } = authSlice.actions

export default authSlice.reducer