import { costTemplate } from "../../../types/costTemplate";
import { dailyNotification } from '../../../types/dailyNotification'
import { editObject } from "../../../types/edit";
import { API } from "../../APIs/moduleAPI";

export const extendedNotificationAPI = API
    .enhanceEndpoints({ addTagTypes: ['Notification' as const] })
    .injectEndpoints({
        endpoints: builder => ({
            getTemplateNotification: builder.query<dailyNotification[], void>({ //when we want to retrieve our notification for the day
                query() {
                    return {
                        url: `costTracking/getDailyTemplateNotification`,
                        method: 'GET'
                    }
                },
                providesTags: ["Notification"]
            }),
            closeTemplateNotification: builder.mutation<dailyNotification[], any>({ //when we want to retrieve our notification for the day
                query(id) {
                    return {
                        url: `costTracking/closeDailyTemplateNotification/${id}`,
                        method: 'PUT',
                    }
                },
                invalidatesTags: ["Notification"]
            }),
        })
    })

    export const {
        useGetTemplateNotificationQuery,
        useCloseTemplateNotificationMutation
    } = extendedNotificationAPI