import React, { useState, useEffect } from 'react';
import { Modal, Table } from 'react-bootstrap';
import { PlusCircle, DashCircle, Icon, Trash3 } from 'react-bootstrap-icons';
import { rate } from '../../../../../types';
import { useGetCostCodesQuery } from '../../../../../redux/injectionEndpoints/costTracking/costCodeEndpoint';
import { useGetResourceCodesQuery } from '../../../../../redux/injectionEndpoints/costTracking/resourceCodeEndpoint';
import { useGetCompaniesQuery } from '../../../../../redux/injectionEndpoints/costTracking/companiesEndpoint';
import { useAddIndexMutation } from '../../../../../redux/injectionEndpoints/costTracking/indexEndpoint';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';

const topTableHeadings = [
    "Company",
    "Description",
    "Cost Code",
    "Resource Code",
    "Rates"
];

const bottomTableHeadings = [
    "Rate",
    "Unit",
    "Rate Description",
    "Save"
]

type props = {
    closeAddIndexModal: () => void,
    addIndex: boolean
}

export const AddIndex = (props: props) => {
    const [AddIndex] = useAddIndexMutation();

    const selectedProject = useSelector((state: RootState) => state.tabs.projectTab) //subsrcibe to the project currently selected

    const { data: costCodesData } = useGetCostCodesQuery(); //for retrieving all cost codes
    const { data: resourceCodesData } = useGetResourceCodesQuery(); //for retrieving all resource codes
    const { data: companiesData } = useGetCompaniesQuery(); //for retrieving all companies

    const [resourceCode, setresourceCode] = useState<string>("0") //resource code to be selected
    const [company, setcompany] = useState<string>("0") //company for the index
    const [description, setdescription] = useState<string>("") //description of the index
    const [subCostCode, setsubCostCode] = useState<string>("0") //cost code assigned to index (optional)

    const [rates, setrates] = useState<rate[]>([]) //this rates array will be the final array added to our index

    const [rateId, setrateId] = useState<number>(1)
    const [newRate, setnewRate] = useState<number | undefined>()
    const [newUnit, setnewUnit] = useState<string>("")
    const [newRateDescription, setnewRateDescription] = useState<string>("")

    const saveRate = (e: React.MouseEvent<HTMLButtonElement>) => { //function add new rate to rate array
        e.preventDefault()
        //enter code here to check all entries are filled

        const rateToAdd: rate = { //create new complete rate to add to rate array
            id: rateId,
            rate: newRate,
            unit: newUnit,
            rateDescription: newRateDescription
        }

        setrates([...rates, rateToAdd]) //add the new complete rate to the rate array

        setrateId(rateId + 1)
        setnewRate(0)
        setnewUnit("")
        setnewRateDescription("")
    }

    const deleteRate = (e: React.MouseEvent<SVGElement>) => {
        e.preventDefault();

        const newRates = rates.filter(rate => rate.id?.toString() !== e.currentTarget.id) //filter out the deleted rate

        setrates(newRates)
    }

    const saveIndex = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        AddIndex({
            project: selectedProject,
            company,
            description,
            resourceCode,
            subCostCode, //cost code and sub-code concatenated applied to the index. "0" means no code is selected
            rates, //to be stringified in the back end
            status: 1
        })
        setdescription("")
        setsubCostCode("0")
        setrates([])
    }

    return (
        <Modal show={props.addIndex} dialogClassName="modal-80w">
            <Modal.Header closeButton onHide={() => props.closeAddIndexModal()}>
                <Modal.Title>Add Index</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div id="add-index">
                    <Table>
                        <thead>
                            <tr>
                                {topTableHeadings.map(heading => {
                                    return <th>{heading}</th>
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <select value={company} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setcompany(e.currentTarget.value)}>
                                        <option value="0">No Company</option>
                                        {companiesData?.map(company => {
                                            return <option id={company.id?.toString()} value={company.company}>{company.company}</option>
                                        })}
                                    </select>
                                </td>
                                <td><input value={description} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setdescription(e.currentTarget.value)}></input></td>
                                <td>
                                    <select value={subCostCode} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setsubCostCode(e.currentTarget.value)}>
                                        {<option value="0">No Cost Code</option>}
                                        {costCodesData?.map(costCode => {
                                            if (costCode.project == selectedProject) {
                                                return <option id={costCode.id!.toString()} value={costCode.code.concat(".", costCode.subCode)} >{costCode.code.concat(".", costCode.subCode)}</option>
                                            }
                                        })}
                                    </select>
                                </td>
                                <td>
                                    <select value={resourceCode} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setresourceCode(e.currentTarget.value)}>
                                        <option value="0">No Resource Code</option>
                                        {resourceCodesData?.map(resourceCode => {
                                            if (resourceCode.project == selectedProject) {
                                                return <option id={resourceCode.id!.toString()} value={resourceCode.code} >{resourceCode.code}</option>
                                            }
                                        })}
                                    </select>
                                </td>
                                <td>
                                    <Table>
                                        <thead>
                                            <tr>
                                                {bottomTableHeadings.map(heading => {
                                                    return <th>{heading}</th>
                                                })}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rates &&
                                                rates.map(rate => {
                                                    return (
                                                        <tr>
                                                            <td>{rate.rate}</td>
                                                            <td>{rate.unit}</td>
                                                            <td>{rate.rateDescription}</td>
                                                            <td><Trash3 id={rate.id?.toString()} onClick={deleteRate} /></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                            <tr>
                                                <td>
                                                    <input type="number" value={newRate} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setnewRate(Number(e.currentTarget.value))}></input>
                                                </td>
                                                <td>
                                                    <input value={newUnit} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setnewUnit(e.currentTarget.value)}></input>
                                                </td>
                                                <td>
                                                    <input value={newRateDescription} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setnewRateDescription(e.currentTarget.value)}></input>
                                                </td>
                                                <td>
                                                    <button className='universal-small-save-button' onClick={saveRate}>Save Rate</button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
                <span className='save-item' >
                    <button className='universal-save-button' onClick={saveIndex}>Save Index</button>
                </span>
            </Modal.Body>
            <Modal.Footer>
                <button className='universal-close-button' onClick={() => props.closeAddIndexModal()}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}
