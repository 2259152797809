import React, { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { CheckCircle, XCircle } from 'react-bootstrap-icons'
import { useNavigate } from 'react-router-dom';
import { passwordStrength } from 'check-password-strength';
import { useEditUserMutation, useGetUsersQuery } from '../../redux/injectionEndpoints/users/userEndpoints';
import { user } from '../../types/user';

type props = user

export const ChangePassword: FC<props> = (userProp) => {
    const navigate = useNavigate();

    const [editUser] = useEditUserMutation();

    const { userToUpdate } = useGetUsersQuery(undefined, {
        selectFromResult: ({data}) => ({
            userToUpdate: data?.find(user => user.email === userProp.email)
        })
    })

    const [newPassword, setnewPassword] = React.useState<string>("")
    const [newPasswordCheck, setnewPasswordCheck] = React.useState<string>("")

    const checkPasswordStrength = () => {
        var passStrength = passwordStrength(newPassword)

        if (newPassword === "") return {
            message: "",
            id: 0
        }

        else {
            if (passStrength.id === 0) {
                return {
                    message: "Password is weak",
                    id: passStrength.id
                }
            }
            else {
                return {
                    message: "Password is".concat(" ", passStrength.value.toLowerCase()),
                    id: passStrength.id
                }
            }
        }
    };

    const checkPasswordsTheSame = () => {
        if (newPasswordCheck === "") return {
            message: "",
            id: -1
        }
        else if (newPasswordCheck !== newPassword) return {
            message: "Passwords do not match",
            id: 0
        }
        else if (newPasswordCheck === newPassword) return {
            message: "Passwords match",
            id: 1
        }
        else return {
            message: "",
            id: -1
        }
    };

    const editPassword = () => {
        if (checkPasswordsTheSame().id === 0) {
            return console.log("not the same")
        } else if (newPassword && userToUpdate) {
            const modifiedUser = {...userToUpdate!}
            const passwords = {...modifiedUser.passwords}
            const oldPassword = passwords.password
            passwords.password = newPassword
            passwords.previousPasswords = [...passwords.previousPasswords!, oldPassword!]
            editUser({
                ...modifiedUser,
                passwords
            })
        }
    }

    return (
        <Modal show={true} size="lg" centered>
            <Modal.Header closeButton onHide={() => navigate("/")}>
                <Modal.Title>Change User Password</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="NewPassword-Container">
                    <p>Enter New Password</p>
                    <input type="password" value={newPassword} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setnewPassword(e.target.value)}></input>
                    <p style={{ margin: "2% 4%" }} >{checkPasswordStrength().message}</p>
                    <p>Re-Enter New Password</p>
                    <input type="password" value={newPasswordCheck} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setnewPasswordCheck(e.target.value)}></input>
                    <div className='NewPassword-Container-subContainer'>
                        <p ><b>{checkPasswordsTheSame().message}</b></p>
                        {checkPasswordsTheSame().id === 1 && <p><CheckCircle color='green' /></p>}
                        {checkPasswordsTheSame().id === 0 && <p><XCircle color='red' /></p>}
                    </div>
                </div>
                <div style={{ margin: "1% auto", display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                    <button onClick={() => editPassword()} >Save</button>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={() => navigate("/")}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}
