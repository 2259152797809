import React from 'react'
import { useSelector } from 'react-redux'
import { generateProductionTrackingDates } from '../../../../../functions/costTracking/productionTracking/generateTrackingDates'
import { useGetCostsQuery } from '../../../../../redux/injectionEndpoints/costTracking/costsEndpoint'
import { useEditProductionTrackingMutation, useGetProductionTrackingQuery } from '../../../../../redux/injectionEndpoints/costTracking/productionTrackingEndpoint'
import { RootState } from '../../../../../redux/store'
import { CaretDown, CaretUp } from 'react-bootstrap-icons'
import { useGetCostCodesQuery } from '../../../../../redux/injectionEndpoints/costTracking/costCodeEndpoint'
import { production, productionTracking } from '../../../../../types/productionTracking';
import { useNavigate } from 'react-router-dom';

export const EditProductionTracking = () => {
    const navigate = useNavigate();

    var selectedProject = useSelector((state: RootState) => state.tabs.projectTab);
    const { costCodes } = useGetCostCodesQuery(undefined, {
        selectFromResult: ({ data }) => ({
            costCodes: data?.filter(costCode => costCode.project === selectedProject)
        })
    })

    const [editProductionTracking] = useEditProductionTrackingMutation();

    const [selectedProductionTrackingCode, setselectedProductionTrackingCode] = React.useState<string>()
    const { costs } = useGetCostsQuery(undefined, { // here, we are returning costs for the project as per usual. additionally, we are returning costs for the selected cost code being tracked (if selected), so we can check dates in the render
        selectFromResult: ({ data }) => ({
            costs: data?.filter(cost => cost.project === selectedProject)
                .filter(projectCost => {
                    if (selectedProductionTrackingCode) {
                        return projectCost.costCode === selectedProductionTrackingCode
                    } else {
                        return projectCost
                    }
                })
        })
    })

    var { productionTracking, refetch } = useGetProductionTrackingQuery(undefined, {
        selectFromResult: ({ data }) => ({
            productionTracking: data?.filter(productionTracked => productionTracked.project === selectedProject)
        })
    });

    const [openTracking, setopenTracking] = React.useState<string>(""); //to determine which production tracking is shown. governed by MongoDB ID

    //for updating fields
    const [name, setname] = React.useState<string>("");
    const [budgetUnitRate, setbudgetUnitRate] = React.useState<string>("")
    const [budgetDailyRate, setbudgetDailyRate] = React.useState<string>("")
    const [dollarTrackingCode, setdollarTrackingCode] = React.useState<string>("")
    const [unitBeingTracked, setunitBeingTracked] = React.useState<string>("")

    //for updating quantities
    type quantitiesArrayItem = {
        value: number,
        index: number
    }
    const [quantities, setquantities] = React.useState<quantitiesArrayItem[]>([])

    const updateQuantities = (index: number, value: string) => {
        //in this case, index is the index of the productionTracking array.
        setquantities(prevQuantities => {

            if (prevQuantities.find(prevQuant => prevQuant.index === index)) { //this is the case of the quantity already being there and existing
                let quantToUpdate = prevQuantities.find(prevQuant => prevQuant.index === index) //this is so we can update the value
                let indexOfQuantToUpdate = prevQuantities.findIndex(prevQuant => prevQuant.index === index) //this is so we know where to put the new item
                quantToUpdate!.value = Number(value) //update the value
                let newQuants = [...prevQuantities]; //open up our array for business
                newQuants[indexOfQuantToUpdate] = quantToUpdate!
                return newQuants
            } else { //this is the case of the quantity not existing yet
                let newQuants = [...prevQuantities]; //open up our array for business
                let newQuant = { //this is our new quantity
                    value: Number(value),
                    index
                }
                newQuants.push(newQuant)
                return newQuants
            }
        })
    };

    const editWithQuantities = (prodTrack: productionTracking, dateIndex: number) => {
        if (quantities.find(quantity => quantity.index === dateIndex)) {
            let newQuantities = [...prodTrack.productionTracked] //open up this array
            let newQuantity = { ...newQuantities[dateIndex] } //find the item to be edited
            newQuantity.quantity = quantities.find(quantity => quantity.index === dateIndex)!.value //update its quantity
            newQuantities[dateIndex] = newQuantity //add it back to the array
            editProductionTracking({ //send that array to the db
                id: openTracking,
                field: "productionTracked",
                value: newQuantities
            })
            setquantities([])
            refetch()
            return
        }
    }

    React.useEffect(() => {
        refetch();
    }, [editProductionTracking, name, budgetUnitRate, budgetDailyRate, dollarTrackingCode, unitBeingTracked, openTracking])

    return (
        <div>
            <div className="costs-heading">
                <div className="modal-subTabs">
                    <span className="modal-subTabs-item">
                        <button onClick={() => navigate("/ProductionTracking")} className='universal-delete-button'>Exit</button>
                    </span>
                </div>
            </div>
            <div>
                {productionTracking?.map((prodTrack, index) => {
                    return <>
                        <div className='productionTracking-dropdown'>
                            <h6 >{prodTrack.name}</h6>
                            {prodTrack._id! !== openTracking && <CaretDown onClick={() => { setopenTracking(prodTrack._id!); setselectedProductionTrackingCode(prodTrack.dollarTrackingCode); setquantities([]) }} />}
                            {prodTrack._id! === openTracking && <CaretUp onClick={() => { setopenTracking(""); setquantities([]) }} />}
                        </div>
                        {prodTrack._id! === openTracking &&
                            <div className='productionTracking-dropdown-body' id={index.toString()}>
                                <div className="productionTracking-dropdown-body-left">
                                    <h6>Field</h6>
                                    <h6>Current Value</h6>
                                    <h6>Updated</h6>
                                    <p></p>
                                    <p>Name: </p>
                                    <p style={{ fontWeight: "lighter" }}>{prodTrack.name}</p>
                                    <input value={name} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setname(e.target.value)} ></input>
                                    <button className="productionTracking-dropdown-body-left-button" onClick={() => { editProductionTracking({ id: openTracking, field: "name", value: name }); setname("") }}>Update</button>
                                    <p>Budget Unit Rate: </p>
                                    <p style={{ fontWeight: "lighter" }}>{prodTrack.budgetUnitRate}</p>
                                    <input value={budgetUnitRate} type="number" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setbudgetUnitRate(e.target.value)} ></input>
                                    <button className="productionTracking-dropdown-body-left-button" onClick={() => { editProductionTracking({ id: openTracking, field: "budgetUnitRate", value: Number(budgetUnitRate) }); setbudgetUnitRate("") }}>Update</button>
                                    <p>Budget Daily Rate: </p>
                                    <p style={{ fontWeight: "lighter" }}>{prodTrack.budgetDailyRate}</p>
                                    <input value={budgetDailyRate} type="number" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setbudgetDailyRate(e.target.value)} ></input>
                                    <button className="productionTracking-dropdown-body-left-button" onClick={() => { editProductionTracking({ id: openTracking, field: "budgetDailyRate", value: Number(budgetDailyRate) }); setbudgetDailyRate("") }}>Update</button>
                                    <p>Dollar Tracking Code: </p>
                                    <p style={{ fontWeight: "lighter" }}>{prodTrack.dollarTrackingCode}</p>
                                    <select value={dollarTrackingCode} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setdollarTrackingCode(e.target.value)}>
                                        {costCodes && costCodes.map(costCode => {
                                            return <option value={costCode.code.concat(".", costCode.subCode)}>{costCode.code.concat(".", costCode.subCode)}</option>
                                        })}
                                    </select>
                                    <button className="productionTracking-dropdown-body-left-button" onClick={() => { editProductionTracking({ id: openTracking, field: "dollarTrackingCode", value: dollarTrackingCode }); setdollarTrackingCode("") }}>Update</button>
                                    <p>Unit Being Tracked: </p>
                                    <p style={{ fontWeight: "lighter" }}>{prodTrack.unitBeingTracked}</p>
                                    <input value={unitBeingTracked} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setunitBeingTracked(e.target.value)}></input>
                                    <button className="productionTracking-dropdown-body-left-button" onClick={() => { editProductionTracking({ id: openTracking, field: "unitBeingTracked", value: unitBeingTracked }); setunitBeingTracked("") }}>Update</button>
                                </div>
                                <div className="productionTracking-dropdown-body-right">
                                    <h6>Dates</h6>
                                    <h6>Current Production </h6>
                                    <h6>Updated Production </h6>
                                    <h6>Unit</h6>
                                    <h6></h6>
                                    {prodTrack.productionTracked.map((production, dateIndex) => {
                                        if (costs?.find(cost => cost.date === production.date)) {
                                            return <>
                                                <p>{production.date}</p>
                                                <p>{prodTrack.productionTracked[dateIndex].quantity === undefined || null ? "0" : prodTrack.productionTracked[dateIndex].quantity}</p>
                                                <input type="number" onChange={(e: React.ChangeEvent<HTMLInputElement>) => { updateQuantities(dateIndex, e.target.value) }}></input>
                                                <p>{prodTrack.unitBeingTracked}</p>
                                                <button className='productionTracking-dropdown-body-right-button' onClick={() => { editWithQuantities(prodTrack, dateIndex); refetch() }}>Update</button>
                                            </>
                                        }
                                    })}
                                </div>
                            </div>
                        }
                    </>
                })}
            </div>
        </div>
    )
}
