import { cost } from "../../types/cost";
import { costCode } from '../../types/costCode'

export const costCodeCostSummary = (costs: cost[] | undefined, costCode: string): number => {//calculates the sum of all costs for a given cost code
    var finalCost = 0;

    costs && costs.forEach(cost => {
        if (cost.costCode === costCode) {
            finalCost += cost.totalCost
        }
    })

    return finalCost
}

export const metaCostCodeSummary = (costs: cost[] | undefined, costCode: string): number => {
    var finalCost = 0;

    costs && costs.forEach(cost => {
        if (cost.costCode.split(".")[0] === costCode) {
            finalCost += cost.totalCost
        }
    })

    return finalCost
}

export const monthlyCostCodeSummary = (costs: cost[] | undefined, costCode: string): number => { //calculates the sum of all costs for a given cost code for the current month
    var finalCost = 0;

    costs && costs.forEach(cost => {
        if (cost.costCode === costCode) {
            const today = new Date()
            if (Number(cost.date.split("-")[1]) === (today.getMonth() + 1)) {
                finalCost += cost.totalCost
            }
        }
    })

    return finalCost
};

export const metaMonthlyCostCodeSummary = (costs: cost[] | undefined, costCode: string): number => {
    var finalCost = 0;

    costs && costs.forEach(cost => {
        if (cost.costCode.split(".")[0] === costCode) {
            const today = new Date()
            if (Number(cost.date.split("-")[1]) === (today.getMonth() + 1)) {
                finalCost += cost.totalCost
            }
        }
    })

    return finalCost
}

export const metaCostCodeStatus = (costCodes: costCode[] | undefined, costCodeInput: string): boolean => {
    var finalStatus = false;

    costCodes && costCodes.forEach(costCode => {
        if (costCode.code === costCodeInput) {
            if (costCode.status) {
                finalStatus = true
                return
            }
        }
    })

    return finalStatus
}