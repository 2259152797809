import React, { FC } from 'react'
import { Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import { closeViewResourceCodes } from '../../../../../redux/reducers/viewResourceCodesReducer';
import { RootState } from '../../../../../redux/store';
import { Table } from 'react-bootstrap';
import { resourceCode } from '../../../../../types/costCode';
import { dollarConverter } from '../../../../../functions/dollarConverter';

const resourceCodeHeadings = [
    "Code",
    "Rate",
    "Unit",
    "Quantity",
    "Total",
    ""
];

export const ViewResourceCodes = () => {

    const dispatch = useDispatch(); //dispatch for closing the modal
    const resourceCodes = useSelector((state: RootState) => state.resourceCodes.resourceCodes) //for when we want to view rates of a cost code
    const resourceCodeTitle = useSelector((state: RootState) => state.resourceCodes.codeTitle) //so we know what cost code we are viewing rates for

    return (
        <div>
            <Modal show={true} size="lg">
                <Modal.Header closeButton onHide={() => dispatch(closeViewResourceCodes())}>
                    <Modal.Title>
                        View Resource Codes: {resourceCodeTitle}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table>
                        <thead>
                            <tr>
                                {resourceCodeHeadings.map(heading => {
                                    return <th>{heading}</th>
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {resourceCodes.map(resourceCode => {
                                return <tr>
                                    <td>{resourceCode.resource}</td>
                                    <td>{dollarConverter(resourceCode.rate, "$", "") }</td>
                                    <td>{resourceCode.unit}</td>
                                    <td>{dollarConverter(resourceCode.quantity, "$", "") }</td>
                                    <td>{dollarConverter(resourceCode.total, "$", "") }</td>
                                </tr>
                            })}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => dispatch(closeViewResourceCodes())}>Close</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
