import React from 'react'
import { Modal } from 'react-bootstrap'
import { cost, costComment } from '../../../../../types/cost'
import { useEditCostMutation, useGetCostsQuery } from '../../../../../redux/injectionEndpoints/costTracking/costsEndpoint'
import { useSelector } from 'react-redux'
import { RootState } from '../../../../../redux/store'

type props = {
    closeAddCostCommentModal: () => void,
    addComment: boolean
    costId: string
}

export const AddCostComment = (props: props) => {

    const loggedInUser = useSelector((state: RootState) => state.user.userProfile!.firstName.concat(" ", state.user.userProfile!.lastName))

    const { checkedCost } = useGetCostsQuery(undefined, {
        selectFromResult: ({ data }) => ({
            checkedCost: data?.find(cost => cost.id === Number(props.costId))
        })
    })
    const [editCost] = useEditCostMutation();
    const [newComment, setnewComment] = React.useState<string>("")

    const saveComment = () => {

        const today = new Date().toDateString()

        const newCostComment: costComment = {
            madeBy: loggedInUser,
            date: today,
            comment: newComment
        }

        let newCostComments

        if (checkedCost) { //only complete if checkedCost can be found
            if ((checkedCost?.comments === undefined || null) || checkedCost?.comments.length === 0) {
                newCostComments = [newCostComment]
            } else {
                newCostComments = [newCostComment, ...checkedCost.comments]
            }
            
            editCost({
                id: props.costId,
                field: "comments",
                value: newCostComments
            })
        }

    }

    return (
        <Modal show={props.addComment} dialogClassName='modal-80w'>
            <Modal.Header closeButton onHide={() => props.closeAddCostCommentModal()}>
                <Modal.Title>Add comment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="cost-comments-viewing-window">
                    <div className="cost-comments-viewing-window-left">
                        <h6>New Comment</h6>
                        <textarea rows={5} onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => setnewComment(e.target.value)} ></textarea>
                        <button onClick={() => saveComment()}>Save Comment</button>
                    </div>
                    <div className="cost-comments-viewing-window-right">
                        <h6>History</h6>
                        {Array.isArray(checkedCost!.comments) && checkedCost!.comments!.length > 0 && 
                            checkedCost!.comments?.map((comment, index) => {
                                return <div id="cost-comments-view-comments-container">
                                    <p style={{fontWeight: "bold"}}>{comment.date} - {comment.madeBy}</p>
                                    <p>{comment.comment}</p>
                                </div>
                            })
                        }
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={() => props.closeAddCostCommentModal()}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}
