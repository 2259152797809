import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { cost, costRate } from '../../../../../types/cost';
import { dollarConverter } from '../../../../../functions/dollarConverter';
import { useEditCostMutation, useGetCostQuery } from '../../../../../redux/injectionEndpoints/costTracking/costsEndpoint';
import { Trash } from 'react-bootstrap-icons';
import { useGetResourceCodesQuery } from '../../../../../redux/injectionEndpoints/costTracking/resourceCodeEndpoint';
import { useGetIndexesQuery } from '../../../../../redux/injectionEndpoints/costTracking/indexEndpoint';
import { useGetCostCodesQuery } from '../../../../../redux/injectionEndpoints/costTracking/costCodeEndpoint';
import { useGetCompaniesQuery } from '../../../../../redux/injectionEndpoints/costTracking/companiesEndpoint';

const bottomTableHeadings = [
    "Description",
    "Rate",
    "Unit",
    "Number of",
    "Sub-Total",
    ""
];

type props = {
    cost:cost,
    deleteCost: (costId:string) => void
}

export const EditCost = (props: props) => {
    const [editCost] = useEditCostMutation();
    const { data: costData } = useGetCostQuery(props.cost.id!.toString()) //id determines which cost is for editing.
    const { data: resourceCodes } = useGetResourceCodesQuery();
    const { data: indexes } = useGetIndexesQuery();
    const { data: costCodes } = useGetCostCodesQuery();
    const { data: companies } = useGetCompaniesQuery();

    //TEXT FIELD STATE FOR UPDATING
    const [invoiceNumber, setinvoiceNumber] = useState<string>(props.cost.invoiceNumber);
    const [docketNumber, setdocketNumber] = useState<string>(props.cost.docketNumber);
    const [purchaseOrder, setpurchaseOrder] = useState<string>(props.cost.purchaseOrder)
    const [description, setdescription] = useState<string>(props.cost.description)

    ///NEW RATES IF EDITING
    const [newRate, setnewRate] = useState<number>()
    const [newUnit, setnewUnit] = useState<string>("")
    const [newRateDescription, setnewRateDescription] = useState<string>("");
    const [newQuantity, setnewQuantity] = useState<number>()

    const [rates, setrates] = useState<costRate[]>([...props.cost.rates]) //this rates array will be the final array edited in our cost
    const [quantities, setquantities] = useState<number[]>(Array(props.cost.rates.length).fill(null).map((_, index) => props.cost.rates[index].numberOf)) //this returns an array with just the quantities for each rate given in our cost

    const saveRate = (e: React.MouseEvent<HTMLButtonElement>) => { //function add new rate to rate array
        e.preventDefault()
        //enter code here to check all entries are filled

        const rateToAdd: costRate = { //create new complete rate to add to rate array
            rate: newRate!,
            unit: newUnit,
            description: newRateDescription,
            numberOf: newQuantity!,
            valueOf: newRate! * newQuantity!
        }

        setrates([...rates, rateToAdd]) //add the new complete rate to the rate array
        editCost({ id: props.cost.id!, field: "rates", value: [...rates, rateToAdd] })
        editCost({ id: props.cost.id!, field: "totalCost", value: [...rates, rateToAdd].map(rate => rate.valueOf).reduce((prev, curr) => prev + curr, 0) })

        // setrateId(Math.max(...rates.map(rate => rate.id!)) + 1)
        setnewRate(0)
        setnewUnit("")
        setnewRateDescription("")
        setnewQuantity(0)
    };

    const deleteRate = (e: React.MouseEvent<SVGElement>, index: number) => { //deleting rate from the cost
        var newRates: costRate[] = [] //initialise new rates after deletion
        rates.forEach((rate, rateIndex) => {
            if (rateIndex !== index) newRates.push(rate) //only include rate if it doesnt have index of deleted rate
        })
        setrates(newRates) //set new rate to state
        editCost({ id: props.cost.id!, field: "rates", value: newRates })
        editCost({ id: props.cost.id!, field: "totalCost", value: newRates.map(rate => rate.valueOf).reduce((prev, curr) => prev + curr, 0) })
    }

    const updateRates = () => { //for finalising the updated rate in a DB
        editCost({ id: props.cost.id!, field: "rates", value: rates })
        editCost({ id: props.cost.id!, field: "totalCost", value: rates.map(rate => rate.valueOf).reduce((prev, curr) => prev + curr, 0) })
    };

    const updateIndex = (e: React.ChangeEvent<HTMLSelectElement>) => {//updating index changes resourceCode, company, description, rates, costCode

        //update standard fields
        editCost({ id: props.cost.id!, field: "index", value: e.target.value })
        editCost({ id: props.cost.id!, field: "company", value: indexes?.find(index => index.id == e.target.value)?.company! })
        editCost({ id: props.cost.id!, field: "description", value: indexes?.find(index => index.id == e.target.value)?.description! })
        editCost({ id: props.cost.id!, field: "resourceCode", value: indexes?.find(index => index.id == e.target.value)?.resourceCode! })

        //update cost code if cost code is set in index
        if (indexes?.find(index => index.id == e.target.value)?.subCostCode !== "0") {
            editCost({ id: props.cost.id!, field: "costCode", value: indexes?.find(index => index.id == e.target.value)?.subCostCode! })
        }

        //update rates
        editCost({
            id: props.cost.id!,
            field: "rates",
            value: indexes!.find(index => index.id == e.target.value)!.rates!.map((rate, index) => {
                return {
                    description: rate.rateDescription ? rate.rateDescription : "",
                    rate: rate.rate ? rate.rate : 0,
                    unit: rate.unit,
                    numberOf: 0,
                    valueOf: 0
                }
            })
        })
        editCost({ id: props.cost.id!, field: "totalCost", value: 0 })
    }

    const editCostDropdownProp = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => { //for editing dropdown properties (cost code, resource code etc)
        editCost({ id: props.cost.id!, field: e.currentTarget.name, value: e.currentTarget.value })
    }

    const editCostProp = (e: React.MouseEvent<HTMLButtonElement>) => { //for editing typed properties
        editCost({ id: props.cost.id!, field: "description", value: description })
        editCost({ id: props.cost.id!, field: "invoiceNumber", value: invoiceNumber })
        editCost({ id: props.cost.id!, field: "purchaseOrder", value: purchaseOrder })
        editCost({ id: props.cost.id!, field: "docketNumber", value: docketNumber })
    }

    //for updating resource codes rates array
    const updateRatesNumberOfState = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {

        setquantities(prevQuantities => {
            let newQuants = [...prevQuantities];
            let newQuant = newQuants[index]
            newQuant = Number(e.target.value);
            newQuants[index] = newQuant

            setrates(prevRates => {
                let newRates = [...prevRates];
                let newRate = { ...newRates[index] }
                newRate.numberOf = newQuant;
                newRate.valueOf = newQuant * newRate.rate;
                newRates[index] = newRate;
                return newRates;
            })

            return newQuants
        })
    }

    useEffect(() => {
        setquantities(Array(props.cost.rates.length).fill(null).map((_, index) => props.cost.rates[index].numberOf)) //this returns an array with just the quantities for each rate given in our cost
    }, [props.cost])

    return (
        <>
            <>
                {costData &&
                    <tr>
                        <td><button onClick={editCostProp} className='universal-small-save-button' >Save</button></td>
                        <td>
                            <select value={props.cost.index!} style={{ maxWidth: "70px" }} onChange={updateIndex} >
                                <option value={"0"}>0</option>
                                {indexes && indexes.map(index => {
                                    return <option value={index.id!}>{index.id}</option>
                                })}
                            </select>
                        </td>
                        <td><input type="date" name="date" value={costData[0].date} style={{ maxWidth: "100px" }} onChange={editCostDropdownProp}></input></td>
                        {props.cost.index == "0" || 0 ?
                            <td>
                                <select value={props.cost.resourceCode} style={{ maxWidth: "80px" }} name="resourceCode" onChange={editCostDropdownProp}>
                                    <option value="0">Select</option>
                                    {resourceCodes && resourceCodes.map(resourceCode => {
                                        return <option value={resourceCode.code}>{resourceCode.code}</option>
                                    })}
                                </select>
                            </td> : <td><p>{costData[0].resourceCode}</p></td>}
                        <td><input name="docketNumber" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setdocketNumber(e.currentTarget.value)} value={docketNumber}></input></td>
                        <td><input name="purchaseOrder" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setpurchaseOrder(e.currentTarget.value)} value={purchaseOrder}></input></td>
                        {props.cost.index == "0" || 0 ?
                            <td>
                                <select value={props.cost.company} name="company" onChange={editCostDropdownProp}>
                                    <option value="0">Select</option>
                                    {companies && companies.map(company => {
                                        return <option value={company.company}>{company.company}</option>
                                    })}
                                </select>
                            </td> : <td><p>{costData[0].company}</p></td>}
                        {props.cost.index == "0" || 0 ?
                            <td><input name="description" value={description} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setdescription(e.currentTarget.value)}></input></td> :
                            <td><p>{costData[0].description}</p></td>}
                        {props.cost.index == "0" || 0 || indexes?.find(index => index.id === props.cost.index)?.subCostCode == "0" ?
                            <td>
                                <select value={props.cost.costCode} name="costCode" onChange={editCostDropdownProp}>
                                    <option value="0">Select</option>
                                    {costCodes && costCodes.map(costCode => {
                                        return <option value={costCode.code.concat(".", costCode.subCode)}>{costCode.code.concat(".", costCode.subCode)}</option>
                                    })}
                                </select>
                            </td> : <td><p>{costData[0].costCode}</p></td>}
                        <td><input name="invoiceNumber" value={invoiceNumber} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setinvoiceNumber(e.currentTarget.value)}></input></td>
                        <td>{dollarConverter(costData[0].totalCost, "$", "")}</td>
                        <td>
                            <select value={props.cost.status} name="status" onChange={editCostDropdownProp}>
                                <option value="0">Select</option>
                                <option value="Open">Open</option>
                                <option value="Approved">Approved</option>
                                <option value="Pending">Pending</option>
                                <option value="Rejected">Rejected</option>
                            </select>
                        </td>
                    </tr>
                }
            </>
            <tr>
                <td colSpan={12} >
                    <Table style={{ maxWidth: "40%", margin: "0 auto" }}>
                        <thead>
                            <tr>
                                {bottomTableHeadings.map(heading => {
                                    return <th>{heading}</th>
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {props.cost.index == "0" || 0 ?
                                <tr>
                                    <td>
                                        <input value={newRateDescription} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setnewRateDescription(e.currentTarget.value)}></input>
                                    </td>
                                    <td>
                                        <input type="number" value={newRate} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setnewRate(Number(e.currentTarget.value))}></input>
                                    </td>
                                    <td>
                                        <input value={newUnit} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setnewUnit(e.currentTarget.value)}></input>
                                    </td>
                                    <td>
                                        <input type="number" value={newQuantity} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setnewQuantity(Number(e.currentTarget.value))}></input>
                                    </td>
                                    <td>{newRate! * newQuantity! || 0}</td>
                                    <td>
                                        <button onClick={saveRate}>Save Rate</button>
                                    </td>
                                </tr> : null
                            }
                            {props.cost.rates.map((rate, index) => {
                                return <tr>
                                    <td><p >{rate.description}</p></td>
                                    <td><p>{rate.rate}</p></td>
                                    <td><p >{rate.unit}</p></td>
                                    <td><input type="number" name='numberOf' value={quantities[index]} className='editPropertyText' onChange={(e: React.ChangeEvent<HTMLInputElement>) => updateRatesNumberOfState(e, index)}  ></input></td>
                                    <td>{dollarConverter(Number(quantities[index] * rate.rate), "$", "")}</td>
                                    {props.cost.index == "0" || 0 ?
                                        <td><Trash onClick={(e: React.MouseEvent<SVGElement>) => deleteRate(e, index)} /></td> :
                                        <td></td>
                                    }
                                </tr>
                            })
                            }
                            <tr >
                                <td colSpan={bottomTableHeadings.length}>
                                    <div className='rates-button-row'>
                                        <button onClick={() => updateRates()} className='universal-small-save-button' >Save Cost</button>
                                        <button onClick={() => props.deleteCost(props.cost.id!.toString())} className='universal-small-delete-button' >Delete Cost</button>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </td>
            </tr>
        </>
    )
}
