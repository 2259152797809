import React, { useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import { months } from '../../../../dropdowns/months'
import { useGetCompaniesQuery } from '../../../../../redux/injectionEndpoints/costTracking/companiesEndpoint';
import { useGetCostCodesQuery } from '../../../../../redux/injectionEndpoints/costTracking/costCodeEndpoint';
import { useGetResourceCodesQuery } from '../../../../../redux/injectionEndpoints/costTracking/resourceCodeEndpoint';
import { useAddAccrualMutation } from '../../../../../redux/injectionEndpoints/costTracking/accrualsEndpoint';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';

const tableHeadings = [
    "Company",
    "Cost Code",
    "Resource Code",
    "Description",
    "Cost",
    "Type",
    "Accrued To Month",
    "Accrued To Year",
    ""
];

type props = {
    closeAddAccrual: () => void,
    addAccrual: boolean
}

export const AddAccrual = (props: props) => {

    const today = new Date()
    //get our existing data
    const { data: companies } = useGetCompaniesQuery();
    const { data: costCodes } = useGetCostCodesQuery();
    const { data: resourceCodes } = useGetResourceCodesQuery();
    //get our add accrual endpoint
    const [addAccrual] = useAddAccrualMutation();

    const selectedProject = useSelector((state: RootState) => state.tabs.projectTab)

    //local state for adding new accrual
    const [company, setcompany] = useState<string>("");
    const [costCode, setcostCode] = useState<string>("");
    const [resourceCode, setresourceCode] = useState<string>("");
    const [description, setdescription] = useState<string>("");
    const [cost, setcost] = useState<number>(0);
    const [type, settype] = useState<string>("");
    const [month, setmonth] = useState<string>("")

    const saveNewArrcual = () => {
        addAccrual({
            project: selectedProject,
            company,
            costCode,
            resourceCode,
            description,
            cost,
            type,
            month,
            year: today.getFullYear().toString(),
            dateAccrued: today.getFullYear().toString().concat("-", (today.getMonth() + 1).toString(), "-", today.getDate().toString())
        })
    }

    return (
        <Modal show={props.addAccrual} dialogClassName="modal-80w">
            <Modal.Header closeButton onHide={() => props.closeAddAccrual()}>
                <Modal.Title>Add Accrual</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table>
                    <thead>
                        <tr>
                            {tableHeadings.map(heading => {
                                return <th>{heading}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <select onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setcompany(e.currentTarget.value)}>
                                    <option value={0}>SELECT</option>
                                    {companies &&
                                        companies.map(company => {
                                            return <option value={company.company}>{company.company}</option>
                                        })
                                    }
                                </select>
                            </td>
                            <td>
                                <select onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setcostCode(e.currentTarget.value)}>
                                    <option value={0}>SELECT</option>
                                    {costCodes &&
                                        costCodes.map(costCode => {
                                            if (costCode.subCode !== "0" || null || undefined) {
                                                if (costCode.project == selectedProject) {
                                                    return <option value={costCode.code.concat(".", costCode.subCode)}>{costCode.code.concat(".", costCode.subCode, " - ", costCode.subCodeName)}</option>
                                                }
                                            }
                                        })
                                    }
                                </select>
                            </td>
                            <td>
                                <select onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setresourceCode(e.currentTarget.value)}>
                                    <option value={0}>SELECT</option>
                                    {resourceCodes &&
                                        resourceCodes.map(resourceCode => {
                                            if (resourceCode.project == selectedProject) {
                                                return <option value={resourceCode.code}>{resourceCode.code}</option>
                                            }
                                        })
                                    }
                                </select>
                            </td>
                            <td><input onChange={(e: React.ChangeEvent<HTMLInputElement>) => setdescription(e.currentTarget.value)}></input></td>
                            <td><input type="number" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setcost(Number(e.currentTarget.value))}></input></td>
                            <td>
                                <select onChange={(e: React.ChangeEvent<HTMLSelectElement>) => settype(e.currentTarget.value)}>
                                    <option value={0}>SELECT</option>
                                    <option value="invoice">Invoice</option>
                                    <option value="forecast">Forecast</option>
                                    <option value="estimate">Estimate</option>
                                    <option value="costTracker">Cost Tracker</option>
                                </select>
                            </td>
                            <td>
                                <select onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setmonth(e.currentTarget.value)}>
                                    <option value={0}>SELECT</option>
                                    {months.map(month => {
                                        return <option value={month.value}>{month.month}</option>
                                    })}
                                </select>
                            </td>
                            <td>{new Date().getFullYear().toString()}</td>
                            <td><button className='universal-small-save-button' onClick={() => saveNewArrcual()}>Add Accrual</button></td>
                        </tr>
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <button className='universal-close-button' onClick={() => props.closeAddAccrual()}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}
