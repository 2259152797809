import React from 'react'

type props = {
    changeStateFunction: (date:string) => void
}

export const Date = (props: props) => {
    const [date, setdate] = React.useState<string>("") //date for the new cost

    const handleDateChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        setdate(e.target.value);
        props.changeStateFunction(e.target.value)
    }

    return (
        <div className="date-input-container">
            <input type="date" className="date-input" onChange={handleDateChange} value={date} />
            <span className="date-icon">📅</span>
        </div>
    )
}
