import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { Modal } from 'react-bootstrap';
import { useGetAccrualsQuery } from '../../../../redux/injectionEndpoints/costTracking/accrualsEndpoint';
import { utils, writeFile } from 'xlsx';
import { months } from '../../../dropdowns/months';
import { getAccrualYearRange } from '../../../../functions/costTracking/getAccrualYearRange';
import { useGetProjectsQuery } from '../../../../redux/injectionEndpoints/costTracking/projectsEndpoint';
import { accrualsToAoAs } from '../../../../functions/costTracking/accrualsToAoAs';

type props = {
    closeDownloadAccruals: () => void,
    downloadAccruals: boolean
}

export const DownloadAccruals = (props: props) => {

    const selectedProject = useSelector((state: RootState) => state.tabs.projectTab)

    const [monthToView, setmonthToView] = React.useState<string>((new Date().getMonth() + 1).toString());
    const [yearToView, setyearToView] = React.useState<string>(new Date().getFullYear().toString());

    const { data: projectData } = useGetProjectsQuery()

    const { accrualData } = useGetAccrualsQuery(undefined, {
        selectFromResult: ({ data }) => ({
            accrualData: data?.filter(accrual => accrual.project === selectedProject)
                .filter(accrual => {
                    if (monthToView === "0") {
                        return true
                    } else return accrual.month === monthToView
                })
                .filter(accrual => {
                    if (yearToView === "0") {
                        return true
                    } else return accrual.year === yearToView
                })
        })
    });

    const downloadAccrualsAndClose = () => {
        props.closeDownloadAccruals();

        const headings = ["Date Accrued", "Cost Code", "Resource Code", "Company", "Description", "Cost", "Type"];

        const dataToDownload = accrualsToAoAs(accrualData)

        if (typeof dataToDownload !== "undefined" && dataToDownload.length > 0 && Array.isArray(dataToDownload)) {
            const workbook = utils.book_new(); //create a new workbook
            const worksheet = utils.aoa_to_sheet([[...headings], ...dataToDownload]) //create a new worksheet with our required headings as the first and only row
            utils.book_append_sheet(workbook, worksheet, "Accruals".concat(projectData ? projectData.find(project => project._id! === selectedProject)!.projectCode : "No Project")) //append the sheet to the workbook
            writeFile(workbook, "Accruals.xlsx") //download the file when the user elects to add from spreadsheet
        }
    }

    return (
        <Modal show={props.downloadAccruals} dialogClassName="modal-80w">
            <Modal.Header closeButton onHide={() => props.closeDownloadAccruals()}>
                <Modal.Title>Download Accruals</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="download-accruals-select-date-range">
                    <p>Download Accruals for: </p>
                    <div className="accruals-select-date-range-selectRow">
                        <p>Month</p>
                        <select value={monthToView} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setmonthToView(e.target.value)} >
                            <option value={0}>All Months</option>
                            {months.map(month => {
                                return <option value={month.value}>{month.month}</option>
                            })}
                        </select>
                    </div>
                    <div className="accruals-select-date-range-selectRow">
                        <p>Year</p>
                        <select value={yearToView} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setyearToView(e.target.value)}>
                            <option value={0}>All Years</option>
                            {getAccrualYearRange(accrualData).map(year => {
                                return <option value={year}>{year}</option>
                            })}
                        </select>
                    </div>
                    <p>Downloading <b>{accrualData &&
                        accrualData?.length}</b> accruals for: </p>
                    <p><b>For period:</b> {monthToView === "0" ? "All Months" : months.find(month => month.value.toString() === monthToView)?.month}, {yearToView === "0" ? "All Years" : yearToView}</p>
                    <p><b>On project:</b> {projectData && projectData.find(project => project._id! === selectedProject)?.projectCode.concat(" - ", projectData.find(project => project._id! === selectedProject)!.projectName)}</p>
                </div>
            </Modal.Body>
            <Modal.Footer>
                {accrualData && accrualData.length > 0 && <button onClick={() => downloadAccrualsAndClose()}>Download</button>}
            </Modal.Footer>
        </Modal>
    )
}
