import React from 'react';
import { Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import isEmail from 'validator/lib/isEmail';
import { useForgotPasswordMutation, useResetPasswordMutation } from '../../redux/injectionEndpoints/auth/authEndpoints';

export const ForgotPassword = () => {

    const navigate = useNavigate();

    const [createForgotPasswordToken] = useForgotPasswordMutation();
    const [submitNewPasswordWithToken] = useResetPasswordMutation();

    const [sendEmailCode, setsendEmailCode] = React.useState<boolean>(true)
    const [email, setemail] = React.useState<string>('')

    const [token, setToken] = React.useState<string>('');
    const [newPassword, setNewPassword] = React.useState<string>('');
    const [confirmPassword, setConfirmPassword] = React.useState<string>('');
    const [message, setMessage] = React.useState<string>('');

    const [emailErrorMessage, setemailErrorMessage] = React.useState<string>('')

    //for once token has been requested and user enters token along with new password to the form
    const changePassword = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (newPassword !== confirmPassword) {
            setMessage('Passwords do not match.');
            return;
        }

        try {
            //CODE TO REACH BACKEND
            submitNewPasswordWithToken({
                token,
                newPassword
            })
                .unwrap()
                .then((resp) => {
                    console.log(resp.message)
                    if (resp.success) {
                        setToken('');
                        setNewPassword('');
                        setConfirmPassword('')
                        setMessage(resp.message)
                        return
                    } else {
                        setNewPassword('');
                        setConfirmPassword('');
                        setMessage(resp.message)
                        return
                    }
                })
                .catch((err) => {
                    console.log('something bad has happened')
                    return
                })
        } catch (error) {
            setMessage('An error occurred while resetting the password.');
            console.error(error);
        }
    };

    //for when user requests new password by putting in their email address.
    const handleRequestNewPassword = async () => {
        //check if email is valid. only hit backend if valid email format.
        if (isEmail(email)) {
            createForgotPasswordToken(email)
                .unwrap()
                .then((resp) => {
                    setMessage("A reset token has been sent to the email address entered. Please enter this token to the above form, along with your new password")
                })
                .catch(err => {
                    return
                })
            setsendEmailCode(false)
            setemailErrorMessage("Email OK")
            setemail('')
            setToken('');
            setNewPassword('');
            setConfirmPassword('')
        } else {
            return setemailErrorMessage("Please enter a valid email")
        }
    }

    return (
        <div>
            <h2 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: '1em' }}>Reset Password</h2>
            <div className='forgot-password-primary-container'>
                <form onSubmit={changePassword} className='forgot-password-submit-new-password-form'>
                    <label htmlFor="token">Reset Token:</label>
                    <input
                        type="text"
                        id="token"
                        value={token}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setToken(e.target.value)}
                        required
                    />
                    <label htmlFor="newPassword">New Password:</label>
                    <input
                        type="password"
                        id="newPassword"
                        value={newPassword}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setNewPassword(e.target.value)}
                        required
                    />
                    <label htmlFor="confirmPassword">Confirm Password:</label>
                    <input
                        type="password"
                        id="confirmPassword"
                        value={confirmPassword}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setConfirmPassword(e.target.value)}
                        required
                    />
                    <button type="submit" style={{ columnSpan: 'all' }}>Reset Password</button>
                    <button onClick={() => setsendEmailCode(true)} style={{ columnSpan: 'all' }}>Re-enter Email Address</button>
                </form>
                {message && <p>{message}</p>}
                <h6>OR</h6>
                <button onClick={() => navigate('/')}>Back to Login Page</button>
            </div>
            <Modal show={sendEmailCode} >
                <Modal.Header closeButton onHide={() => setsendEmailCode(false)}>
                    <Modal.Title>Send Verification Code</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form className="forgot-password-enter-email-form">
                        <label htmlFor='emailAddress'>Enter Email Address: </label>
                        <input type='email' id='emailAddress' value={email} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setemail(e.target.value)}></input>
                    </form>
                    <p style={{ textAlign: 'right', width: '100%', margin: "0.5em 0 0em 0", color: 'red' }}>{emailErrorMessage}</p>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => handleRequestNewPassword()}>Send Verification Code</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
