import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { RootState } from '../store';

var url = process.env.NODE_ENV === "production" ? "https://mdjv-cost-tracker-trial.herokuapp.com/api/" : "http://localhost:3000/api";

export const API = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({ 
        baseUrl: url,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).user.userProfile?.token

            if (token) {
                headers.set('authorization', `Bearer ${token}`)
            }
            return headers
        }
     }),
    endpoints: () => ({}) //we use injection enpoints in individual files to add to this
});