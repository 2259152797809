import React from 'react';
import { Modal, Table } from 'react-bootstrap';
import { useAddOngoingCostMutation } from '../../../../../redux/injectionEndpoints/costTracking/ongoingCostsEndpoint';
import { costRate, ongoingCost } from '../../../../../types/ongoingCosts';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { useGetIndexQuery, useGetIndexesQuery } from '../../../../../redux/injectionEndpoints/costTracking/indexEndpoint';
import { useGetCompaniesQuery } from '../../../../../redux/injectionEndpoints/costTracking/companiesEndpoint';
import { useGetResourceCodesQuery } from '../../../../../redux/injectionEndpoints/costTracking/resourceCodeEndpoint';
import { useGetCostCodesQuery } from '../../../../../redux/injectionEndpoints/costTracking/costCodeEndpoint';
import { dollarConverter } from '../../../../../functions/dollarConverter';
import { Trash3 } from 'react-bootstrap-icons';

const topTableHeadings = [
    "Index",
    "Start Date",
    "End Date",
    "Cost Code",
    "Resource Code",
    "Docket Number",
    "Purchase Order",
    "Company",
    "Description",
    "Invoice Number",
    "Frequency",

];

const bottomTableHeadings = [
    "Description",
    "Rate",
    "Unit",
    "Quantity",
    "Sub-Total",
    ""
];

type props = {
    closeAddOngoingCosts: () => void,
    addOngoingCosts: boolean
}

export const AddOngoingCost = (props: props) => {

    const selectedProject = useSelector((state: RootState) => state.tabs.projectTab);

    const [index, setindex] = React.useState<string>("0");//default is no index selected. This means we render our inputs rather than index fields for the new cost

    const { indexesData } = useGetIndexesQuery(undefined, {
        selectFromResult: ({ data }) => ({
            indexesData: data?.filter(index => index.project === selectedProject)
        })
    }); //for retrieving all indexes;
    const { data: indexData, refetch: indexRefetch } = useGetIndexQuery(index, { refetchOnMountOrArgChange: true }); //for retrieving an index
    const { costCodes } = useGetCostCodesQuery(undefined, {
        selectFromResult: ({ data }) => ({
            costCodes: data?.filter(costCode => costCode.project === selectedProject)
        })
    }); //for retrieving all resource codes
    const { resourceCodes } = useGetResourceCodesQuery(undefined, {
        selectFromResult: ({ data }) => ({
            resourceCodes: data?.filter(resourceCode => resourceCode.project === selectedProject)
        })
    }); //for retrieving all resource codes
    const { data: companies } = useGetCompaniesQuery();

    const [addOngoingCost] = useAddOngoingCostMutation();

    //adding rates to the cost
    const [rates, setrates] = React.useState<costRate[]>([])
    const [description, setdescription] = React.useState<string>("") //new rate to save
    const [rate, setrate] = React.useState<number>(0) //new rate to save
    const [unit, setunit] = React.useState<string>("") //new rate to save
    const [numberOf, setnumberOf] = React.useState<number>(0) //new rate to save

    const [indexQuantities, setindexQuantities] = React.useState<number[]>([0]) //quantities array for indexed costs
    const [indexRates, setindexRates] = React.useState<costRate[]>([]);
    const [indexTotals, setindexTotals] = React.useState<number[]>([0]) //array with rates total costs

    const saveRate = (e: React.MouseEvent<HTMLButtonElement>) => { //save new rate from add rate option
        e.preventDefault();
        var newRate: costRate = {
            description,
            numberOf,
            unit,
            rate,
            valueOf: rate * numberOf
        }
        setrates([...rates, newRate])
        setnewOngoingCost({ ...newOngoingCost, totalCost: newOngoingCost.totalCost + rate * numberOf, rates: [...rates, newRate] })
    };

    const deleteRate = (e: React.MouseEvent<SVGElement>) => { //delete rate from the add rate option
        e.preventDefault();
        const newRates = rates!.filter(rate => rate.description != e.currentTarget.id)
        setrates(newRates)
        setnewOngoingCost({ ...newOngoingCost, totalCost: newRates.map(rate => rate.valueOf).reduce((prev, curr) => prev + curr, 0), rates: newRates })
    };

    const [newOngoingCost, setnewOngoingCost] = React.useState<ongoingCost>({
        index: index,
        project: selectedProject,
        date: "",
        endDate: "",
        costCode: "",
        resourceCode: "",
        description: "",
        company: "",
        rates: rates,
        totalCost: 0,
        docketNumber: "N/A",
        purchaseOrder: "N/A",
        invoiceNumber: 'N/A',
        comments: "",
        frequency: "daily",
    });

    const updateNewCost = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        indexRefetch();
        setnewOngoingCost({ ...newOngoingCost, [e.target.id]: e.target.value })
        if (e.target.id === "index") {
            setindex(e.target.value)
            indexRefetch();
        }
    };

    const setNewCostRates = (e: React.ChangeEvent<HTMLInputElement>, index: number, rate: number) => { //used to update rates when index not = 0 is selected
        var newIndexQuantities = [...indexQuantities]; //create new dummy rates variable
        var newindexTotals = [...indexTotals] //create new dummy totals variable

        newIndexQuantities[index] = Number(e.target.value) //update dummy rates variable based on changes in UI
        newindexTotals[index] = Number(e.target.value) * rate

        setindexQuantities(newIndexQuantities); //reset indexRates
        setindexTotals(newindexTotals); //reset totals
    };

    const saveCost = (e: React.MouseEvent<HTMLButtonElement>) => {
        if (index !== "0") { //save cost based on index values
            const costRates: costRate[] = indexData!.rates.map((rate, index) => ({ //set new rates for the new cost based on UI inputs and indexData
                description: rate.rateDescription!,
                rate: rate.rate!,
                unit: rate.unit!,
                numberOf: indexQuantities[index] ? indexQuantities[index] : 0,
                valueOf: indexQuantities[index] ? rate.rate! * indexQuantities[index] : 0
            }));

            setindexRates(costRates)

            addOngoingCost({
                index,
                project: newOngoingCost.project,
                date: newOngoingCost.date,
                endDate: newOngoingCost.endDate,
                costCode: indexData!.subCostCode!,
                resourceCode: indexData!.resourceCode!,
                description: indexData!.description!,
                company: indexData!.company!,
                rates: costRates,
                docketNumber: newOngoingCost.docketNumber,
                purchaseOrder: newOngoingCost.purchaseOrder,
                invoiceNumber: newOngoingCost.invoiceNumber,
                totalCost: costRates!.map(rate => rate.valueOf).reduce((prev, curr) => prev + curr, 0),
                frequency: newOngoingCost.frequency,
            })
            setrates([])
            setdescription("")
            setrate(0)
            setunit("")
            setnumberOf(0)
        }
        else {
            addOngoingCost(newOngoingCost)
            setrates([])
            setdescription("")
            setrate(0)
            setunit("")
            setnumberOf(0)
        }
    }

    return (
        <Modal show={props.addOngoingCosts} dialogClassName="modal-80w">
            <Modal.Header closeButton onHide={() => props.closeAddOngoingCosts()}>
                <Modal.Title>Add Ongoing Cost</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table>
                    <thead>
                        <tr>
                            {topTableHeadings.map(heading => {
                                return <th>{heading}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <select id="index" onChange={updateNewCost} style={{ maxWidth: "70px" }}>
                                    <option value={"0"}>0</option>
                                    {indexesData?.map(index => {
                                        return <option value={index.id!}>{index.id}</option>
                                    })}
                                </select>
                            </td>
                            <td><input type="date" id="date" style={{ maxWidth: "100px" }} onChange={updateNewCost}></input></td>
                            <td><input type="date" id="endDate" style={{ maxWidth: "100px" }} onChange={updateNewCost}></input></td>
                            {index === "0" ?
                                <td>
                                    <select id="costCode" onChange={updateNewCost}>
                                        <option value="">Select</option>
                                        {costCodes?.map(costCode => {
                                            return <option value={costCode.code.concat(".", costCode.subCode)}>{costCode.code.concat(".", costCode.subCode)}</option>
                                        })}
                                    </select>
                                </td>
                                :
                                <td>
                                    {indexData ? <p>{indexData!.subCostCode}</p> : <p>No data</p>}
                                </td>
                            }
                            {index === "0" ?
                                <td>
                                    <select id="resourceCode" style={{ maxWidth: "80px" }} onChange={updateNewCost}>
                                        <option value="">Select</option>
                                        {resourceCodes?.map(resourceCode => {
                                            return <option value={resourceCode.code}>{resourceCode.code}</option>
                                        })}
                                    </select>
                                </td>
                                :
                                <td>
                                    {indexData ? <p>{indexData!.resourceCode}</p> : <p>No data</p>}
                                </td>
                            }
                            <td><input type="string" id="docketNumber" onChange={updateNewCost}></input></td>
                            <td><input type="string" id="purchaseOrder" onChange={updateNewCost}></input></td>
                            {index === "0" ?
                                <td>
                                    <select id="company" onChange={updateNewCost}>
                                        <option value="0">Select</option>
                                        {companies && companies.map(company => {
                                            return <option value={company.company}>{company.company}</option>
                                        })}
                                    </select>
                                </td>
                                :
                                <td>
                                    {indexData ? <p>{indexData!.company}</p> : <p>No data</p>}
                                </td>
                            }
                            {index === "0" ?
                                <td><input type="string" id="description" onChange={updateNewCost}></input></td>
                                :
                                <td>
                                    {indexData ? <p>{indexData!.description}</p> : <p>No data</p>}
                                </td>
                            }
                            <td><input type="string" id="invoiceNumber" onChange={updateNewCost}></input></td>
                            <td>
                                <select id="frequency" onChange={updateNewCost}>
                                    <option value="daily">Daily</option>
                                    <option value="weekly">Weekly</option>
                                    <option value="fortnightly">Fortnightly</option>
                                    <option value="monthly">Monthly</option>
                                </select>
                            </td>
                        </tr>
                    </tbody>
                </Table>
                <hr></hr>
                <div id="add-cost-flex-column">
                    {
                        index == "0" ?
                            <Table style={{ maxWidth: "40%" }}>
                                <thead>
                                    <tr>
                                        {bottomTableHeadings.map(heading => {
                                            return <th>{heading}</th>
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><input value={description} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setdescription(e.currentTarget.value)}></input></td>
                                        <td><input value={rate} type="number" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setrate(Number(e.currentTarget.value))}></input></td>
                                        <td><input value={unit} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setunit(e.currentTarget.value)}></input></td>
                                        <td><input value={numberOf} type="number" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setnumberOf(Number(e.currentTarget.value))} min={0} ></input></td>
                                        <td>{dollarConverter(rate * numberOf, "$", "")}</td>
                                        <td><button className='universal-small-save-button' onClick={saveRate}>Save Rate</button></td>
                                    </tr>
                                    {rates.map(rate => {
                                        return <tr>
                                            <td>{rate.description}</td>
                                            <td>{rate.rate}</td>
                                            <td>{rate.unit}</td>
                                            <td>{rate.numberOf}</td>
                                            <td>{rate.valueOf}</td>
                                            <td><Trash3 id={rate.description} onClick={deleteRate}>Delete</Trash3></td>
                                        </tr>
                                    })}
                                    <tr>
                                        <th colSpan={bottomTableHeadings.length - 2}>TOTAL</th>
                                        <th>{
                                            dollarConverter(rates.map(rate => rate.valueOf).reduce((prev, curr) => prev + curr, 0), "$", "") 
                                        }</th>
                                    </tr>
                                </tbody>
                            </Table>
                            :
                            <Table style={{ maxWidth: "40%" }}>
                                <thead>
                                    <tr>
                                        {bottomTableHeadings.map(heading => {
                                            return <th>{heading}</th>
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {indexData && indexData!.rates.map((rate, index) => {
                                        return <tr>
                                            <td>{rate.rateDescription}</td>
                                            <td>{rate.rate}</td>
                                            <td>{rate.unit}</td>
                                            <td><input type="number" id={rate.rateDescription} style={{ maxWidth: "80px" }} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setNewCostRates(e, index, rate.rate!) }} min={0} ></input></td>
                                            <td>{dollarConverter(indexQuantities[index] ? indexQuantities[index] * rate.rate! : 0, "$", "")}</td>
                                        </tr>
                                    })
                                    }
                                    <tr>
                                        <th colSpan={bottomTableHeadings.length - 2}>TOTAL</th>
                                        <th>{
                                            dollarConverter(indexTotals.map(total => (total)).reduce((prev, curr) => prev + curr), "$", "")
                                        }</th>
                                    </tr>
                                </tbody>
                            </Table>
                    }
                </div>
                <span id="save-cost-button" >
                    <button className='universal-save-button' onClick={saveCost}>Save Ongoing Cost</button>
                </span>
            </Modal.Body>
            <Modal.Footer>
                <button className='universal-close-button' onClick={() => props.closeAddOngoingCosts()}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}
