import React, { useState } from 'react';
import { Modal, Table } from 'react-bootstrap'
import { dollarConverter } from '../../../../../functions/dollarConverter';
import { useEditAccrualMutation } from '../../../../../redux/injectionEndpoints/costTracking/accrualsEndpoint';
import { useGetCompaniesQuery } from '../../../../../redux/injectionEndpoints/costTracking/companiesEndpoint';
import { useGetCostCodesQuery } from '../../../../../redux/injectionEndpoints/costTracking/costCodeEndpoint';
import { useGetResourceCodesQuery } from '../../../../../redux/injectionEndpoints/costTracking/resourceCodeEndpoint';
import { accrual } from '../../../../../types/accruals';
import { months } from '../../../../dropdowns/months';

export const EditAccrual = (props: accrual) => {

    const { data: costCodes } = useGetCostCodesQuery();
    const { data: resourceCodes } = useGetResourceCodesQuery();
    const { data: companies } = useGetCompaniesQuery();

    const [editAccrual] = useEditAccrualMutation();

    const [description, setdescription] = useState<string>(props.description)
    const [cost, setcost] = useState<number>(props.cost)
    const [type, settype] = useState<string>(props.type)

    const [accrualMonth, setaccrualMonth] = useState<string>(props.month)
    const [accrualYear, setaccrualYear] = useState<string>(props.year)

    const [accrualMonthModal, setaccrualMonthModal] = useState<boolean>(false);

    const editAccrualDropdown = (e: React.ChangeEvent<HTMLSelectElement>) => {
        editAccrual({id: props.id!, field: e.target.name, value: e.target.value})
    }

    const editAccrualProp = (e:React.MouseEvent<HTMLButtonElement>) => {
        editAccrual({id: props.id!, field: "description", value: description});
        editAccrual({id: props.id!, field: "cost", value: cost});
    }


    return (
        <>
            {accrualMonthModal &&
                <Modal show={true} centered size='sm'>
                    <Modal.Header closeButton onHide={() => setaccrualMonthModal(false)}>
                        <Modal.Title>Set Accrual Month</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h4>Month</h4>
                        <select style={{ width: "100%" }} value={accrualMonth} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setaccrualMonth(e.target.value)} >
                            <option value={0}>SELECT</option>
                            {months.map(month => {
                                return <option value={month.value}>{month.month}</option>
                            })}
                        </select>
                        <h4>Year</h4>
                        <select style={{ width: "100%" }} value={accrualYear} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setaccrualYear(e.target.value)}>
                            <option value={(Number(accrualYear) - 1).toString()}>{Number(accrualYear) - 1}</option>
                            <option value={accrualYear}>{accrualYear}</option>
                            <option value={(Number(accrualYear) + 1).toString()}>{Number(accrualYear) + 1}</option>
                            <option value={(Number(accrualYear) + 2).toString()}>{Number(accrualYear) + 2}</option>
                        </select>
                    </Modal.Body>
                </Modal>
            }
            <tr>
                <td><button className='universal-small-save-button' onClick={editAccrualProp}>Save</button></td>
                <td>
                    <select name="company" value={props.company} onChange={editAccrualDropdown}>
                        {companies && companies.map(company => {
                            return <option value={company.company}>{company.company}</option>
                        })}
                    </select>
                </td>
                <td>
                    <select  name="costCode" value={props.costCode} onChange={editAccrualDropdown}>
                        {costCodes && costCodes.map(costCode => {
                            return <option value={costCode.code.concat(".", costCode.subCode)}>{costCode.code.concat(".", costCode.subCode)}</option>
                        })}
                    </select>
                </td>
                <td>
                    <select  name="resourceCode" value={props.resourceCode} onChange={editAccrualDropdown}>
                        {resourceCodes && resourceCodes.map(resourceCode => {
                            return <option value={resourceCode.code}>{resourceCode.code}</option>
                        })}
                    </select>
                </td>
                <td><input  name="description" value={description} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setdescription(e.target.value)}></input></td>
                <td><input  name="cost" value={Number(cost)} type="number" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setcost(Number(e.target.value))}></input></td>
                <td>
                    <select  name="type" value={props.type} onChange={editAccrualDropdown}>
                        <option value={0}>SELECT</option>
                        <option value="invoice">Invoice</option>
                        <option value="forecast">Forecast</option>
                        <option value="estimate">Estimate</option>
                        <option value="cost tracker">Cost Tracker</option>
                    </select>
                </td>
                <td  onClick={() => setaccrualMonthModal(true)}>{accrualMonth.length === 1 ? "0".concat(accrualMonth, " - ", accrualYear) : accrualMonth.concat(" - ", accrualYear)}</td>
            </tr>
        </>
    )
}
