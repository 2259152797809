import { editObject } from "../../../types/edit";
import { API } from "../../APIs/moduleAPI";
import { accrual } from "../../../types/accruals";

//this is how we 
export const extendedAccrualsAPI = API
    .enhanceEndpoints({ addTagTypes: ['Accrual' as const] })
    .injectEndpoints({
        endpoints: builder => ({
            getAccruals: builder.query<accrual[], void>({ //retrieve all accruals from db
                query: () => {
                    return {
                        url: 'costTracking/getAllAccruals',
                        method: 'GET'
                    }
                },
                providesTags: ["Accrual"]
            }),
            getAccrual: builder.query<accrual, string>({//retrieve individual accrual from db
                query(id) {
                    return {
                        url: `costTracking/getAccrual/${id}`,
                        method: "GET"
                    }
                },
                providesTags: ["Accrual"]
            }),
            addAccrual: builder.mutation<accrual,accrual>({
                query(accrual) {
                    return {
                        url: 'costTracking/createNewAccrual',
                        method: "POST",
                        body: accrual
                    }
                },
                invalidatesTags: ["Accrual"]
            }),
            deleteAccrual: builder.mutation<accrual, string>({
                query(id) {
                    return {
                        url: `costTracking/deleteAccrual/${id}`,
                        method: 'DELETE'
                    }
                },
                invalidatesTags: ["Accrual"]
            }),
            editAccrual: builder.mutation<accrual, editObject>({
                query(editObject){
                    return {
                        url: `costTracking/editAccrual/${editObject.id}`,
                        method: "POST",
                        body: editObject
                    }
                },
                invalidatesTags: ["Accrual"]
            })
        }),
        overrideExisting: false
    });

export const {
    useGetAccrualsQuery,
    useGetAccrualQuery,
    useAddAccrualMutation,
    useDeleteAccrualMutation,
    useEditAccrualMutation
} = extendedAccrualsAPI