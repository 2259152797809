import { company } from "../../../types/company";
import { editObject } from "../../../types/edit";
import { API } from "../../APIs/moduleAPI";

//this is how we 
export const extendedCompaniesAPI = API
    .enhanceEndpoints({ addTagTypes: ['Company' as const] })
    .injectEndpoints({
        endpoints: builder => ({
            getCompanies: builder.query<company[], void>({ //retrieve all companies from db
                query: () => {
                    return {
                        url: 'costTracking/getAllCompanies',
                        method: 'GET'
                    }
                },
                providesTags: ["Company"]
            }),
            getCompany: builder.query<company, string>({//retrieve individual company from db
                query(id) {
                    return {
                        url: `costTracking/getCompany/${id}`,
                        method: "GET"
                    }
                },
                providesTags: ["Company"]
            }),
            addCompany: builder.mutation<company,company>({
                query(company) {
                    return {
                        url: 'costTracking/createNewCompany',
                        method: "POST",
                        body: company
                    }
                },
                invalidatesTags: ["Company"]
            }),
            deleteCompany: builder.mutation<company, string>({
                query(id) {
                    return {
                        url: `costTracking/deleteCompany/${id}`,
                        method: 'DELETE'
                    }
                },
                invalidatesTags: ["Company"]
            }),
            editCompany: builder.mutation<company, editObject>({
                query(editObject){
                    return {
                        url: `costTracking/editCompany/${editObject.id}`,
                        method: "POST",
                        body: editObject
                    }
                },
                invalidatesTags: ["Company"]
            })
        }),
        overrideExisting: false
    });

export const {
    useGetCompaniesQuery,
    useGetCompanyQuery,
    useAddCompanyMutation,
    useDeleteCompanyMutation,
    useEditCompanyMutation
} = extendedCompaniesAPI