import { cost } from "../../types/cost";

export const resourceCodeCostSummary = (costs: cost[] | undefined, resourceCode: string): number => {
    var finalCost = 0;

    costs && costs.forEach(cost => {
        if (cost.resourceCode === resourceCode) {
            finalCost += cost.totalCost
        }
    })

    return finalCost
}

export const monthlyResourceCodeCostSummary = (costs: cost[] | undefined, resourceCode: string): number => {
    var finalCost = 0;

    costs && costs.forEach(cost => {
        if (cost.resourceCode === resourceCode) {
            const today = new Date()
            if (Number(cost.date.split("-")[1]) === (today.getMonth() + 1)) {
                finalCost += cost.totalCost
            }
        }
    })

    return finalCost
}