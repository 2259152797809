import React from 'react';
import Table from 'react-bootstrap/Table';
import { CaretLeftFill, CaretRightFill } from 'react-bootstrap-icons';
import { useSelector } from 'react-redux';
import { useDeleteOngoingCostMutation, useGetOngoingCostsQuery } from '../../../../redux/injectionEndpoints/costTracking/ongoingCostsEndpoint';
import { RootState } from '../../../../redux/store';
import { AddOngoingCost } from './add items/AddOngoingCost';
import { EditOngoingCost } from './editItems/EditOngoingCost';
import { dollarConverter } from '../../../../functions/dollarConverter';

export const tableHeadings = [
    "Select",
    "Index",
    "Start Date",
    "End Date",
    "Resource Code",
    "Docket Number",
    "Purchase Order",
    "Company",
    "Description",
    "Cost Code",
    "Invoice Number",
    "Total Cost",
    "Frequency"
];

export const OngoingCosts = () => {

    const selectedProject = useSelector((state: RootState) => state.tabs.projectTab)

    const [page, setpage] = React.useState<number>(0);
    const [costsPerPage, setcostsPerPage] = React.useState<number>(40) //indicate how many costs to show per page

    const [addOngoingCost, setaddOngoingCost] = React.useState<boolean>(false)
    const [editOngoingCost, seteditOngoingCost] = React.useState<boolean>(false)

    const [checkedOngoingCost, setcheckedOngoingCost] = React.useState<string>("") //indicate which cost has been selected

    const { ongoingCostsData, totalOngoingCostsLength } = useGetOngoingCostsQuery(undefined, {
        selectFromResult: ({ data }) => ({
            ongoingCostsData: data?.filter(ongoingCost => ongoingCost.project === selectedProject)
                .slice(page * costsPerPage, (page * costsPerPage) + costsPerPage),
            totalOngoingCostsLength: data?.length
        })
    })

    const [deleteOngoingCost] = useDeleteOngoingCostMutation()

    const pageUp = (e: React.MouseEvent<SVGElement>) => { //set the page to go up
        let numberOfPages
        if (totalOngoingCostsLength) {
            numberOfPages = Math.ceil(totalOngoingCostsLength / costsPerPage)
            if (page < numberOfPages) setpage(prevPage => {
                if (prevPage + 3 > Math.ceil(totalOngoingCostsLength / costsPerPage)) {
                    document.getElementById("costs-page-nagivator-top-right")!.style.color = "white" //white out the arrow. no position change but no visible
                    document.getElementById("costs-page-nagivator-top-right")!.style.cursor = "auto" //no cursor if going over white arrow
                    document.getElementById("costs-page-nagivator-bottom-right")!.style.color = "white" //white out the arrow. no position change but no visible
                    document.getElementById("costs-page-nagivator-bottom-right")!.style.cursor = "auto" //no cursor if going over white arrow
                }
                return prevPage + 1
            })
        }
    }
    const pageDown = (e: React.MouseEvent<SVGElement>) => { //set the page to go down
        if (page > 0) setpage(prevPage => {
            if (totalOngoingCostsLength && prevPage < Math.ceil(totalOngoingCostsLength / costsPerPage)) {
                document.getElementById("costs-page-nagivator-top-right")!.style.color = "black"
            }
            return page - 1
        })
    }

    const deleteCost = (e: React.MouseEvent<HTMLSpanElement>) => {
        e.preventDefault();
        deleteOngoingCost(checkedOngoingCost)
        setcheckedOngoingCost("");
    };

    const manageChecks = (e: React.ChangeEvent<HTMLInputElement>) => {
        (document.getElementsByName(`${checkedOngoingCost}`) as NodeListOf<HTMLInputElement>).forEach(element => element.checked = false)
        if (e.currentTarget.checked) { //if box is checked
            setcheckedOngoingCost(e.target.name); //add new cost to checked cost array
        }
        else setcheckedOngoingCost("") //else if box is unchecked, set to empty string
    };

    const closeAddOngoingCosts = () => {
        setaddOngoingCost(false);
    }

    const resetCheckedCost = () => { setcheckedOngoingCost("") }

    return (
        <div>
            <div className="costs-heading">
                <div className="modal-subTabs">
                    {!addOngoingCost && <span onClick={() => { setaddOngoingCost(true); seteditOngoingCost(false) }} className="modal-subTabs-item"><button className='universal-save-button'>Add Ongoing Cost</button></span>
                    }
                    {checkedOngoingCost.length > 0 && <span className="modal-subTabs-item" onClick={deleteCost}><button className='universal-delete-button'>Delete Ongoing Cost</button></span>}
                </div>
                <div className="page-arrows-container">
                    {page > 0 && <CaretLeftFill onClick={pageDown} style={{ cursor: "pointer" }} />}
                    <p >Page: {page + 1}</p>
                    {ongoingCostsData && totalOngoingCostsLength && <CaretRightFill id="costs-page-nagivator-top-right" onClick={pageUp} style={{ cursor: "pointer" }} />}
                </div>
            </div>
            {addOngoingCost && <AddOngoingCost closeAddOngoingCosts={closeAddOngoingCosts} addOngoingCosts={addOngoingCost} />}
            <div className="costCodes-modal-pageSplit-column">
                <br></br>
                {<Table className="generic-table-class" bordered hover>
                    <thead>
                        <tr>
                            {tableHeadings.map(heading => {
                                return <th>{heading}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {ongoingCostsData && ongoingCostsData?.map((cost, index) => {
                            return <>
                                <tr key={index} >
                                    <td><input name={cost.id} type="checkbox" className="tableCheckbox" onChange={manageChecks} ></input></td>
                                    <td>{cost.index}</td>
                                    <td>{cost.date}</td>
                                    <td>{cost.endDate}</td>
                                    <td>{cost.resourceCode}</td>
                                    <td>{cost.docketNumber}</td>
                                    <td>{cost.purchaseOrder}</td>
                                    <td>{cost.company}</td>
                                    <td>{cost.description}</td>
                                    <td>{cost.costCode}</td>
                                    <td>{cost.invoiceNumber}</td>
                                    <td>{dollarConverter(cost.totalCost, "$", "")}</td>
                                    <td>{cost.frequency}</td>
                                </tr>
                                {checkedOngoingCost == cost.id ? <>
                                    <>
                                        <EditOngoingCost ongoingCost={ongoingCostsData.find(costToFind => costToFind.id === cost.id)!}resetCheckedCost={resetCheckedCost} />
                                    </>
                                    <br></br>
                                </> : null
                                }
                            </>

                        })}
                    </tbody>
                </Table>}
            </div>
            <div className="page-arrows-container-bottom">
                {page > 0 && <CaretLeftFill onClick={pageDown} style={{ cursor: "pointer" }} />}
                <p >Page: {page + 1}</p>
                {ongoingCostsData && totalOngoingCostsLength && <CaretRightFill id="costs-page-nagivator-bottom-right" onClick={pageUp} style={{ cursor: "pointer" }} />}
            </div>
        </div>
    )
}
