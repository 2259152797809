import React, { useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import { Trash3 } from 'react-bootstrap-icons';
import { dollarConverter } from '../../../../../functions/dollarConverter';
import { costCode, resourceCode } from '../../../../../types/costCode';
import { useAddCostCodeMutation, useLazyGetCostCodeNameQuery } from '../../../../../redux/injectionEndpoints/costTracking/costCodeEndpoint';
import { useGetResourceCodesQuery } from '../../../../../redux/injectionEndpoints/costTracking/resourceCodeEndpoint';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';

const topTableHeadings = [
    "Code",
    "Code Name",
    "Sub-Code",
    "Sub-Code Name",
    "Budget"
]

const resourceCodeHeadings = [
    "Code",
    "Rate",
    "Unit",
    "Quantity",
    "Total",
    ""
];

type props = {
    closeAddCostCode: () => void,
    addCostCode: boolean
}

export const AddCostCode = (props: props) => {
    const [addCostCode] = useAddCostCodeMutation();
    const { data: resourceCodesData } = useGetResourceCodesQuery(); //for retrieving all resource codes
    const [resourceCodes, setresourceCodes] = useState<resourceCode[]>([])


    const selectedProject = useSelector((state: RootState) => state.tabs.projectTab) //subsrcibe to the project currently selected

    //Save all properties for new cost code
    const [code, setcode] = useState<string>("") //new cost code
    const [codeName, setcodeName] = useState<string>("") //new name
    const [subCode, setsubCode] = useState<string>("") //new sub-cost code
    const [subCodeName, setsubCodeName] = useState<string>("") //new sub-cost code
    const [budget, setbudget] = useState<number>(0)

    const [resource, setresource] = useState<string>("0") //new resource code to save
    const [rate, setrate] = useState<number>(0) //new rate to save
    const [unit, setunit] = useState<string>("") //new rate to save
    const [quantity, setquantity] = useState<number>(0) //new rate to save  

    const [message, setmessage] = React.useState<string>('');

    const [getCodeName, { data: searchedCodeName }] = useLazyGetCostCodeNameQuery();

    const saveCode = (e: React.MouseEvent<HTMLButtonElement>) => { //save new resource code from add cost code option
        e.preventDefault();
        var newResourceCode: resourceCode = {
            resource,
            quantity,
            unit,
            rate,
            total: rate * quantity
        }
        setresourceCodes([...resourceCodes!, newResourceCode])
        setbudget(budget + (rate * quantity));

        (document.getElementById("addCostCode-rateinput")! as HTMLInputElement).value = "";
        (document.getElementById("addCostCode-unitinput")! as HTMLInputElement).value = "";
        (document.getElementById("addCostCode-quantityinput")! as HTMLInputElement).value = "";
    }

    const deleteCode = (e: React.MouseEvent<SVGElement>) => { //delete resource code from the add cost code option
        e.preventDefault();
        const newCodes = resourceCodes!.filter(code => code.resource != e.currentTarget.id)
        setresourceCodes(newCodes)
        setbudget(newCodes.map(resourceCode => resourceCode.total).reduce((prev, curr) => prev + curr, 0))
    };

    const saveCostCode = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        //always need at least one resource code
        if (resourceCodes.length === 0 || resourceCodes == undefined) {
            setmessage("Please add at least one resource code")
            return window.setTimeout(() => setmessage(""), 3000)
        }

        if (searchedCodeName?.codeName) { //for the case where the searchedCodeName works
            //can't have code or code name empty
            if (code.length === 0 || searchedCodeName.codeName.length === 0) {
                setmessage("Code and Code Name cannot be blank")
                return window.setTimeout(() => setmessage(""), 3000)
            }
            addCostCode({
                project: selectedProject,
                code,
                codeName: searchedCodeName.codeName,
                subCode: subCode.length === 0 ? "0" : subCode, //default to zero if no sub code entered
                subCodeName,
                budget,
                resourceCodes,
                CTD: 0,
                remainingBudget: budget,
                budgetUnit: "",
                status: true
            })
            setresourceCodes([]);
            setsubCode('')
            setsubCodeName('')
            setbudget(0);
        } else { //manually typed code name
            //can't have code or code name empty
            if (code.length === 0 || codeName.length === 0) {
                setmessage("Code and Code Name cannot be blank")
                return window.setTimeout(() => setmessage(""), 3000)
            }
            addCostCode({
                project: selectedProject,
                code,
                codeName,
                subCode: subCode.length === 0 ? "0" : subCode, //default to zero if no sub code entered
                subCodeName,
                budget,
                resourceCodes,
                CTD: 0,
                remainingBudget: budget,
                budgetUnit: "",
                status: true
            });
            setresourceCodes([]);
            setsubCode('')
            setsubCodeName('')
            setbudget(0);
        }
    }

    const searchCodeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        setcode(e.target.value)
        getCodeName(e.target.value)
    }

    return (
        <Modal show={props.addCostCode} dialogClassName="modal-80w">
            <Modal.Header closeButton onHide={() => props.closeAddCostCode()}>
                <Modal.Title>Add Cost Code</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table>
                    <thead>
                        <tr>
                            {topTableHeadings.map(heading => {
                                return <th>{heading}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th><input placeholder='New Code' value={code} onChange={(e: React.ChangeEvent<HTMLInputElement>) => searchCodeName(e)}></input></th>
                            {searchedCodeName?.codeName ? (
                                <th><input placeholder='New Code Name' maxLength={45} value={searchedCodeName.codeName} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setcodeName(e.target.value)}></input></th>
                            )
                                :
                                <th><input placeholder='New Code Name' maxLength={45} value={codeName} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setcodeName(e.target.value)}></input></th>
                            }
                            <th><input placeholder='New Sub-Code' value={subCode} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setsubCode(e.target.value)}></input></th>
                            <th><input placeholder='New Sub-Code Name' value={subCodeName} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setsubCodeName(e.target.value)}></input></th>
                            <th>
                                <Table>
                                    <thead>
                                        <tr>
                                            {resourceCodeHeadings.map(heading => {
                                                return <th>{heading}</th>
                                            })}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <th>
                                                <select onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setresource(e.currentTarget.value)}>
                                                    <option value="0">SELECT</option>
                                                    {resourceCodesData?.map(resourceCode => {
                                                        return <option id={resourceCode.id?.toString()} value={resourceCode.code} >{resourceCode.code}</option>
                                                    })}
                                                </select>
                                            </th>
                                            <th><input id="addCostCode-rateinput" type="number" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setrate(Number(e.target.value))}></input></th>
                                            <th><input id="addCostCode-unitinput" value={unit} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setunit(e.target.value)}></input></th>
                                            <th><input id="addCostCode-quantityinput" type="number" value={quantity} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setquantity(Number(e.target.value))}></input></th>
                                            <th ><p id="addCostCode-total">{dollarConverter(rate * quantity, "$", "")}</p></th>
                                            <th><button className='universal-small-save-button' onClick={saveCode}>Save</button></th>
                                        </tr>
                                        {resourceCodes ?
                                            resourceCodes!.map(code => {
                                                return <tr>
                                                    <th>{code.resource}</th>
                                                    <th>{dollarConverter(code.rate, "$", "")}</th>
                                                    <th>{code.unit}</th>
                                                    <th>{dollarConverter(code.quantity, "$", "")}</th>
                                                    <th>{dollarConverter(code.total, "$", "")}</th>
                                                    <th><Trash3 id={code.resource} onClick={deleteCode}>Delete</Trash3></th>
                                                </tr>
                                            }) : null
                                        }
                                        <tr>
                                            <th colSpan={3}></th>
                                            <th>TOTAL</th>
                                            <th>{dollarConverter(resourceCodes.reduce((acc, resourceCode) => { return acc + resourceCode.total }, 0), "$", "")}</th>
                                        </tr>
                                    </tbody>
                                </Table>
                            </th>
                        </tr>
                        <p>{message}</p>
                    </tbody>
                </Table>
                <span className='save-item' >
                    <button className='universal-save-button' onClick={saveCostCode}>Save Cost Code</button>
                </span>
            </Modal.Body>
            <Modal.Footer>
                <button className='universal-close-button' onClick={() => props.closeAddCostCode()}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}
