import React from 'react';
import { Table, Modal } from 'react-bootstrap';
import { months } from '../../../../dropdowns/months';
import { useGetCostsQuery } from '../../../../../redux/injectionEndpoints/costTracking/costsEndpoint';
import { dollarConverter } from '../../../../../functions/dollarConverter';
import { useAddAccrualMutation } from '../../../../../redux/injectionEndpoints/costTracking/accrualsEndpoint';
import { cost } from '../../../../../types/cost';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';

const tableHeadings = [
    "Company",
    "Cost Code",
    "Resource Code",
    "Description",
    "Cost",
    "Type",
    "Accruing To",
    ""
];

type props = {
    closeAddFromTracker: () => void,
    costTrackerAccruals: boolean
}

export const AccrueFromTracker = (props: props) => {

    const selectedProject = useSelector((state: RootState) => state.tabs.projectTab)

    const { data: costsData } = useGetCostsQuery();
    const [addAccrual] = useAddAccrualMutation();

    const [accrualMonthModal, setaccrualMonthModal] = React.useState<boolean>(true)
    const [monthToAccrueTo, setmonthToAccrueTo] = React.useState<string>((new Date().getMonth() + 1).toString());
    const [accrualErrorTrue, setaccrualErrorTrue] = React.useState<boolean>(false)

    const accrueSingleOpenCost = (cost: cost) => { //for when we accrue with the "Accrue" button on each listed open cost
        const today = new Date();

        if (costsData) {
            addAccrual({
                project: selectedProject,
                month: monthToAccrueTo,
                year: today.getFullYear().toString(),
                dateAccrued: today.getFullYear().toString().concat("-", (today.getMonth() + 1).toString(), "-", today.getDate().toString()),
                costCode: cost.costCode,
                resourceCode: cost.resourceCode,
                company: cost.company,
                description: cost.description, //resourceCodesToAccrue(costsData, company, costCode).description[index].concat(resourceCodesData.find(resourceCodeDB => resourceCodeDB.code === resourceCode)!.name),
                cost: Number(cost.totalCost),
                type: "tracker accrual"
            })
        }
    };

    const accrueAllOpenCosts = () => { //for when we do a bulk accrual with the "Accrue All" button
        const today = new Date()

        if (costsData) {
            costsData.filter(cost => cost.status == "Open")
                .filter(cost => (cost!.costCode == "0" || undefined || null || 0) || (cost!.resourceCode == "0" || undefined || null) || (cost!.company == "0" || undefined || null) || (cost!.description == "0" || undefined || null) || (cost!.totalCost == undefined || null || 0))
                ?
                setaccrualErrorTrue(true)
                :
                costsData.forEach(cost => {
                    if (cost.status === "Open") {
                        {
                            addAccrual({
                                project: selectedProject,
                                month: monthToAccrueTo,
                                year: today.getFullYear().toString(),
                                dateAccrued: today.getFullYear().toString().concat("-", (today.getMonth() + 1).toString(), "-", today.getDate().toString()),
                                costCode: cost.costCode,
                                resourceCode: cost.resourceCode,
                                company: cost.company,
                                description: cost.description,
                                cost: Number(cost.totalCost),
                                type: "tracker accrual"
                            })
                        }
                    }
                })
        }

        // if (costsData && resourceCodesData) {
        //     companiestoAccrue(costsData).forEach(company => {
        //         costCodesToAccrue(costsData, company).forEach(costCode => {
        //             resourceCodesToAccrue(costsData, company, costCode).resourceCodes.forEach((resourceCode, index) => {
        //                 addAccrual({
        //                     project: selectedProject,
        //                     month: monthToAccrueTo,
        //                     year: today.getFullYear().toString(),
        //                     dateAccrued: today.getFullYear().toString().concat("-", (today.getMonth() + 1).toString(), "-", today.getDate().toString()),
        //                     costCode,
        //                     resourceCode,
        //                     company,
        //                     description: resourceCodesToAccrue(costsData, company, costCode).description[index].concat(resourceCodesData.find(resourceCodeDB => resourceCodeDB.code === resourceCode)!.name),
        //                     cost: Number(summaryCost(costsData, company, costCode, resourceCode)),
        //                     type: "tracker accrual"
        //                 })
        //             })
        //         })
        //     })
        // }
    }

    return (
        <Modal show={props.costTrackerAccruals} dialogClassName="modal-90w">
            <Modal.Header closeButton onHide={() => props.closeAddFromTracker()}>
                <Modal.Title>Add Accruals from Tracker</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="modal-subTabs-halfSplit">
                    <div className="modal-subTabs">
                        <p>Select Month To Accrue To</p>
                        <select style={{ width: "30%" }} value={monthToAccrueTo} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setmonthToAccrueTo(e.target.value)} >
                            <option value={0}>SELECT</option>
                            {months.map(month => {
                                return <option value={month.value}>{month.month}</option>
                            })}
                        </select>
                    </div>
                    <div>
                        <span className="modal-subTabs-item">Accrue All</span>
                    </div>
                </div>
                <Table>
                    <Modal show={accrualErrorTrue} centered size='lg'>
                        <Modal.Header closeButton onHide={() => setaccrualErrorTrue(false)}>
                            <Modal.Title>Woops, you're missing something</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <p>No accruals have been submitted. Please check your open costs and ensure all fields are properly entered</p>
                        </Modal.Body>
                    </Modal>
                    <thead>
                        <tr>
                            {tableHeadings.map(heading => {
                                return <th>{heading}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {costsData && costsData.map(cost => {
                            if (cost.status === "Open") {
                                return <tr >
                                    <td style={{ backgroundColor: cost.company == "0" || undefined || null ? "rgba(255, 99, 71, 0.4)" : "none" }}>{cost.company}</td>
                                    <td style={{ backgroundColor: cost.costCode == "0" || undefined || null ? "rgba(255, 99, 71, 0.4)" : "none" }}>{cost.costCode}</td>
                                    <td style={{ backgroundColor: cost.resourceCode == "0" || undefined || null ? "rgba(255, 99, 71, 0.4)" : "none" }}>{cost.resourceCode}</td>
                                    <td style={{ backgroundColor: cost.description == "0" || undefined || null ? "rgba(255, 99, 71, 0.4)" : "none" }}>{cost.description}</td>
                                    <td style={{ backgroundColor: cost.totalCost == 0 || undefined || null ? "rgba(255, 99, 71, 0.4)" : "none" }}>{dollarConverter(cost.totalCost, "$", "")}</td>
                                    <td>Tracker Accrual</td>
                                    <td>{months.find(month => month.value.toString() === monthToAccrueTo)?.month}</td>
                                    <td><button onClick={() => accrueSingleOpenCost(cost)}>Accrue</button></td>
                                </tr>
                            } else {
                                return
                            }
                        })}
                        {/* {costsData && companiestoAccrue(costsData).map(company => {
                        return <>
                        <tr>
                                            <td colSpan={tableHeadings.length} style={{ fontWeight: "bold" }}>{company}</td>
                                            </tr>
                                            {
                                                costCodesToAccrue(costsData, company).map(costCode => {
                                                    return resourceCodesToAccrue(costsData, company, costCode).resourceCodes.map((resourceCode, index) => {
                                                        return <tr>
                                                        <td></td>
                                                        <td >{costCode}</td>
                                                        <td>{resourceCode}</td>
                                                        <td>{resourceCodesData && resourceCodesToAccrue(costsData, company, costCode).description[index].concat(resourceCodesData.find(resourceCodeDB => resourceCodeDB.code === resourceCode)!.name)}</td>
                                                        <td>{dollarConverter(summaryCost(costsData, company, costCode, resourceCode), "$", "")}</td>
                                                        <td>Tracker Accrual</td>
                                                        <td>{months.find(month => month.value.toString() === monthToAccrueTo)?.month}</td>
                                                        <td><button onClick={() => accrueSingleOpenCost(costCode, resourceCode, company, index)}>Accrue</button></td>
                                                        </tr>
                                                    })
                                                })
                                            }
                                            </>
                                        })} */}
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal>
    )
}
