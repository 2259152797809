import React, { FC } from 'react';
import { Modal, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { dollarConverter } from '../../../../../functions/dollarConverter';
import { closeViewCosts } from '../../../../../redux/reducers/viewCostsReducer';
import { closeViewRates } from '../../../../../redux/reducers/viewRatesReducer';
import { RootState } from '../../../../../redux/store';

const topTableHeadings = [
    "Company",
    "Description",
    "Resource Code",
    "Cost Code",
    "Rates"
]

const bottomTableHeadings = [
    "Rate",
    "Unit",
    "Rate Description"
]

export const ViewCosts = () => {

    const dispatch = useDispatch()
    const { costs, costTitle } = useSelector((state: RootState) => state.costs) //rates and index for display

    return (
        <div>
            <Modal show={true} size="lg">
                <Modal.Header closeButton onHide={() => dispatch(closeViewCosts())}>
                    <Modal.Title>
                        View Costs: {costTitle}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table>
                        <thead>
                            <tr>
                                {topTableHeadings.map(heading => {
                                    return <th>{heading}</th>
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {costs!.map(cost => {
                                return <tr>
                                    <td>{cost.company}</td>
                                    <td>{cost.description}</td>
                                    <td>{cost.resourceCode}</td>
                                    <td>{cost.subCostCode}</td>
                                    <td>
                                        <Table>
                                            <thead>
                                                <tr>
                                                    {bottomTableHeadings.map(heading => {
                                                        return <th>{heading}</th>
                                                    })}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {cost.rates.map(rate => {
                                                    return <tr>
                                                        <td>{rate.rate}</td>
                                                        <td>{rate.unit}</td>
                                                        <td>{rate.rateDescription}</td>
                                                    </tr>
                                                })}
                                            </tbody>
                                        </Table>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => dispatch(closeViewCosts())}>Close</button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
