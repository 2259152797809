import { API } from "../../APIs/moduleAPI";
import { user } from "../../../types/user";

export const extendedUsersAPI = API
    .enhanceEndpoints({ addTagTypes: ['User' as const] })
    .injectEndpoints({
        endpoints: builder => ({
            getUsers: builder.query<user[], void>({
                query: () => 'users/getAllUsers',
                providesTags: ['User']
            }),
            getUser: builder.query<user, string>({
                query(id) {
                    return {
                        url: `users/getIndividualUser/${id}`,
                        method: 'GET'
                    }
                },
                providesTags: ['User']
            }),
            addUser: builder.mutation<user, user>({
                query(user) {
                    return {
                        url: 'users/newUser',
                        method: 'POST',
                        body: user
                    }
                },
                invalidatesTags: ['User']
            }),
            editUser: builder.mutation<user, user>({
                query(user) {
                    return {
                        url: `users/editUser/${user._id}`,
                        method: "POST",
                        body: user
                    }
                },
                invalidatesTags: ['User']
            }),
            deleteUser: builder.mutation<user[], string>({
                query(id) {
                    return {
                        url: `users/deleteUser/${id}`,
                        method: "DELETE",
                    }
                },
                invalidatesTags: ['User']
            })
        })
    })

export const { 
    useGetUsersQuery,
    useGetUserQuery,
    useAddUserMutation,
    useDeleteUserMutation,
    useEditUserMutation 
} = extendedUsersAPI