import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { rate } from '../../types/index';

export interface viewRatesPayload {
    rates: rate[],
    indexTitle: string
}

export interface viewRatesState {
    ratesOpen: boolean,
    rates: rate[],
    indexTitle: string
}

const initialState: viewRatesState = {
    ratesOpen: false,
    rates: [],
    indexTitle: ""
}

export const viewRatesSlice = createSlice({
    name: "viewRates",
    initialState,
    reducers: {
        closeViewRates(state) {
            return {
                ...state,
                ratesOpen: false,
                rates: [],
                indexTitle: ""
            }
        },
        openViewRates(state, action: PayloadAction<viewRatesPayload>) {
            return {
                ...state,
                ratesOpen: true,
                rates: action.payload.rates,
                indexTitle: action.payload.indexTitle
            }
        }
    }
});

export const { closeViewRates, openViewRates } = viewRatesSlice.actions

export default viewRatesSlice.reducer