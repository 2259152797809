import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import { BottomHeader } from './components/headers/BottomHeader';
import { TopHeader } from './components/headers/TopHeader';
import { useSelector } from 'react-redux';
import { RootState } from './redux/store';
import { PleaseSignIn } from './components/body/PleaseSignIn';
import { RequestAccess } from './components/body/RequestAccess';
import { CostCodes } from './components/modals/modal_internals/costTracking/CostCodes';
import { ResourceCodes } from './components/modals/modal_internals/costTracking/ResourceCodes';
import { Costs } from './components/modals/modal_internals/costTracking/Costs';
import { Accruals } from './components/modals/modal_internals/costTracking/Accruals';
import { Index } from './components/modals/modal_internals/costTracking/Index';
import { Companies } from './components/modals/modal_internals/costTracking/Companies';
import { Templates } from './components/modals/modal_internals/costTracking/Templates';
import { ProductionTracking } from './components/modals/modal_internals/costTracking/ProductionTracking';
import { OngoingCosts } from './components/modals/modal_internals/costTracking/OngoingCosts';
import { AddUser } from './components/admin/AddUser';
import { ViewUsers } from './components/admin/ViewUsers';
import { ViewProjects } from './components/admin/ViewProjects';
import { CreateProject } from './components/admin/CreateProject';
import { ChangePassword } from './components/admin/ChangePassword';
import { DummyPage } from './components/body/DummyPage';
import { RequestAccessConfirmed } from './components/body/RequestAccessConfirmed';
import { ProjectInteractionHeader } from './components/headers/ProjectInteractionHeader';
import { EditProductionTracking } from './components/modals/modal_internals/costTracking/editItems/EditProductionTracking';
import { ForgotPassword } from './components/body/ForgotPassword';

function App() {

    const confirmedAuthenticated = useSelector((state: RootState) => state.user)// boolean for authenticated state

    const renderIfAuthenticated = (pageToLoad: JSX.Element) => {
        if (confirmedAuthenticated.isAuthenticated) return pageToLoad
        else if (confirmedAuthenticated.isLoading) return <DummyPage />
        else return <PleaseSignIn />
    }

    return (
        <Router>
            {confirmedAuthenticated.isLoading ? <DummyPage /> :
                <div className="App">
                    <TopHeader />
                    {confirmedAuthenticated.isAuthenticated && <BottomHeader {...[...confirmedAuthenticated!.userProfile!.projects!]} />}
                    <>
                        <div id="modalBody">
                            <Tab.Container defaultActiveKey="/" >
                                <ProjectInteractionHeader />
                                <Tab.Content>
                                    <Routes>
                                        <Route path="/" element={<DummyPage />} />
                                        <Route path="/Costs" element={renderIfAuthenticated(<Costs />)} />
                                        <Route path="/CostCodes" element={renderIfAuthenticated(<CostCodes />)} />
                                        <Route path="/Index" element={renderIfAuthenticated(<Index />)} />
                                        <Route path="/ResourceCodes" element={renderIfAuthenticated(<ResourceCodes />)} />
                                        <Route path="/Companies" element={renderIfAuthenticated(<Companies />)} />
                                        <Route path="/Templates" element={renderIfAuthenticated(<Templates />)} />
                                        <Route path="/Accruals" element={renderIfAuthenticated(<Accruals />)} />
                                        <Route path="/ProductionTracking" element={renderIfAuthenticated(<ProductionTracking />)} />
                                        <Route path="/OngoingCosts" element={renderIfAuthenticated(<OngoingCosts />)} />
                                        <Route path="/Projects" element={renderIfAuthenticated(<ViewProjects />)} />
                                        <Route path="/Users" element={renderIfAuthenticated(<ViewUsers />)} />
                                        {/* <Route path="/AddUser" element={renderIfAuthenticated(<AddUser />)} /> */}
                                        <Route path="/AddProject" element={renderIfAuthenticated(<CreateProject />)} />
                                        <Route path="/ChangePassword" element={renderIfAuthenticated(<ChangePassword {...confirmedAuthenticated.userProfile!} />)} />
                                        <Route path="/login" element={renderIfAuthenticated(<PleaseSignIn />)} />
                                        <Route path="/requestaccess" element={<RequestAccess />} />
                                        <Route path="/forgot-password" element={<ForgotPassword/>} />
                                        <Route path="/requestaccessconfirmed" element={renderIfAuthenticated(<RequestAccessConfirmed />)} />
                                        <Route path="/editProductionTracking" element={renderIfAuthenticated(<EditProductionTracking />)} />
                                    </Routes>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </>
                </div>
            }
        </Router>
    );
}

export default App;
