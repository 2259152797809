import React from 'react';
import { Modal } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import { useGetIndexesQuery } from '../../../../../redux/injectionEndpoints/costTracking/indexEndpoint';
import { topTableHeadings, bottomTableHeadings } from './AddCost';
import { useGetCostCodesQuery } from '../../../../../redux/injectionEndpoints/costTracking/costCodeEndpoint';
import { useGetResourceCodesQuery } from '../../../../../redux/injectionEndpoints/costTracking/resourceCodeEndpoint';
import { useGetCompaniesQuery } from '../../../../../redux/injectionEndpoints/costTracking/companiesEndpoint';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { costRate } from '../../../../../types/cost';
import { dollarConverter } from '../../../../../functions/dollarConverter';
import { Trash3 } from 'react-bootstrap-icons';
import { useAddCostMutation } from '../../../../../redux/injectionEndpoints/costTracking/costsEndpoint';

type props = {
    closeAddCostFromIndex: () => void,
    addCostFromIndex: boolean,
    indexID: string | undefined
}

export const AddCostFromIndex = (props: props) => {

    const selectedProject = useSelector((state: RootState) => state.tabs.projectTab)

    const [addCost] = useAddCostMutation();

    const { costCodesData } = useGetCostCodesQuery(undefined, {
        selectFromResult: ({ data }) => ({
            costCodesData: data?.filter(costCode => costCode.project === selectedProject)
        })
    });

    const { resourceCodesData } = useGetResourceCodesQuery(undefined, {
        selectFromResult: ({ data }) => ({
            resourceCodesData: data?.filter(costCode => costCode.project === selectedProject)
        })
    });

    const { data: companiesData } = useGetCompaniesQuery();

    //locate index we are referring to
    const { indexData } = useGetIndexesQuery(undefined, {
        selectFromResult: ({ data }) => ({
            indexData: data?.find(index => Number(index.id) === Number(props.indexID))
        })
    })

    //fields to be inputted by user
    const [date, setdate] = React.useState<string>('')
    const [costCode, setcostCode] = React.useState<string>('0')
    const [resourceCode, setresourceCode] = React.useState<string>('0');
    const [docketNumber, setdocketNumber] = React.useState<string>('N/A');
    const [purchaseOrder, setpurchaseOrder] = React.useState<string>('N/A');
    const [company, setcompany] = React.useState<string>('0');
    const [description, setdescription] = React.useState<string>('N/A');
    const [invoiceNumber, setinvoiceNumber] = React.useState<string>('N/A');

    //rates fields to be inputed by user
    const [indexQuantities, setindexQuantities] = React.useState<number[]>([0]) //quantities array for indexed costs
    const [indexTotals, setindexTotals] = React.useState<number[]>([0]) //array with rates total costs

    //message
    const [message, setmessage] = React.useState<string>('')

    //rates
    const setNewCostRates = (e: React.ChangeEvent<HTMLInputElement>, index: number, rate: number) => { //used to update rates when index not = 0 is selected
        var newIndexQuantities = [...indexQuantities]; //create new dummy rates variable
        var newindexTotals = [...indexTotals] //create new dummy totals variable

        newIndexQuantities[index] = Number(e.currentTarget.value) //update dummy rates variable based on changes in UI
        newindexTotals[index] = Number(e.currentTarget.value) * rate

        setindexQuantities(newIndexQuantities); //reset indexRates
        setindexTotals(newindexTotals); //reset totals
    }

    //Add new cost
    const addNewCost = () => {
        if (indexData) {
            const costRates: costRate[] = indexData!.rates.map((rate, index) => ({ //set new rates for the new cost based on UI inputs and indexData
                description: rate.rateDescription!,
                rate: rate.rate!,
                unit: rate.unit!,
                numberOf: indexQuantities[index] ? indexQuantities[index] : 0,
                valueOf: indexQuantities[index] ? rate.rate! * indexQuantities[index] : 0
            }));

            addCost({
                index: indexData.id!,
                project: selectedProject,
                date,
                costCode: indexData.subCostCode !== "0" ? indexData.subCostCode! : costCode ,
                resourceCode: indexData.resourceCode !== "0" ? indexData.resourceCode : resourceCode,
                description: indexData.description !== "N/A" ? indexData.description : description,
                company: indexData.company !== "0" ? indexData.company : company,
                rates: costRates,
                docketNumber,
                purchaseOrder,
                invoiceNumber,
                status: "Open",
                totalCost: costRates!.map(rate => rate.valueOf).reduce((prev, curr) => prev + curr, 0),
                comments: []
            }).unwrap()
            .then((cost) => {
                setmessage("Cost successfully added")
                window.setTimeout(() => setmessage(""), 3000)
            })
            .catch((err) => {
                setmessage("Something went wrong. Try adding a cost from the cost addition page instead.")
                window.setTimeout(() => setmessage(""), 3000)
            })
        } else {
            setmessage("We can't seem to retrieve the necessary information at this time. Try adding a cost from the cost addition page instead.");
            window.setTimeout(() => setmessage(""), 3000)
        }
    }

    if (typeof props.indexID === undefined) {
        return <Modal show={props.addCostFromIndex}>
            <Modal.Header closeButton onHide={() => props.closeAddCostFromIndex()}>
                <Modal.Title>Fetching Index...</Modal.Title>
            </Modal.Header>
        </Modal>
    }

    return (
        <Modal show={props.addCostFromIndex} dialogClassName="modal-90w">
            <Modal.Header closeButton onHide={() => props.closeAddCostFromIndex()}>
                <Modal.Title>Add Cost From Index</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table>
                    <thead>
                        <tr>
                            {topTableHeadings.map(heading => {
                                return <th>{heading}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{indexData && indexData.id}</td>
                            <td><input type="date" value={date} style={{ maxWidth: "100px" }} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setdate(e.target.value)}></input></td>
                            {indexData && indexData.subCostCode === "0" ?
                                <td>
                                    <select value={costCode} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setcostCode(e.target.value)} >
                                        <option value="0">Select</option>
                                        {costCodesData?.map(costCode => {
                                            return <option value={costCode.code.concat(".", costCode.subCode)}>{costCode.code.concat(".", costCode.subCode)}</option>
                                        })}
                                    </select>
                                </td>
                                :
                                <td>{indexData && <p>{indexData!.subCostCode}</p>}</td>
                            }
                            {indexData && indexData.resourceCode === "0" ?
                                <td>
                                    <select value={resourceCode} style={{ maxWidth: "80px" }} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setresourceCode(e.target.value)}>
                                        <option value="0">Select</option>
                                        {resourceCodesData?.map(resourceCode => {
                                            return <option value={resourceCode.code}>{resourceCode.code}</option>
                                        })}
                                    </select>
                                </td>
                                :
                                <td>
                                    {indexData && <p>{indexData!.resourceCode}</p>}
                                </td>
                            }
                            <td><input type="string" value={docketNumber} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setdocketNumber(e.target.value)}></input></td>
                            <td><input type="string" value={purchaseOrder} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setpurchaseOrder(e.target.value)}></input></td>
                            {indexData && indexData.company === "0" ?
                                <td>
                                    <select value={company} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setcompany(e.target.value)}>
                                        <option value="0">Select</option>
                                        {companiesData && companiesData.map(company => {
                                            return <option value={company.company}>{company.company}</option>
                                        })}
                                    </select>
                                </td>
                                :
                                <td>
                                    {indexData && <p>{indexData.company}</p>}
                                </td>
                            }
                            {indexData && indexData.description === "0" || undefined ?
                                <td><input type="string" value={description} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setdescription(e.target.value)}></input></td>
                                :
                                <td>
                                    {indexData && <p>{indexData.description}</p>}
                                </td>
                            }
                            <td><input type="string" value={invoiceNumber} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setinvoiceNumber(e.target.value)}></input></td>
                        </tr>
                    </tbody>
                </Table>
                <Table style={{ maxWidth: "40%" }}>
                    <thead>
                        <tr>
                            {bottomTableHeadings.map(heading => {
                                return <th>{heading}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {indexData && indexData!.rates.map((rate, index) => {
                            return <tr>
                                <td>{rate.rateDescription}</td>
                                <td>{rate.rate}</td>
                                <td>{rate.unit}</td>
                                <td><input type="number" id={rate.rateDescription} style={{ maxWidth: "80px" }} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setNewCostRates(e, index, rate.rate!) }} min={0} ></input></td>
                                <td>{dollarConverter(indexQuantities[index] ? indexQuantities[index] * rate.rate! : 0, "$", "")}</td>
                            </tr>
                        })
                        }
                        <tr>
                            <th colSpan={bottomTableHeadings.length - 2}>TOTAL</th>
                            <th>{
                                dollarConverter(indexTotals.map(total => (total)).reduce((prev, curr) => prev + curr), "$", "")
                            }</th>
                        </tr>
                    </tbody>
                </Table>
                <p style={{margin: '0'}}>{message}</p>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={() => addNewCost()}>Save Cost</button>
            </Modal.Footer>
        </Modal>
    )
}
