import React, { useState } from 'react'
import { index } from '../../../../../types';
import { rate } from '../../../../../types';
import { useEditIndexMutation } from '../../../../../redux/injectionEndpoints/costTracking/indexEndpoint';
import { useEditCostMutation, useGetCostsQuery } from '../../../../../redux/injectionEndpoints/costTracking/costsEndpoint';
import { useGetCompaniesQuery } from '../../../../../redux/injectionEndpoints/costTracking/companiesEndpoint';
import { useGetCostCodesQuery } from '../../../../../redux/injectionEndpoints/costTracking/costCodeEndpoint';
import { useGetResourceCodesQuery } from '../../../../../redux/injectionEndpoints/costTracking/resourceCodeEndpoint';

export const EditIndex = (props: index) => {
    const [editIndex] = useEditIndexMutation();
    const [editCost] = useEditCostMutation();

    const { data: costs } = useGetCostsQuery(); //need these in case we update our index
    const { data: companies } = useGetCompaniesQuery(); //need these for our dropdown
    const { data: costCodes } = useGetCostCodesQuery(); //need these for our dropdown
    const { data: resourceCodes } = useGetResourceCodesQuery(); //need these for our dropdown

    //EDITED TEXT VALUES
    const [company, setcompany] = useState<string>(props.company)
    const [description, setdescription] = useState<string>(props.description)
    const [subCostCode, setsubCostCode] = useState<string>(props.subCostCode!)
    const [resourceCode, setresourceCode] = useState<string>(props.resourceCode)
    const [status, setStatus] = React.useState<number>(props.status || 0)

    ///NEW RATES IF EDITING
    const [newRate, setnewRate] = useState<number | undefined>()
    const [newUnit, setnewUnit] = useState<string>("")
    const [newRateDescription, setnewRateDescription] = useState<string>("");

    const [rates, setrates] = useState<rate[]>([...props.rates]) //this rates array will be the final array added to our index

    const saveRate = (e: React.MouseEvent<HTMLButtonElement>) => { //function add new rate to rate array
        e.preventDefault()
        //enter code here to check all entries are filled

        const rateToAdd: rate = { //create new complete rate to add to rate array
            id: props.rates.reduce((prev, curr) => (prev.id! > curr.id!) ? prev : curr).id! + 1,
            rate: newRate,
            unit: newUnit,
            rateDescription: newRateDescription
        }

        setrates([...rates, rateToAdd]) //add the new complete rate to the rate array

        // setrateId(Math.max(...rates.map(rate => rate.id!)) + 1)
        setnewRate(0)
        setnewUnit("")
        setnewRateDescription("")
    }

    const deleteRate = (e: React.MouseEvent<SVGElement>) => {
        e.preventDefault();

        const newRates = rates.filter(rate => rate.id?.toString() !== e.currentTarget.id) //filter out the deleted rate
        setrates(newRates)
    }

    //for updating resource codes rates array
    const updateRatesState = (e: React.ChangeEvent<HTMLInputElement>, field: string, index: number) => {
        setrates(prevRates => {
            let newRates = [...prevRates];
            let newRate = { ...newRates[index] }
            switch (field) {
                case "rate":
                    newRate.rate = Number(e.target.value);
                    break
                case "rateUnit":
                    newRate.unit = e.target.value;
                    break
                case "rateDescription":
                    newRate.rateDescription = e.target.value;
                    break
            }
            newRates[index] = newRate;
            return newRates;
        })
    }

    const saveIndex = (e: React.MouseEvent<HTMLButtonElement>) => { //for when the user hits save
        if (company !== props.company) { //only update company if different to original company value
            editIndex({ id: props.id!, field: "company", value: company }) //edit through backend
            costs && costs.filter(cost => cost.index === props.id).forEach(cost => {
                editCost({ id: cost.id!, field: "company", value: company })
            })
        }
        if (description !== props.description) {
            editIndex({ id: props.id!, field: "description", value: description })
            costs && costs.filter(cost => cost.index === props.id).forEach(cost => {
                editCost({ id: cost.id!, field: "description", value: description })
            })
        }
        if (subCostCode !== props.subCostCode) {
            editIndex({ id: props.id!, field: "subCostCode", value: subCostCode })
            costs && costs.filter(cost => cost.index === props.id).forEach(cost => {
                editCost({ id: cost.id!, field: "costCode", value: subCostCode })
            })
        }
        if (resourceCode !== props.resourceCode) {
            editIndex({ id: props.id!, field: "resourceCode", value: resourceCode })
            costs && costs.filter(cost => cost.index === props.id).forEach(cost => {
                editCost({ id: cost.id!, field: "resourceCode", value: resourceCode })
            })
        }
        if (status !== props.status) {
            editIndex({ id: props.id!, field: "status", value: status })
        }
    }

    return (
        <>
            <tr>
                <td colSpan={2}><button onClick={saveIndex} className='universal-small-save-button' >Save</button></td>
                <td >
                    <select name='company' value={company} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setcompany(e.target.value)}>
                        {companies && companies.map(company => {
                            return <option value={company.company}>{company.company}</option>
                        })}
                    </select>
                </td>
                <td><input name='description' value={description} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setdescription(e.target.value)}></input></td>
                <td>
                    <select name='subCostCode' value={subCostCode} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setsubCostCode(e.target.value)}>
                        <option value="0">No Cost Code</option>
                        {costCodes && costCodes.map(costCode => {
                            return <option value={costCode.code.concat(".", costCode.subCode)}>{costCode.code.concat(".", costCode.subCode)}</option>
                        })}
                    </select>
                </td>
                <td>
                    <select name='resourceCode' value={resourceCode} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setresourceCode(e.target.value)}>
                        {resourceCodes && resourceCodes.map(resourceCode => {
                            return <option value={resourceCode.code}>{resourceCode.code}</option>
                        })}
                    </select>
                </td>
                <td>
                    N/A
                </td>
                <td>
                    <select name="status" value={status} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setStatus(Number(e.target.value))}>
                        <option value={1}>Open</option>
                        <option value={0}>Closed</option>
                    </select>
                </td>
            </tr>
        </>
    )
}
