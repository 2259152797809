import React, { useState } from 'react'
import { Modal, Table } from 'react-bootstrap';
import { resourceCode } from '../../../../../types/resourceCode';
import { useAddResourceCodeMutation } from '../../../../../redux/injectionEndpoints/costTracking/resourceCodeEndpoint';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';

type props = {
    closeAddResourceCode: () => void,
    addResourceCode: boolean
}

export const AddResourceCode = (props: props) => {

    const [addResourceCode] = useAddResourceCodeMutation();

    const selectedProject = useSelector((state: RootState) => state.tabs.projectTab)

    const [code, setcode] = useState("") // new resource code
    const [name, setname] = useState("") //new resource code name

    const saveResourceCode = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        addResourceCode({
            project: selectedProject,
            code,
            name,
            CTD: 0
        })

    }

    return (
        <Modal show={props.addResourceCode} dialogClassName="modal-50w">
            <Modal.Header closeButton onHide={() => props.closeAddResourceCode()}>
                <Modal.Title>Add Resource Code</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table>
                    <thead>
                        <tr>
                            <td>Resource Code</td>
                            <td>Name</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><input placeholder='New Resource Code' onChange={(e: React.ChangeEvent<HTMLInputElement>) => setcode(e.target.value)}></input></td>
                            <td><input placeholder='New Name' onChange={(e: React.ChangeEvent<HTMLInputElement>) => setname(e.target.value)}></input></td>
                            <td>
                                <span  >
                                    <button className='universal-save-button' onClick={saveResourceCode}>Save Resource Code</button>
                                </span>
                            </td>
                        </tr>
                    </tbody>
                </Table>
            </Modal.Body>
            <Modal.Footer>
                <button className='universal-close-button' onClick={() => props.closeAddResourceCode()}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}