import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { CaretDown, CaretUp } from 'react-bootstrap-icons';
import { AddCostCode } from './add items/AddCostCode';
import { dollarConverter } from '../../../../functions/dollarConverter';
import { EditCostCode } from './editItems/EditCostCode';
import { Binoculars } from 'react-bootstrap-icons';
import { useGetCostCodesQuery, useDeleteCostCodeMutation } from '../../../../redux/injectionEndpoints/costTracking/costCodeEndpoint';
import { useGetCostsQuery } from '../../../../redux/injectionEndpoints/costTracking/costsEndpoint';
import { costCodesBudgetCalculator } from '../../../../functions/costTracking/costSummaries';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { openViewResourceCodes } from '../../../../redux/reducers/viewResourceCodesReducer';
import { ViewResourceCodes } from './viewItems/ViewResourceCodes';
import { costCodeCostSummary, metaCostCodeStatus, metaCostCodeSummary, metaMonthlyCostCodeSummary, monthlyCostCodeSummary } from '../../../../functions/costTracking/costCodeSummaries';
import { costCode } from '../../../../types/costCode';

const tableHeadings = [
    "Select",
    "Cost Code",
    "Code Name",
    "Resource Codes",
    "Budget",
    "Cost to Date",
    "Remaining Budget",
    "Monthly Cost",
    "Status"
];

interface codeSummary {
    code: string,
    name: string,
    budget: number
}

export const CostCodes = () => {

    const dispatch = useDispatch();
    const selectedProject = useSelector((state: RootState) => state.tabs.projectTab) //subsrcibe to the project currently selected

    const { data: costCodes, refetch: costCodesRefetch } = useGetCostCodesQuery(); //for retrieving all resource codes

    const { data: costs, isSuccess: costsSuccess } = useGetCostsQuery(); //retrieving all costs

    const [addCostCode, setaddCostCode] = useState(false);
    const [editCostCode, seteditCostCode] = React.useState<boolean>(false)

    const [costCodeToEdit, setcostCodeToEdit] = useState<costCode | null>() //indicate which cost codes have been selected

    const viewResourceCodes = useSelector((state: RootState) => state.resourceCodes.resourceCodesOpen) //for when we want to view rates of a cost code

    const [minimisedCodes, setminimisedCodes] = React.useState<string[]>([])

    const codeSummaries: Record<string, codeSummary> = {};

    costCodes?.forEach(costCode => {
        if (!codeSummaries[costCode.code]) {
            codeSummaries[costCode.code] = {
                code: costCode.code,
                name: costCode.codeName,
                budget: costCodesBudgetCalculator(costCodes, costCode.code)
            }
        }
    });

    const toggleMinimiseCode = (code: string) => {
        if (minimisedCodes.includes(code)) {
            setminimisedCodes(minimisedCodes.filter((c) => c !== code));
        } else {
            setminimisedCodes([...minimisedCodes, code]);
        }
    }

    const closeAddCostCode = () => {
        setaddCostCode(false)
    };

    const closeEditCostCode = () => {
        seteditCostCode(false)
    };

    React.useEffect(() => {
        setminimisedCodes(costCodes ? Object.values(costCodes).map(costCode => costCode.code) : [])
    }, [costCodes])

    return (
        <div id="costCodes-modal">
            <div className="modal-subTabs">
                <span id="addIndex" onClick={(e: React.MouseEvent<HTMLSpanElement>) => setaddCostCode(true)} className="modal-subTabs-item">
                    <button className='universal-save-button'>Add Cost Code</button>
                </span>
                <span className="modal-subTabs-item" >
                    <button className='universal-search-button' onClick={() => costCodesRefetch()}>Refetch Cost Codes Data</button>
                </span>
            </div>
            {addCostCode ? <div><AddCostCode closeAddCostCode={closeAddCostCode} addCostCode={addCostCode} /> <hr></hr></div> : null}
            {editCostCode && costCodeToEdit && <EditCostCode openEditCostCode={editCostCode} closeEditCostCode={closeEditCostCode} costCode={costCodeToEdit} />}
            <div className='modal-viewRates'>
                {
                    viewResourceCodes && <ViewResourceCodes />
                }
            </div>
            <div className="costCodes-modal-pageSplit">
                <Table className="generic-table-class" bordered hover>
                    <thead>
                        <tr>
                            {tableHeadings.map(heading => {
                                return <th>{heading}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {Object.values(codeSummaries).map(summary => (
                            <React.Fragment key={`summary-${summary.code}`}>
                                <tr>
                                    <th onClick={() => toggleMinimiseCode(summary.code)}>{minimisedCodes.includes(summary.code) ? <CaretDown /> : <CaretUp />}</th>
                                    <th >{summary.code}</th>
                                    <th colSpan={2}>{summary.name}</th>
                                    <th>{costsSuccess && dollarConverter(codeSummaries[summary.code].budget, "$", "")}</th>
                                    <th>{costsSuccess && dollarConverter(metaCostCodeSummary(costs, summary.code), "$", "")}</th>
                                    <th>{costsSuccess && dollarConverter(codeSummaries[summary.code].budget - metaCostCodeSummary(costs, summary.code), "$", "")}</th>
                                    <th>{costsSuccess && dollarConverter(metaMonthlyCostCodeSummary(costs, summary.code), "$", "")}</th>
                                    <th >{costsSuccess && (metaCostCodeStatus(costCodes, summary.code) ? "Open" : "Closed")}</th>
                                </tr>
                                {!minimisedCodes.includes(summary.code) &&
                                    costCodes?.
                                        filter(costCode => costCode.project === selectedProject)
                                        .filter(costCode => costCode.code === summary.code)
                                        .map(costCode => {
                                            return <React.Fragment key={`summary-${costCode.code}`}>
                                                <tr key={`${costCode.code}-${costCode.subCode}`}>
                                                    <td><button className="costCode-editButton" onClick={() => { seteditCostCode(true); setcostCodeToEdit(costCode) }}>Edit</button></td>
                                                    <td >{costCode.code}.{costCode.subCode}</td>
                                                    <td>{costCode.subCodeName}</td>
                                                    <td>
                                                        <Binoculars onClick={() => dispatch(openViewResourceCodes({ resourceCodes: costCode.resourceCodes, codeTitle: costCode.subCodeName }))} />

                                                    </td>
                                                    <td>{dollarConverter(Number(costCode.budget), "$", "")}</td>
                                                    <td>{costsSuccess && dollarConverter(Number(costCodeCostSummary(costs, costCode.code.concat(".", costCode.subCode))), '$', "")}</td>
                                                    <td>{costsSuccess && dollarConverter(Number(costCode.budget) - Number(costCodeCostSummary(costs, costCode.code.concat(".", costCode.subCode))), "$", "")}</td>
                                                    <td>{costsSuccess && dollarConverter(Number(monthlyCostCodeSummary(costs, costCode.code.concat(".", costCode.subCode))), "$", "")}</td>
                                                    <td>{costCode.status ? "Open" : "Closed"}</td>
                                                </tr>
                                            </React.Fragment>
                                        })
                                }
                            </React.Fragment>
                        ))}
                    </tbody>
                </Table>
            </div>
        </div>
    )
}
