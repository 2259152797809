import { editObject } from "../../../types/edit";
import { API } from "../../APIs/moduleAPI";
import { cost, costSearch } from "../../../types/cost";

export const extendedCostsAPI = API
    .enhanceEndpoints({ addTagTypes: ['Cost' as const] })
    .injectEndpoints({
        endpoints: builder => ({
            getCosts: builder.query<cost[], void>({ //retrieve all Costs from db
                query: () => {
                    return {
                        url: 'costTracking/getAllCosts',
                        method: 'GET'
                    }
                },
                providesTags: ["Cost"]
            }),
            getCost: builder.query<cost[], string>({//retrieve individual cost from db
                query(id) {
                    return {
                        url: `costTracking/getCost/${id}`,
                        method: "GET"
                    }
                },
                providesTags: ["Cost"]
            }),
            searchCosts: builder.query<cost[] | [], costSearch>({
                query(searches) {
                    return {
                        url: 'costTracking/searchCosts',
                        method: 'POST',
                        body: searches
                    }
                }
            }),
            addCost: builder.mutation<cost, cost>({
                query(cost) {
                    return {
                        url: 'costTracking/createNewCost',
                        method: "POST",
                        body: cost
                    }
                },
                invalidatesTags: ["Cost"]
            }),
            deleteCost: builder.mutation<cost, string>({
                query(id) {
                    return {
                        url: `costTracking/deleteCost/${id}`,
                        method: 'DELETE'
                    }
                },
                invalidatesTags: ["Cost"]
            }),
            editCost: builder.mutation<cost, editObject>({
                query(editObject) {
                    return {
                        url: `costTracking/editCost/${editObject.id}`,
                        method: "POST",
                        body: editObject
                    }
                },
                invalidatesTags: ["Cost"]
            })
        }),
        overrideExisting: false
    });

export const {
    useGetCostsQuery,
    useGetCostQuery,
    useSearchCostsQuery,
    useAddCostMutation,
    useDeleteCostMutation,
    useEditCostMutation
} = extendedCostsAPI