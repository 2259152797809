import { accrual } from "../../types/accruals";

export const accrualsToAoAs = (data: accrual[] | undefined) => {
    let returnArray:(string | number | Date)[][] = []
    if (typeof data === undefined) {
        returnArray = [["No data for your selection"]]
        return returnArray
    } else {
        data?.forEach(accrual => {
            var newRow = [ new Date(accrual.dateAccrued), accrual.costCode, accrual.resourceCode, accrual.company, accrual.description, accrual.cost, accrual.type.toUpperCase()]
            returnArray.push(newRow)
        })
        return returnArray
    }
}