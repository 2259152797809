import React, { FC } from 'react';
import "../../css files/BottomHeader.css";
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { useGetProjectsQuery } from '../../redux/injectionEndpoints/costTracking/projectsEndpoint';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { changeProject } from '../../redux/reducers/projectTabReducer';

export const BottomHeader = (loggedInProjects: string[]) => {

    const user = useSelector((state: RootState) => state.user.userProfile)// boolean for authenticated state
    const project = useSelector((state:RootState) => state.tabs.projectTab);
    const [key, setkey] = React.useState(project)
    const { projects } = useGetProjectsQuery(undefined, {
        selectFromResult: ({ data }) => ({
            projects: data?.filter(project => (user?.projects.find(userProject => userProject === project._id )))
        })
    });

    const dispatch = useDispatch();

    const changeProjectTab = (key: string) => {
        setkey(key!); //change the key for the tab component
        dispatch(changeProject(key)); //change the project in global state to re-render project specific data
    };

    React.useEffect(() => {
        if (project) {
            setkey(project)
        }
    }, [project])

    return (
        <div id="bottomHeader">
            <h5>Projects</h5>
            <Tabs
                id="program-modal-tabs"
                activeKey={key}
                onSelect={key => changeProjectTab(key!)}
            >
                {projects &&
                    projects.map(rtkProject => {
                        if (user?.userPrivilege === "Engineer") {
                            if (user?.projects.find(project => project == rtkProject._id)) {
                                return <Tab title={rtkProject.projectName} eventKey={rtkProject._id} key={rtkProject._id}>
                                </Tab>
                            } else {
                                return
                            }
                        }
                        else return <Tab title={rtkProject.projectName} eventKey={rtkProject._id} key={rtkProject._id}>
                        </Tab>
                    })
                }
            </Tabs>
        </div>
    )
}
