import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { useSelector } from 'react-redux';
import { dollarConverter } from '../../../../functions/dollarConverter';
import { RootState } from '../../../../redux/store';
import { AccrueFromTracker } from './add items/AccrueFromTracker';
import { useGetAccrualsQuery } from '../../../../redux/injectionEndpoints/costTracking/accrualsEndpoint';
import { EditAccrual } from './editItems/EditAccrual';
import { months } from '../../../dropdowns/months';
import { getAccrualYearRange } from '../../../../functions/costTracking/getAccrualYearRange';
import { AddAccrual } from './add items/AddAccrual';
import { DownloadAccruals } from '../downloads/DownloadAccruals';
import { useDeleteAccrualMutation } from '../../../../redux/injectionEndpoints/costTracking/accrualsEndpoint';

const tableHeadings = [
    "",
    "Company",
    "Cost Code",
    "Resource Code",
    "Description",
    "Cost",
    "Type",
    "Accruing To",
    ""
];

export const Accruals = () => {
    const selectedProject = useSelector((state: RootState) => state.tabs.projectTab)

    const [monthToView, setmonthToView] = useState<string>((new Date().getMonth()+1).toString());
    const [yearToView, setyearToView] = useState<string>(new Date().getFullYear().toString())

    const { accrualData } = useGetAccrualsQuery(undefined, {
        selectFromResult: ({ data }) => ({
            accrualData: data?.filter(accrual => accrual.project === selectedProject)
        })
    });

    const [addAccrual, setaddAccrual] = React.useState<boolean>(false);
    const [downloadAccrual, setdownloadAccrual] = React.useState<boolean>(false)

    const [checkedAccrual, setcheckedAccrual] = useState<string>("");

    const [costTrackerAccruals, setcostTrackerAccruals] = useState<boolean>(false)

    const [deleteAccrual] = useDeleteAccrualMutation();

    const manageChecks = (e: React.ChangeEvent<HTMLInputElement>) => {
        (document.getElementsByName(`${checkedAccrual}`) as NodeListOf<HTMLInputElement>).forEach(element => element.checked = false)
        if (e.currentTarget.checked) { //if box is checked
            setcheckedAccrual(e.currentTarget.name); //add new user to checked user
        }
        else setcheckedAccrual("")
    };

    const closeAddFromTracker = () => {
        setcostTrackerAccruals(false)
    }

    const closeAddAccrual = () => {
        setaddAccrual(false)
    }

    const closeDownloadAccruals = () => {
        setdownloadAccrual(false)
    }

    return (
        <div>
            <div className="modal-subTabs-halfSplit">
                <div className="modal-subTabs">
                    <span onClick={() => setcostTrackerAccruals(true)} className="modal-subTabs-item"><button className='universal-save-button'>Accrue From Tracker</button></span>
                    <span onClick={() => setaddAccrual(true)} className="modal-subTabs-item"><button className='universal-save-button'>Add Accrual</button></span>
                    <span onClick={() => setdownloadAccrual(true)} className="modal-subTabs-item"><button className='universal-search-button'>Download Accruals</button></span>
                    {checkedAccrual !== "" && <span onClick={() => {deleteAccrual(checkedAccrual); setcheckedAccrual('')}} className="modal-subTabs-item"><button className='universal-delete-button'>Delete</button></span>}
                </div>
                <div className="accruals-select-date-range">
                    <span className="modal-subTabs-item">
                        <p>Show Accruals for: </p>
                    </span>
                    <div className="accruals-select-date-range-selectRow">
                        <p>Month</p>
                        <select className='universal-custom-select' value={monthToView} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setmonthToView(e.target.value)} >
                            <option value={0}>All Months</option>
                            {months.map(month => {
                                return <option value={month.value}>{month.month}</option>
                            })}
                        </select>
                    </div>
                    <div className="accruals-select-date-range-selectRow">
                        <p>Year</p>
                        <select className='universal-custom-select' value={yearToView} onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setyearToView(e.target.value)}>
                            <option value={0}>All Years</option>
                            {getAccrualYearRange(accrualData).map(year => {
                                return <option value={year}>{year}</option>
                            })}
                        </select>
                    </div>
                </div>
            </div>
            {costTrackerAccruals && <AccrueFromTracker closeAddFromTracker={closeAddFromTracker} costTrackerAccruals={costTrackerAccruals} />}
            <div className="costCodes-modal-pageSplit-column">
                {addAccrual && <AddAccrual addAccrual={addAccrual} closeAddAccrual={closeAddAccrual} />}
                {downloadAccrual && <DownloadAccruals downloadAccruals={downloadAccrual} closeDownloadAccruals={closeDownloadAccruals} />}
                <Table>
                    <thead>
                        <tr>
                            {tableHeadings.map(heading => {
                                return <th>{heading}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {accrualData &&
                            accrualData?.
                                filter(accrual => {
                                    if (monthToView === "0") {
                                        return true
                                    } else return accrual.month === monthToView
                                })
                                .filter(accrual => {
                                    if (yearToView === "0") {
                                        return true
                                    } else return accrual.year === yearToView
                                }).map(accrual => {
                                    return <>
                                        <tr>
                                            <td><input name={accrual.id!} type="checkbox" className='tableCheckbox' onChange={(e: React.ChangeEvent<HTMLInputElement>) => manageChecks(e)}></input></td>
                                            <td>{accrual.company}</td>
                                            <td>{accrual.costCode}</td>
                                            <td>{accrual.resourceCode}</td>
                                            <td>{accrual.description}</td>
                                            <td>{dollarConverter(Number(accrual.cost), "$", "")}</td>
                                            <td>{accrual.type.toUpperCase()}</td>
                                            <td>{accrual.month.length === 1 ? "0".concat(accrual.month, " - ", accrual.year) : accrual.month.concat(" - ", accrual.year)}</td>
                                        </tr>
                                        {checkedAccrual == accrual.id! &&
                                            <>
                                                <EditAccrual {...accrual} />
                                            </>
                                        }
                                    </>
                                })
                        }
                        {(!accrualData || accrualData.length === 0) &&
                            <tr>
                                <td colSpan={tableHeadings.length}>No Accruals Entered</td>
                            </tr>
                        }
                    </tbody>
                </Table>
            </div>
        </div>
    )
}
