import React, { useState } from 'react'
import { Modal, Table } from 'react-bootstrap';
import { company } from '../../../../../../types/company';
import { useAddCompanyMutation } from '../../../../../../redux/injectionEndpoints/costTracking/companiesEndpoint';

type props = {
    closeAddCompany: () => void,
    addCompany: boolean
}

export const AddCompany = (props: props) => {

    const [addCompany] = useAddCompanyMutation();

    //new company saved as local state
    const [company, setcompany] = useState("");
    const [abbreviation, setabbreviation] = useState("");
    const [abn, setabn] = useState("");
    const [businessAddress, setbusinessAddress] = useState("");
    const [primaryContact, setprimaryContact] = useState("");
    const [primaryContactNumber, setprimaryContactNumber] = useState("");

    const topTableHeadings = [
        "Company",
        "Abbreviation",
        "ABN",
        "Business Address",
        "Primary Contact",
        "Primary Contact Number",
    ];

    const saveCompany = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        var newCompany: company = {
            company,
            abbreviation,
            abn,
            businessAddress,
            primaryContact,
            primaryContactNumber
        };

        //code to save company to server
        addCompany(newCompany);

        setcompany("");
        setabbreviation("");
        setabn("");
        setbusinessAddress("");
        setprimaryContact("");
        setprimaryContactNumber("");

        (document.getElementById("company") as HTMLInputElement)!.value = "";
        (document.getElementById("abbreviation") as HTMLInputElement)!.value = "";
        (document.getElementById("abn") as HTMLInputElement)!.value = "";
        (document.getElementById("address") as HTMLInputElement)!.value = "";
        (document.getElementById("contact") as HTMLInputElement)!.value = "";
        (document.getElementById("companyNumber") as HTMLInputElement)!.value = "";
    }

    return (
        <Modal show={props.addCompany} dialogClassName="modal-70w" >
            <Modal.Header closeButton onHide={() => props.closeAddCompany()}>
                <Modal.Title>Add Company</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Table style={{ width: "100%" }} striped bordered>
                    <thead>
                        <tr>
                            {
                                topTableHeadings.map(heading => {
                                    return <td>{heading}</td>
                                })
                            }
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><input id="company" placeholder='New Company' onChange={(e: React.ChangeEvent<HTMLInputElement>) => setcompany(e.target.value)}></input></td>
                            <td><input id="abbreviation" placeholder='Company Abbreviation' onChange={(e: React.ChangeEvent<HTMLInputElement>) => setabbreviation(e.target.value)}></input></td>
                            <td><input id="abn" placeholder='Company ABN' onChange={(e: React.ChangeEvent<HTMLInputElement>) => setabn(e.target.value)}></input></td>
                            <td><input id="address" placeholder='Company Address' onChange={(e: React.ChangeEvent<HTMLInputElement>) => setbusinessAddress(e.target.value)}></input></td>
                            <td><input id="contact" placeholder='Primary Contact' onChange={(e: React.ChangeEvent<HTMLInputElement>) => setprimaryContact(e.target.value)}></input></td>
                            <td><input id="companyNumber" placeholder='Primary Contact Number' onChange={(e: React.ChangeEvent<HTMLInputElement>) => setprimaryContactNumber(e.target.value)}></input></td>
                        </tr>
                    </tbody>
                </Table>
                <span className='save-item' >
                    <button className="universal-save-button" onClick={saveCompany}>Save Company</button>
                </span>
                <br></br>
            </Modal.Body>
            <Modal.Footer>
                <button className='universal-close-button' onClick={() => props.closeAddCompany()}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}
