import { configureStore } from '@reduxjs/toolkit';
import authReducer from './reducers/authReducer';
import projectTabReducer from './reducers/projectTabReducer';
import { API } from './APIs/moduleAPI';
import editPropertyReducer from './reducers/editPropertyReducer';
import viewRatesReducer from './reducers/viewRatesReducer';
import viewResourceCodesReducer from './reducers/viewResourceCodesReducer';
import viewCostsReducer from './reducers/viewCostsReducer';
import searchReducer from './reducers/searchReducer';
import adminReducer from './reducers/adminReducer';

export const store = configureStore({
    reducer: {
        user: authReducer,
        tabs: projectTabReducer,
        property: editPropertyReducer,
        rates: viewRatesReducer,
        costs: viewCostsReducer,
        resourceCodes: viewResourceCodesReducer,
        search: searchReducer,
        admin: adminReducer,
        //API Slices
        [API.reducerPath]: API.reducer 
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(API.middleware)
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch