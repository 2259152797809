import React, { useState } from 'react';
import { Modal, Table } from 'react-bootstrap';
import { useGetTemplateQuery, useGetTemplatesQuery } from '../../../../../redux/injectionEndpoints/costTracking/templatesEndpoint';
import { useGetCostCodesQuery } from '../../../../../redux/injectionEndpoints/costTracking/costCodeEndpoint';
import { useAddCostMutation } from '../../../../../redux/injectionEndpoints/costTracking/costsEndpoint';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { costTemplate } from '../../../../../types/costTemplate';
import { useCloseTemplateNotificationMutation } from '../../../../../redux/injectionEndpoints/costTracking/notificationsEndpoint';
import { dailyNotification } from '../../../../../types/dailyNotification';
import { dollarConverter } from '../../../../../functions/dollarConverter';
import { Date } from '../../../../misc/Date';
import { StringInput } from '../../../../misc/StringInput';

const topTableHeadings = [
    "Index",
    "Date",
    "Cost Code",
    "Resource Code",
    "Docket Number",
    "Purchase Order",
    "Company",
    "Description",
    "Invoice Number",
];

const bottomTableHeadings = [
    "Description",
    "Rate",
    "Unit",
    "Quantity",
    "Sub-Total",
    ""
];

type props = {
    closeAddCostFromTemplate: () => void,
    addCostFromTemplate: boolean,
    dailyNotification: dailyNotification | undefined
}

export const AddCostFromTemplate = (props: props) => {
    const selectedProject = useSelector((state: RootState) => state.tabs.projectTab);

    const [updateNotification] = useCloseTemplateNotificationMutation();

    //for the above select from result query for templates
    const selectTemplatesFromResult = (data: costTemplate[] | undefined) => {

        if (props.dailyNotification === undefined) {
            return data?.filter(template => template.project === selectedProject)
        } else {
            return data?.filter(template => props.dailyNotification?.templatesToAdd.includes(template.id!))
                .filter(template => template.project === selectedProject)
        }
    }
    const { templates } = useGetTemplatesQuery(undefined, {
        selectFromResult: ({ data }) => ({
            templates: selectTemplatesFromResult(data)

        })
    });
    const { data: costCodesData } = useGetCostCodesQuery(); //for retrieving all cost codes

    const [selectedTemplate, setselectedTemplate] = useState<string>("0") //default selected template is nothing
    const { data: templateData, refetch: templateRefetch } = useGetTemplateQuery(selectedTemplate, { refetchOnMountOrArgChange: true }); //fetch individual template based on selectedTemplate

    const [addCost] = useAddCostMutation();

    const [date, setdate] = useState<string>("") //date for the new cost
    const [docketNumber, setdocketNumber] = useState<string>("N/A")// docket number for new cost
    const [purchaseOrder, setpurchaseOrder] = useState<string>("N/A") //PO for new cost
    const [invoiceNumber, setinvoiceNumber] = useState<string>("N/A") //invoice number for new cost
    const [subCostCode, setsubCostCode] = useState<string>("0")

    const [indexNumber, setindexNumber] = useState<number>(0) //to iterate through each index in the template. starts at zero
    const [addedCosts, setaddedCosts] = useState<number[]>([]) //to track which costs have been added. based on indexNumber above

    const [indexQuantities, setindexQuantities] = useState<number[]>([0]) //quantities for each rate of each index in the template.
    const [indexTotals, setindexTotals] = useState<number[]>([0]) //array with rates total costs

    const [message, setmessage] = React.useState<string>('')

    const setTemplate = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setindexNumber(0)
        setselectedTemplate(e.target.value);
        templateRefetch();
    }

    const setNewCostQuantities = (e: React.ChangeEvent<HTMLInputElement>, index: number, rate: number) => { //used to update rates when index not = 0 is selected
        var newIndexQuantities = [...indexQuantities]; //create new dummy rates variable
        var newindexTotals = [...indexTotals] //create new dummy totals variable

        newIndexQuantities[index] = Number(e.currentTarget.value) //update dummy rates variable based on changes in UI
        newindexTotals[index] = Number(e.currentTarget.value) * rate

        setindexQuantities(newIndexQuantities); //reset indexRates
        setindexTotals(newindexTotals); //reset totals
    }

    const saveCost = () => { //save templated cost to DB and update component to move to next cost
        if (templateData) {
            if (indexNumber < templateData!.costs.length - 1) {
                setindexNumber(indexNumber + 1)
                setindexQuantities(Array(templateData.costs[indexNumber].rates.length).fill(null).map((_, index) => 0))
                setindexTotals(Array(templateData.costs[indexNumber].rates.length).fill(null).map((_, index) => 0))
            } //move to the next index in the templates array if we are not at end of the array
            else if (indexNumber === templateData!.costs.length - 1) {
                setindexNumber(0)
                setindexQuantities(Array(templateData.costs[indexNumber].rates.length).fill(null).map((_, index) => 0))
                setindexTotals(Array(templateData.costs[indexNumber].rates.length).fill(null).map((_, index) => 0))
            }
        }

        const costRates = templateData!.costs[indexNumber].rates.map((rate, index) => ({
            description: rate.rateDescription!,
            rate: rate.rate!,
            unit: rate.unit!,
            numberOf: indexQuantities[index] ? indexQuantities[index] : 0,
            valueOf: indexQuantities[index] ? rate.rate! * indexQuantities[index] : 0
        }))

        if (templateData!.costs[indexNumber].subCostCode === "0") {
            addCost({
                project: selectedProject,
                index: templateData?.costs[indexNumber].id!,
                date,
                costCode: subCostCode,
                resourceCode: templateData?.costs[indexNumber].resourceCode!,
                description: templateData?.costs[indexNumber].description!,
                company: templateData?.costs[indexNumber].company!,
                rates: costRates,
                totalCost: costRates!.map(rate => rate.valueOf).reduce((prev, curr) => prev + curr, 0),
                docketNumber,
                purchaseOrder,
                invoiceNumber,
                status: "Open"
            }).unwrap()
                .then(cost => {
                    setmessage("Cost successfully added")
                    window.setTimeout(() => setmessage(''), 3000)
                })
                .catch(err => console.log(err))
        }
        else {
            addCost({
                project: selectedProject,
                index: templateData?.costs[indexNumber].id!,
                date,
                costCode: templateData?.costs[indexNumber].subCostCode!,
                resourceCode: templateData?.costs[indexNumber].resourceCode!,
                description: templateData?.costs[indexNumber].description!,
                company: templateData?.costs[indexNumber].company!,
                rates: costRates,
                totalCost: costRates!.map(rate => rate.valueOf).reduce((prev, curr) => prev + curr, 0),
                docketNumber,
                purchaseOrder,
                invoiceNumber,
                status: "Open"
            }).unwrap()
                .then(cost => {
                    setmessage("Cost successfully added")
                    window.setTimeout(() => setmessage(''), 3000)
                })
                .catch(err => console.log(err))
        }
    }

    const renderIndexes = () => { //for rendering our 1, 2, 3 ... etc for which costs to add
        var indexArray = [];
        if (templateData) {
            for (var i = 1; i <= templateData.costs.length; i++) {
                indexArray.push(i)
            }
        }
        return indexArray
    }

    const bottomNumberingFiltering = () => { //return bottom statement of add cost from template based on already added costs
        if (templateData) {
            if (templateData.costs.length == addedCosts.length) {
                return <>
                    <p>All costs from this template have been added</p>
                    <button className='universal-small-save-button' onClick={() => { setaddedCosts(prevArray => []); setindexNumber(0) }}>Add This Template Again</button>
                </>
            }
            else if (templateData.costs.length !== addedCosts.length) return <button className='universal-small-add-button' onClick={() => { saveCost(); setaddedCosts(prevArray => [...prevArray, indexNumber]); console.log(addedCosts) }}>Add Cost</button>
            else if (addedCosts.find(cost => cost == indexNumber)) return <p>Cost Already Added</p>
        }
    }

    React.useEffect(() => { //when we alternate through costs to add, reset quantities and sub-totals to zero
        if (templateData) {
            setindexQuantities(Array(templateData.costs[indexNumber].rates.length).fill(null).map((_, index) => 0))
            setindexTotals(Array(templateData.costs[indexNumber].rates.length).fill(null).map((_, index) => 0))
        }
    }, [indexNumber, templateData])

    return (
        <Modal show={props.addCostFromTemplate} dialogClassName='modal-80w'>
            {props.dailyNotification === undefined ?
                <Modal.Header closeButton onHide={() => props.closeAddCostFromTemplate()}>
                    <Modal.Title>Add Costs From Template</Modal.Title>
                </Modal.Header>
                :
                <Modal.Header closeButton onHide={() => props.closeAddCostFromTemplate()}>
                    <Modal.Title>You have {props.dailyNotification?.templatesToAdd.length} templates scheduled for addition today. See below dropdown.</Modal.Title>
                </Modal.Header>
            }
            <Modal.Body>
                <div>
                    <div>
                        <select onChange={setTemplate}>
                            <option value={0}>SELECT COST TEMPLATE</option>
                            {templates && templates.map((template) => {
                                return <option value={template.id} >{template.name}</option>
                            })}
                        </select>
                    </div>
                    {selectedTemplate !== "0" && templateData &&
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Table>
                                <thead>
                                    <tr>
                                        {topTableHeadings.map(heading => {
                                            return <th>{heading}</th>
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {templateData &&
                                        <tr>
                                            <td>{templateData && templateData.costs[indexNumber].id}</td>
                                            {/* <td><input type="date" style={{ maxWidth: "100px" }} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setdate(e.target.value)}></input></td> */}
                                            <td><Date changeStateFunction={setdate} /></td>
                                            {templateData && templateData.costs[indexNumber].subCostCode === "0" ? <td>
                                                <select onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setsubCostCode(e.currentTarget.value)}>
                                                    {<option value="0">No Cost Code</option>}
                                                    {costCodesData?.map(costCode => {
                                                        return <option id={costCode.id!.toString()} value={costCode.code.concat(".", costCode.subCode)} >{costCode.code.concat(".", costCode.subCode)}</option>
                                                    })}
                                                </select>
                                            </td> : <td>{templateData.costs[indexNumber].subCostCode}</td>}
                                            <td>{templateData && templateData.costs[indexNumber].resourceCode}</td>
                                            {/* <td><input type="string" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setdocketNumber(e.currentTarget.value)}></input></td> */}
                                            <td><StringInput changeStateFunction={setdocketNumber} placeholder='Docket Number' /></td>
                                            {/* <td><input type="string" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setpurchaseOrder(e.currentTarget.value)}></input></td> */}
                                            <td><StringInput changeStateFunction={setpurchaseOrder} placeholder='Purchase Order' /></td>
                                            <td>{templateData && templateData.costs[indexNumber].company}</td>
                                            <td>{templateData && templateData.costs[indexNumber].description}</td>
                                            {/* <td><input type="string" id="invoiceNumber" onChange={(e: React.ChangeEvent<HTMLInputElement>) => setinvoiceNumber(e.currentTarget.value)}></input></td> */}
                                            <td><StringInput changeStateFunction={setinvoiceNumber} placeholder='Invoice Number' /></td>
                                        </tr>
                                    }
                                </tbody>
                            </Table>
                            <Table style={{ maxWidth: "40%" }}>
                                <thead>
                                    <tr>
                                        {bottomTableHeadings.map(heading => {
                                            return <th>{heading}</th>
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {templateData && templateData.costs[indexNumber].rates.map((rate, index) => {
                                        return <tr>
                                            <td>{rate.rateDescription}</td>
                                            <td>{dollarConverter(rate.rate!, "$", "")}</td>
                                            <td>{rate.unit}</td>
                                            <td><input type="number" id={rate.rateDescription} style={{ maxWidth: "80px" }} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { setNewCostQuantities(e, index, rate.rate!) }} min={0} value={indexQuantities[index]}></input></td>
                                            {indexTotals[index] && <td>{dollarConverter(indexTotals[index], "$", "")}</td>}
                                        </tr>
                                    })
                                    }
                                    <tr>
                                        <th colSpan={bottomTableHeadings.length - 2}>TOTAL</th>
                                        <th>{dollarConverter(indexTotals.map(total => ({ total })).reduce((prev, curr) => prev + curr.total, 0), "$", "")}</th>
                                    </tr>
                                </tbody>
                            </Table>
                            <div style={{ display: "flex", alignContent: "center", justifyContent: "center" }}>
                                {renderIndexes().map(index => {
                                    return <p className="cursorHoverMousePointer" onClick={() => setindexNumber(index - 1)} style={indexNumber == index - 1 ? { color: "blue", margin: "5px" } : { color: "black", margin: "5px" }}>{index}</p>
                                })}
                            </div>
                            {bottomNumberingFiltering()}
                            <p>{message !== '' && message}</p>
                        </div>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button className='universal-close-button' onClick={() => {
                    if (props.dailyNotification !== undefined) {
                        updateNotification(props.dailyNotification._id)
                    }
                    props.closeAddCostFromTemplate()
                }}>
                    Close
                </button>
            </Modal.Footer>
        </Modal>
    )
}
