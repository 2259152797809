//stringToDate is our way of converting saved strings from our database (yyyy-mm-dd format) to a Date type which can be used for logical operations

export const stringToDate = (dateInString: string): Date => {
    const dateParts = dateInString.split("-");

    return new Date(+dateParts[0], +dateParts[1] - 1, +dateParts[2])
}

export const stringToDateDifference = (dateInString: string, daysToAlter: number): Date => {
    const dateParts = dateInString.split("-");

    return new Date(+dateParts[0], +dateParts[1] - 1, +dateParts[2]+daysToAlter)
}

//dateToString is our way of converting a Date object to the format "yyyy-mm-dd", which is what we want our dates saved as in our database
export const dateToString = (dateToConvert: Date) => {
    const day = String(dateToConvert.getDate()).padStart(2, '0');
    const month = String(dateToConvert.getMonth() + 1).padStart(2, '0');
    const year = dateToConvert.getFullYear();
    return `${day}-${month}-${year}`;
}