import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import { dollarConverter } from '../../../../functions/dollarConverter';
import { useDeleteCostMutation, useGetCostsQuery } from '../../../../redux/injectionEndpoints/costTracking/costsEndpoint';
import { cost } from '../../../../types/cost';
import { AddCost } from './add items/AddCost';
import { AddCostFromTemplate } from './add items/AddCostFromTemplate';
import { EditCost } from './editItems/EditCost';
import { Binoculars, CaretLeftFill, CaretRightFill } from 'react-bootstrap-icons';
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { openCostsSearch, closeCostsSearch } from '../../../../redux/reducers/searchReducer';
import { SearchCosts } from './searchItems/SearchCosts';
import { useNavigate } from 'react-router-dom';
import { AddCostComment } from './add items/AddCostComment';
import { useGetTemplateNotificationQuery } from '../../../../redux/injectionEndpoints/costTracking/notificationsEndpoint';
import { dateToString } from '../../../../functions/stringToDate';

const tableHeadings = [
    "Select",
    "Index",
    "Date",
    "Resource Code",
    "Docket Number",
    "Purchase Order",
    "Company",
    "Description",
    "Cost Code",
    "Invoice Number",
    "Total Cost",
    "Status",
];

export const Costs = () => {
    const navigate = useNavigate();

    const selectedProject = useSelector((state: RootState) => state.tabs.projectTab)

    const { notificationData } = useGetTemplateNotificationQuery(undefined, {
        selectFromResult: ({ data }) => ({
            notificationData: data?.find(notification => dateToString(new Date(notification.createdAt)) === dateToString(new Date()))
        })
    })

    const [page, setpage] = useState<number>(0);
    const [costsPerPage, setcostsPerPage] = useState<number>(40) //indicate how many costs to show per page

    const searchCosts = useSelector((state: RootState) => state.search.costs) //state to show our search cost modal
    const dispatch = useDispatch();

    const authorisedUser = useSelector((state: RootState) => state.user)

    const { costsData, totalCostsLength } = useGetCostsQuery(undefined, {
        selectFromResult: ({ data }) => ({
            costsData: data?.filter(cost => cost.project === selectedProject)
                .slice(page * costsPerPage, (page * costsPerPage) + (costsPerPage)),
            totalCostsLength: data?.length
        })
    });
    const [deleteSelectedCost] = useDeleteCostMutation();

    const [addCost, setaddCost] = useState(false); //true when user clicks "Add Cost"
    const [addCostFromTemplate, setaddCostFromTemplate] = useState<boolean>(false)
    const [addComment, setaddComment] = React.useState<boolean>(false)
    const [editCost, seteditCost] = useState(false); //true when user clicks pencil icon for editting a cost

    const [checkedCost, setcheckedCost] = useState<string[]>([]) //indicate which cost has been selected

    const pageUp = (e: React.MouseEvent<SVGElement>) => { //set the page to go up
        let numberOfPages
        if (totalCostsLength) {
            numberOfPages = Math.ceil(totalCostsLength / costsPerPage)
            if (page < numberOfPages) setpage(prevPage => {
                if (prevPage + 3 > Math.ceil(totalCostsLength / costsPerPage)) {
                    document.getElementById("costs-page-nagivator-top-right")!.style.color = "white" //white out the arrow. no position change but no visible
                    document.getElementById("costs-page-nagivator-top-right")!.style.cursor = "auto" //no cursor if going over white arrow
                    document.getElementById("costs-page-nagivator-bottom-right")!.style.color = "white" //white out the arrow. no position change but no visible
                    document.getElementById("costs-page-nagivator-bottom-right")!.style.cursor = "auto" //no cursor if going over white arrow
                }
                return prevPage + 1
            })
        }
    }
    const pageDown = (e: React.MouseEvent<SVGElement>) => { //set the page to go down
        if (page > 0) setpage(prevPage => {
            if (totalCostsLength && prevPage < Math.ceil(totalCostsLength / costsPerPage)) {
                document.getElementById("costs-page-nagivator-top-right")!.style.color = "black"
            }
            return page - 1
        })
    }

    const manageChecks = (e: React.ChangeEvent<HTMLInputElement>) => {
        // (document.getElementsByName(`${checkedCost}`) as NodeListOf<HTMLInputElement>).forEach(element => element.checked = false)
        if (e.target.checked) { //if box is checked
            setcheckedCost([...checkedCost, e.target.name]); //add new cost to checked cost array
            setaddCost(false)
        }
        else setcheckedCost([...checkedCost.filter(cost => cost !== e.target.name)]) //else if box is unchecked, set to empty string
    };

    const deleteCost = (e: React.MouseEvent<HTMLSpanElement>) => {
        e.preventDefault();
        checkedCost.forEach(cost => {
            deleteSelectedCost(cost)
        })
        setcheckedCost([]);
    };

    const deleteIndividualCost = (costId: string) => {
        deleteSelectedCost(costId)
    }

    const pickRowColor = (cost: cost) => {
        switch (cost.status) {
            case "Approved":
            case "approved":
                return "rgba(11, 156, 49, 0.4)"
            case "Rejected":
            case "rejected":
                return "rgba(255, 99, 71, 0.4)"
            case "Pending":
            case "pending":
                return "rgba(255, 255, 0, 0.4)"
            default:
                return "rgb(255, 255, 255)"
        }
    }

    const closeAddCostModal = () => { //to be passed in to add cost modal
        setaddCost(false);
    };

    const closeAddCostCommentModal = () => { //to be passed in to add cost comment modal
        setaddComment(false)
    };

    const closeAddCostFromTemplate = () => {
        setaddCostFromTemplate(false)
    }

    useEffect(() => {
        if (!authorisedUser.isAuthenticated) {
            navigate('/login')
        };

        if (checkedCost.length > 1) seteditCost(false) //if a user selects more than one, take away the option to edit as we don't edit two pieces of data at once.
        else if (checkedCost.length === 0) seteditCost(false) //if a user deselects the data to edit while editing, close the edit
    }, [manageChecks, authorisedUser])

    return (
        <div>
            <div className="costs-heading">
                <div className="modal-subTabs">
                    {!addCost && <span onClick={() => { setaddCost(true); seteditCost(false); setaddCostFromTemplate(false); dispatch(closeCostsSearch()); setcheckedCost([]) }} className="modal-subTabs-item"><button className='universal-save-button'>Add Cost</button></span>
                    }
                    <span onClick={() => { setaddCostFromTemplate(!addCostFromTemplate); setaddCost(false); dispatch(closeCostsSearch()) }} className="modal-subTabs-item">
                        <button className='universal-save-button'>Add Costs From Template</button>
                    </span>
                    {checkedCost.length > 0 && <span className="modal-subTabs-item" onClick={deleteCost}><button className='universal-delete-button'>Delete Cost</button></span>}
                    {!searchCosts && <span className="modal-subTabs-item" onClick={() => { dispatch(openCostsSearch()); setaddCostFromTemplate(false); setaddCost(false) }}><button className='universal-search-button'>Search Costs</button></span>}
                    {searchCosts && <span className="modal-subTabs-item" onClick={() => dispatch(closeCostsSearch())}><button className='universal-delete-button'>Close Search</button></span>}
                    {checkedCost.length > 0 && <span className="modal-subTabs-item" onClick={() => setaddComment(true)} ><button className='universal-add-button'>Add Comment</button></span>}
                </div>
                <div className="page-arrows-container">
                    {page > 0 && <CaretLeftFill onClick={pageDown} style={{ cursor: "pointer" }} />}
                    <p >Page: {page + 1}</p>
                    {costsData && totalCostsLength && <CaretRightFill id="costs-page-nagivator-top-right" onClick={pageUp} style={{ cursor: "pointer" }} />}
                </div>
            </div>
            <div className="costCodes-modal-pageSplit-column">
                {addCost && <AddCost closeAddCostModal={closeAddCostModal} addCost={addCost} />}
                {addComment && checkedCost.length === 1 && <AddCostComment closeAddCostCommentModal={closeAddCostCommentModal} addComment={addComment} costId={checkedCost[0]} />}
                {addCostFromTemplate && <AddCostFromTemplate closeAddCostFromTemplate={closeAddCostFromTemplate} addCostFromTemplate={addCostFromTemplate} dailyNotification={undefined} />}
                {notificationData && notificationData.notificationRead === false && <AddCostFromTemplate closeAddCostFromTemplate={closeAddCostFromTemplate} addCostFromTemplate={true} dailyNotification={notificationData} />}
                <br></br>
                {<Table className="generic-table-class" bordered hover>
                    <thead>
                        <tr>
                            {tableHeadings.map(heading => {
                                return <th>{heading}</th>
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {searchCosts && <SearchCosts />}
                        {!searchCosts && costsData?.map((cost, index) => {
                            return <>
                                <tr key={index} style={{ backgroundColor: pickRowColor(cost) }}>
                                    <td><input name={cost.id?.toString()} type="checkbox" className="tableCheckbox" onChange={(e: React.ChangeEvent<HTMLInputElement>) => manageChecks(e)} checked={checkedCost.includes(cost.id!.toString())} ></input></td>
                                    <td>{cost.index}</td>
                                    <td>{cost.date}</td>
                                    <td>{cost.resourceCode}</td>
                                    <td>{cost.docketNumber}</td>
                                    <td>{cost.purchaseOrder}</td>
                                    <td>{cost.company}</td>
                                    <td>{cost.description}</td>
                                    <td>{cost.costCode}</td>
                                    <td>{cost.invoiceNumber}</td>
                                    <td>{dollarConverter(cost.totalCost, "$", "")}</td>
                                    <td>{cost.status}</td>
                                </tr>
                                {checkedCost.length === 1 && checkedCost[0] === cost.id!.toString() &&
                                    <>
                                        <EditCost cost={costsData.find(costToFind => costToFind.id === cost.id)!} deleteCost={deleteIndividualCost} />
                                    </>
                                }
                            </>

                        })}
                    </tbody>
                </Table>}
            </div>
            <div className="page-arrows-container-bottom">
                {page > 0 && <CaretLeftFill onClick={pageDown} style={{ cursor: "pointer" }} />}
                <p >Page: {page + 1}</p>
                {costsData && totalCostsLength && <CaretRightFill id="costs-page-nagivator-bottom-right" onClick={pageUp} style={{ cursor: "pointer" }} />}
            </div>
        </div>
    )
}
