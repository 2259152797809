export interface dropdown {
    key: string,
    value: string,
    dividerUnderneath: boolean,
    tabToOpen: string
}

export interface urlDropdown {
    key: string,
    value: string,
    parent: "setup" | "costTracking" | "endOfMonth",
    dividerUnderneath: boolean,
    tabToOpen: string,
    url: string
}

export const programDropdown: dropdown[] = [
    {
        key: "addProgramItem",
        value: "Add Program Item",
        dividerUnderneath: false,
        tabToOpen: "addActivity",
    },
    {
        key: "editProgramItem",
        value: "Edit Program Item",
        dividerUnderneath: false,
        tabToOpen: "editActivity"
    },
    {
        key: "removeProgramItem",
        value: "Remove Program Item",
        dividerUnderneath: false,
        tabToOpen: "removeActivity"
    }
];

export const costTrackingDropdown: urlDropdown[] = [
    {
        key: "companies",
        value: "Companies",
        parent: "setup",
        dividerUnderneath: false,
        tabToOpen: "companies",
        url: "Companies"
    },
    {
        key: "resourceCodes",
        value: "Resource Codes",
        parent: "setup",
        dividerUnderneath: true,
        tabToOpen: "resourceCodes",
        url: "ResourceCodes"
    },
    {
        key: "codes",
        value: "Cost Codes",
        parent: "setup",
        dividerUnderneath: false,
        tabToOpen: "costCodes",
        url: "CostCodes"
    },
    {
        key: "index",
        value: "Index",
        parent: "setup",
        dividerUnderneath: false,
        tabToOpen: "index",
        url: "Index"
    },
    {
        key: "costs",
        value: "Costs",
        parent: "costTracking",
        dividerUnderneath: true,
        tabToOpen: "costs",
        url: "Costs"
    },
    {
        key: "templates",
        value: "Templates",
        parent: "costTracking",
        dividerUnderneath: true,
        tabToOpen: "templates",
        url: "Templates"
    },
    {
        key: "accruals",
        value: "Accruals",
        parent: "endOfMonth",
        dividerUnderneath: true,
        tabToOpen: "accruals",
        url: "Accruals"
    },
    {
        key: "productionTracking",
        value: "Production Tracking",
        parent: "costTracking",
        dividerUnderneath: true,
        tabToOpen: "productionTracking",
        url: "ProductionTracking"
    },
    {
        key: "ongoingCosts",
        value: "Ongoing Costs",
        parent: "costTracking",
        dividerUnderneath: true,
        tabToOpen: "ongoingCosts",
        url: "OngoingCosts"
    }
];