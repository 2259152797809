import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { useGetCostsQuery } from '../../../../../redux/injectionEndpoints/costTracking/costsEndpoint';
import { useGetCostCodesQuery } from '../../../../../redux/injectionEndpoints/costTracking/costCodeEndpoint'
import { useAddProductionTrackingMutation } from '../../../../../redux/injectionEndpoints/costTracking/productionTrackingEndpoint'
import { RootState } from '../../../../../redux/store'
import { generateProductionTrackingDates } from '../../../../../functions/costTracking/productionTracking/generateTrackingDates';
import { Modal } from 'react-bootstrap';

type props = {
    closeAddProductionTracking: () => void,
    addProductionTracking: boolean
}

export const AddProductionTracking = (props: props) => {
    const selectedProject = useSelector((state: RootState) => state.tabs.projectTab);

    const { costs } = useGetCostsQuery(undefined, {
        selectFromResult: ({ data }) => ({
            costs: data?.filter(cost => cost.project === selectedProject)
        })
    });

    const { costCodes } = useGetCostCodesQuery(undefined, {
        selectFromResult: ({ data }) => ({
            costCodes: data?.filter(costCode => costCode.project === selectedProject)
        })
    });

    const [addProductionTracking] = useAddProductionTrackingMutation();

    const [name, setname] = useState("")
    const [budgetUnitRate, setbudgetUnitRate] = useState(0)
    const [budgetDailyRate, setbudgetDailyRate] = useState(0)
    const [dollarTrackingCode, setdollarTrackingCode] = useState<string>("")
    const [unitBeingTracked, setunitBeingTracked] = useState("")

    const confirmAddNewProductionTracking = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();

        addProductionTracking({
            project: selectedProject,
            name,
            budgetUnitRate,
            budgetDailyRate,
            dollarTrackingCode,
            unitBeingTracked,
            productionTracked: generateProductionTrackingDates(costs, dollarTrackingCode).map(date => { return { date, quantity: 0 } })
        })

        setname("");
        setbudgetUnitRate(0)
        setbudgetDailyRate(0)
        setdollarTrackingCode("0")
        setunitBeingTracked("")
    }

    return (
        <Modal show={props.addProductionTracking} dialogClassName="modal-50w">
            <Modal.Header closeButton onHide={() => props.closeAddProductionTracking()}>
                <Modal.Title>Add Production Tracking</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <form className='addProductionTracking-parent'>
                    <h6 className='addProductionTracking-child'>Name: </h6>
                    <input required value={name} className='addProductionTracking-child' onChange={(e: React.ChangeEvent<HTMLInputElement>) => setname(e.target.value)} ></input>
                    <h6 className='addProductionTracking-child'>Budget Unit Rate: </h6>
                    <input required value={budgetUnitRate} className='addProductionTracking-child' type={"number"} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setbudgetUnitRate(Number(e.target.value))}></input>
                    <h6 className='addProductionTracking-child'>Budget Daily Rate: </h6>
                    <input required value={budgetDailyRate} className='addProductionTracking-child' type={"number"} onChange={(e: React.ChangeEvent<HTMLInputElement>) => setbudgetDailyRate(Number(e.target.value))}></input>
                    <h6 className='addProductionTracking-child'>Cost Code to Track: </h6>
                    <select required value={dollarTrackingCode} className='addProductionTracking-child' onChange={(e: React.ChangeEvent<HTMLSelectElement>) => setdollarTrackingCode(e.target.value)}>
                        <option value="0">Please Select</option>
                        {costCodes && costCodes.map(costCode => {
                            return <option value={costCode.code.concat(".", costCode.subCode)} >{costCode.code.concat(".", costCode.subCode)}</option>
                        })}
                    </select>
                    <h6 className='addProductionTracking-child'>Unit Being Tracked: </h6>
                    <input required value={unitBeingTracked} className='addProductionTracking-child' onChange={(e: React.ChangeEvent<HTMLInputElement>) => setunitBeingTracked(e.target.value)}></input>
                    <button className='universal-save-button' onClick={(e: React.MouseEvent<HTMLButtonElement>) => confirmAddNewProductionTracking(e)}>Create Production Tracking</button>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button className='universal-close-button' onClick={() => props.closeAddProductionTracking()}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}
