import { Dropdown } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/css/bootstrap.css';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../redux/reducers/authReducer';
import { useLogoutMutation } from '../../redux/injectionEndpoints/auth/authEndpoints';
import { RootState } from '../../redux/store';

export const SignOutDropdown = () => {

    const userEmail = useSelector((state: RootState) => state.user.userProfile?.email)

    const [userLogout] = useLogoutMutation()

    return (
        <div id="logOut-heading-dropdown">
            <Dropdown >
                <Dropdown.Toggle id="logOut-heading-dropdown-button">
                    Sign Out
                </Dropdown.Toggle>
                <Dropdown.Menu className="dropdown-style-1">
                    <Dropdown.ItemText><b>Are you sure?</b></Dropdown.ItemText>
                    <Dropdown.Divider />
                    <Dropdown.Item>No</Dropdown.Item>
                    <Dropdown.Divider />
                    <Dropdown.Item onClick={() => { userEmail && userLogout(userEmail) }}>Yes</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
        </div>
    )
}
