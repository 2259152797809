import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom'
import { RootState } from '../../redux/store';
import { BounceLoader } from 'react-spinners';

//Dummy page is for our initial landing. It will either direct straight to login or costs
export const DummyPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const authorisedUser = useSelector((state: RootState) => state.user);

    React.useEffect(() => {
        if (!authorisedUser.isAuthenticated) {
            navigate('/login')
        }
        else if (authorisedUser.isAuthenticated && !authorisedUser.requestAccess) {
            navigate('/Costs')
        }
    }, [authorisedUser])

    return (
        <div style={{width: "100%", display: "flex", flexDirection: "row", alignItems: "center", alignContent: "center"}}>
            <BounceLoader />
        </div>
    )
}
